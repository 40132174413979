import React, { Component } from "react";
import "./styles.css";
import { PrimaryButton, DefaultButton, Dropdown } from "office-ui-fabric-react";
import {
  DetailsList,
  DetailsListLayoutMode,
  Selection,
  SelectionMode
} from "office-ui-fabric-react/lib/DetailsList";
import { CommandBarControl } from "../Controls/CommandBar/commandBar";
import { Icon } from "@fluentui/react";
import { NewsService } from "../Services/NewsService";
import { EditNewsPanel } from "../Controls/News/EditNewsPanel";
import { NewsItem } from "../Services/Entities/NewsItem";
export class News extends Component  {
  constructor(props) {
    super(props);
    this.state = {
      newNewsItemCheck: false,
      editButton: false,
      newsArticles: [],
      selectedNewsItem: new NewsItem(),
      panelOpen: false
    };

    this._changeNewNewsItem = () => {
      this.setState({ 
        newNewsItemCheck: true,
        editButton: false,
        panelOpen:true,
        selectedNewsItem: new NewsItem() });
    };

    this.getNewsItems = () => {
      NewsService.Instance.GetAll(this.props.selectedOrganisation).then((newsItems) =>
      {
        this.setState({ newsArticles:newsItems });
      });
    };

    this._selection = new Selection({
      onSelectionChanged: () =>
        this.setState({
          editButton: true,
        })
    });

    this.editItem = () => {
      const selectionCount = this._selection.getSelectedCount();

      if (selectionCount == 1)
      {
        this.setState({
          selectedNewsItem : this._selection.getSelection()[0]
          ,panelOpen:true });
      }     
    }

    this.closeEditAddPanel = () => {
      this.setState({ panelOpen: false });
    };

    this.onEditAddPanelSave = (item) => {
      NewsService.Instance.Save(item).then((isSucces) =>
      {
        this.setState({ panelOpen: false });
        this.getNewsItems();
      });
    } 
  }

  componentDidMount () {
    this.getNewsItems();
  }

  render() {
    this._columns = [
      {
        key: "organisation",
        name: "Organisatie",
        fieldName: "organisation",
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
      },
      {
        key: "publicationDate",
        name: "Publicatie datum",
        fieldName: "publicationDate",
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        onRender: (item) => {
          return <span>{new Date(item.publicationDate).toLocaleDateString()}</span>
        }
      },
      {
        key: "title",
        name: "Titel",
        fieldName: "title",
        minWidth: 300,
        maxWidth: 500,
        isResizable: true,
      }
    ];

    return (
      <div className="gridWrapper">
        <h1>Nieuws</h1>
        <div className="ms-Grid-col ms-sm12 ms-md12">
              <PrimaryButton
                iconProps={{ iconName: "Add", className: "ms-AddIcon" }}
                onClick={this._changeNewNewsItem}
                className="submitButton"
                text="Nieuw nieuws artikel"
              />
            </div>
        <h4 class="section-divider sectionHeadings">
          <Icon style={{ verticalAlign: "bottom" }} iconName="Info"></Icon>{" "}
          Nieuws artikelen
        </h4>
        <div className="ms-Grid-col ms-col12 ms-md12">
              {this.state.editButton && this.state.selectedNewsItem !== null && (
                <CommandBarControl EditItem={this.editItem} />
              )}
              <div className="detailList">
                <DetailsList
                  items={this.state.newsArticles}
                  selectionMode={SelectionMode.single}
                  enterModalSelectionOnTouch={true}
                  onColumnResize={false}
                  textAlign="left"
                  verticalAlign="left"
                  columns={this._columns}
                  setKey="set"
                  layoutMode={DetailsListLayoutMode.justified}
                  ariaLabelForSelectionColumn="Toggle selection"
                  ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                  checkButtonAriaLabel="Row checkbox"
                  selection={this._selection}
                  compact={false}
                  selectionPreservedOnEmptyClick={true}
                />
              </div>
              <EditNewsPanel panelOpen={this.state.panelOpen} 
                onDismiss={this.closeEditAddPanel} 
                selectableOrganisations={this.props.selectableOrganisations} 
                itemToEdit={this.state.selectedNewsItem} 
                OnPanelSave={this.onEditAddPanelSave}
                selectedOrganisation={this.props.selectedOrganisation}
              >
              </EditNewsPanel>
            </div>
      </div>
    );
  }
}
