import * as React from "react";
import {
  CommandBar,
} from "office-ui-fabric-react/lib/CommandBar";
import { Dialog, DialogType, DialogFooter } from '@fluentui/react/lib/Dialog';
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';
import "./style.css"
export class CommandBarEditDeleteControl extends React.Component {

  constructor(props) {
    super(props);

    this.state = this.GetDefaultState();
  };

  GetDefaultState = () => {
    return {
      hideDialog: true
    };
}

  showDeleteConfirmation = () => {
    this.setState({hideDialog: false});
  };

  hideDeleteConfirmation = () => {
    this.setState({hideDialog: true});
  }

  onDeleteConfirmed = () => {
    this.setState({hideDialog: true});
    this.props.DeleteItem();
  }

  render() {
    const _items = [
      {
        key: "Edit",
        text: "Item bewerken",
        iconProps: { iconName: "Edit" },
        onClick: this.props.EditItem
      },
      {
        key: "Verwijderen",
        text: "Item verwijderen",
        iconProps: { iconName: "Delete" },
        onClick: this.showDeleteConfirmation
      }
    ];

    const dialogContentProps = {
      type: DialogType.normal,
      title: 'Bevestiging verwijderen',
      subText: 'Weet je zeker dat je dit record wilt verwijderen?',
    };

    return (
      <div>
        <div>
        <CommandBar
          items={_items}
          // onClick={this.props.EditItem}
          className="editButton"
          ariaLabel="Use left and right arrow keys to navigate between commands"
        />
        </div>
        <Dialog
          hidden={this.state.hideDialog}
          onDismiss={this.hideDeleteConfirmation}
          dialogContentProps={dialogContentProps}
          modalProps={{isBlocking: true}}
          styles={{main: { maxWidth: 450 } }}
        >
          <DialogFooter>
            <PrimaryButton onClick={this.onDeleteConfirmed} text="Verwijderen" />
            <DefaultButton onClick={this.hideDeleteConfirmation} text="Annuleren" />
          </DialogFooter>
        </Dialog>
      </div>
    );
  }
}
