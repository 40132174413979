export class PublicationService {

    static instance;

    static get Instance() {

        if(this.instance == null) {
            this.instance = new PublicationService();
        }

        return this.instance;
    }

    GetAll() {
        return new Promise((resolve, reject) => {
            fetch("/api/provision/all")
            .then((response) => response.json())
            .then((data) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
        });
    }

    GetAllByKlantId(klantId) {
        return new Promise((resolve, reject) => {
            fetch("/api/provision/all/" + klantId)
            .then((response) => response.json())
            .then((data) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
        });
    }

    Save(publicationItem)
    {
        // POST for add, PUT for update
        // var restMethod = publicationItem.publicationId == null ? "POST" : "PUT";
        // only adding for publication, updating is not allowed.
        var restMethod = "POST";
        return new Promise((resolve, reject) => {
            fetch("/api/provision", {
                method: restMethod,
                headers: {
                "Content-Type": "application/json",
                },
                body: JSON.stringify(publicationItem),
            })
            .then((response) => response.json())
            .then((data) => {
                console.log("Success:", data);
                resolve(true);            
            })
            .catch((error) => {
                console.error("Error:", error);
                reject(error);
            });
        });
    }
}