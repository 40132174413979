import React, { Component } from "react";
import SortableTree, {
  addNodeUnderParent,
  removeNodeAtPath,
  changeNodeAtPath,
  getFlatDataFromTree,
} from "react-sortable-tree";
import "react-sortable-tree/style.css";
import { PrimaryButton } from "office-ui-fabric-react";
import InfoModal from "./InfoModal";
import EditModal from "./EditModal";
import DeleteModal from "./DeleteModal";
import {
  DetailsList,
  DetailsListLayoutMode,
  Selection,
} from "office-ui-fabric-react/lib/DetailsList";
import { CommandBarControl } from "../../Controls/CommandBar/commandBar";
import {
  Button,
  Placeholder,
  Segment,
} from "semantic-ui-react";
import { DefaultButton, Icon} from "@fluentui/react";

const getNodeKey = ({ treeIndex }) => treeIndex;
export default class KlantReisManager extends Component {
  state = {
    treeData: [],
    loading: true,
    klanten: [],
    selectedKlant: undefined,
    editButton: false,
    hideGridview: false,
    items:[]
  };

  getKlanten = () => {
    // Filtering on "klanten" takes place in /routes/klant.js
      fetch("/api/klant/all")
      .then((response) => response.json())
      .then((data) => {
        // this.updateJSON();
        // this.updatePowerShellTheme();
        this.setState({ klanten: data, loading: false });
      });
  };

  getData = (klantId) => {
    fetch("/api/proces/menu/" + klantId, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({ treeData: this.changeTree(data) }, () => {});
      });
  };

  componentDidMount = () => {
    this.getKlanten();
  };

  changeTree = (data) => {
    console.log("data", data);
    let newTreeData = [];
    newTreeData = data.map((proces, index) => {
      let changedProces = { ...proces };
      changedProces["subtitle"] = "Proces";
      changedProces["type"] = "Proces";
      changedProces["noEdit"] = false;
      changedProces["children"] = proces.children.map((procesStap, index) => {
        let changedProcesStap = { ...procesStap };
        changedProcesStap["subtitle"] = "Proces stap";
        changedProcesStap["type"] = "ProcesStap";
        changedProcesStap["noEdit"] = false;
        changedProcesStap["ParentID"] = changedProces.id;

        changedProcesStap["children"] = procesStap.children.map(
          (ProcesSubStap, index) => {
            let changedProcesSubStap = { ...ProcesSubStap };
            changedProcesSubStap["subtitle"] = "Proces sub stap";
            changedProcesSubStap["type"] = "ProcesSubstap";
            changedProcesSubStap["ParentID"] = changedProcesStap.id;
            changedProcesSubStap["noEdit"] = false;
            changedProcesSubStap["noAdd"] = true;
            changedProcesSubStap["noChildren"] = true;
            changedProcesSubStap["noDragging"] = false;
            return changedProcesSubStap;
          }
        );
        console.log("new parentID", changedProcesStap["ParentID"]);
        return changedProcesStap;
      });
      return changedProces;
    });
    return newTreeData;
  };

  addLevel = (e, node, path) => {
    // if rowInfo is null then you should add a new RMM Modal to the view
    const getNodeKey = ({ treeIndex }) => treeIndex;
    e.preventDefault();

    let newProces = {};

    newProces["id"] = 0;
    newProces["title"] = "NIEUW Proces";
    newProces["NormNaam"] = "";
    newProces["NormTypeNaam"] = "";
    newProces["Klantnaam"] = "";
    newProces["Url"] = "";
    newProces["LinkedPage"] = "";
    newProces["MenuType"] = "";
    newProces["CustomStyle"] = false;
    newProces["CustomColor"] = "";
    newProces["NewTab"] = false;
    newProces["children"] = [];
    newProces["subtitle"] = "Proces";
    newProces["type"] = "Proces";
    newProces["noChildren"] = false;
    newProces["noDragging"] = false;
    newProces["noEdit"] = false;
    newProces["noAdd"] = false;
    newProces["Sequence"] = 0;

    let newProcesStap = {};

    newProcesStap["id"] = 0;
    newProcesStap["title"] = "NIEUW Proces stap";
    newProcesStap["NormNaam"] = "";
    newProcesStap["NormTypeNaam"] = "";
    newProcesStap["Klantnaam"] = "";
    newProcesStap["Url"] = "";
    newProcesStap["LinkedPage"] = "";
    newProcesStap["MenuType"] = "";
    newProcesStap["CustomStyle"] = false;
    newProcesStap["CustomColor"] = "";
    newProcesStap["NewTab"] = false;
    newProcesStap["children"] = [];

    newProcesStap["subtitle"] = "Proces stap";
    newProcesStap["type"] = "ProcesStap";
    newProcesStap["noChildren"] = false;
    newProcesStap["noDragging"] = false;
    newProcesStap["noEdit"] = false;
    newProcesStap["noAdd"] = false;
    newProcesStap["Sequence"] = 0;

    let newProcesSubStap = {};

    newProcesSubStap["id"] = 0;
    newProcesSubStap["title"] = "NIEUW Proces sub stap";
    newProcesSubStap["NormNaam"] = "";
    newProcesSubStap["NormTypeNaam"] = "";
    newProcesSubStap["Klantnaam"] = "";
    newProcesSubStap["Url"] = "";
    newProcesSubStap["LinkedPage"] = "";
    newProcesSubStap["MenuType"] = "";
    newProcesSubStap["CustomStyle"] = false;
    newProcesSubStap["CustomColor"] = "";
    newProcesSubStap["NewTab"] = false;
    newProcesSubStap["children"] = [];

    newProcesSubStap["subtitle"] = "Proces sub stap";
    newProcesSubStap["type"] = "ProcesSubstap";
    newProcesSubStap["noChildren"] = true;
    newProcesSubStap["noDragging"] = false;
    newProcesSubStap["noEdit"] = false;
    newProcesSubStap["noAdd"] = true;
    newProcesSubStap["Sequence"] = 0;

    if (node.type === "Proces") {
      this.setState((state) => ({
        treeData: this.changeTree(
          addNodeUnderParent({
            treeData: state.treeData,
            parentKey: path[path.length - 1],
            expandParent: true,
            getNodeKey,
            newNode: newProcesStap,
            addAsFirstChild: false,
          }).treeData
        ),
      }));
    } else if (node.type === "ProcesStap") {
      this.setState((state) => ({
        treeData: this.changeTree(
          addNodeUnderParent({
            treeData: state.treeData,
            parentKey: path[path.length - 1],
            expandParent: true,
            getNodeKey,
            newNode: newProcesSubStap,
            addAsFirstChild: false,
          }).treeData
        ),
      }));
    }
  };

  editLevel = (path, newData) => {
    this.setState((state) => ({
      treeData: changeNodeAtPath({
        treeData: state.treeData,
        path,
        getNodeKey,
        newNode: newData,
      }),
    }));
  };

  changeSomething = (e, data) => {
    var klant;
    data.options.forEach((element) => {
      if (element.value === data.value) {
        klant = element;
      }
    });
    this.setState({ selectedKlant: klant }, () => {
      this.getData(klant.value);
    });
  };

  saveProces = () => {
    const { treeData, selectedKlant } = this.state;

    this.setState({ loading: true });
    let newTree = this.changeTree(treeData);

    fetch("/api/proces", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ klantId: selectedKlant.value, normId: 4 }),
    })
      .then((res) => res.json())
      .then((response) => {
        fetch("/api/proces", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ klant: selectedKlant.value, newTree }),
        })
          .then((res) => res.json())
          .then((response) => {
            this.setState({ loading: false });
          });
      });
  };

  editButtonChange = new Selection({
    onSelectionChanged: () =>
      this.setState({
        editButton: true,
      }),
  });

  showPanel = () => {
    this.changeSomething2();
    this.setState({ hideGridview: true });
  };

  _getSelectionDetailsNameItem = () => {
    const selectionCount = this.editButtonChange.getSelectedCount();

    switch (selectionCount) {
      case 0:
        return "No items selected";
      case 1:
        // this.handleThemeChange();
        return this.editButtonChange.getSelection()[0];
      default:
        return `${selectionCount} items selected`;
    }
  };

  changeSomething2 = () => {
    var klant = this._getSelectionDetailsNameItem();
    // this.updateJSON();
    console.log("Klant", klant);
    this.setState({ selectedKlant: klant }, () => {
      this.getData(klant.value);
    });
  };

  editButtonChange = new Selection({
    onSelectionChanged: () =>
      this.setState({
        editButton: true,
      }),
  });

  render() {
    const { treeData, loading, klanten, selectedKlant } = this.state;
    this._columns = [
      {
        key: "text",
        name: "Organisatie",
        fieldName: "text",
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        isSorted: true,
      },
      {
        key: "uniqueID",
        name: "GUID",
        fieldName: "uniqueID",
        minWidth: 50,
        maxWidth: 50,
        isResizable: true,
        isSorted: true,
      },
    ];
    const canDrop = ({ node, nextParent, prevPath, nextPath }) => {
      if (node.noDragging) return false;

      if (nextParent)
        if (nextParent.noChildren)
          // top-level items don't have a parent
          // you may NOT drag an item to a parent which has noChildren enabled
          return false;

      if (node.type === "ProcesStap") {
        if (nextParent) {
          if (nextParent.type === "Proces") {
            // you may drag a RiskArea to a different RM Model
            return true;
          }
        }

        return false;
      }

      if (node.type === "ProcesSubstap") {
        if (nextParent) {
          if (nextParent.type === "ProcesStap") {
            // you may drag a keycontrol to a different riskarea
            return true;
          }
        }

        return false;
      }
    };

    if (loading) {
      return (
        <Placeholder fluid>
          <Placeholder.Header image>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Header>
          <Placeholder.Paragraph>
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Paragraph>
        </Placeholder>
      );
    }
    if (klanten.length > 0) {
      return (
        <>
        
           <h4 class="section-divider sectionHeadings">
                <Icon
                  style={{ verticalAlign: "bottom" }}
                  iconName="Info"
                ></Icon>{" "}
                Klantgegevens
              </h4>
              {this.state.editButton && !this.state.hideGridview && (
            <CommandBarControl EditItem={this.showPanel} />
          )}
          {!this.state.hideGridview && (
            
            <div className="detailList">
              <DetailsList
                selectionMode="single"
                items={klanten}
                columns={this._columns}
                setKey="set"
                layoutMode={DetailsListLayoutMode.justified}
                onItemInvoked={this._onItemInvoked}
                ariaLabelForSelectionColumn="Toggle selection"
                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                checkButtonAriaLabel="Row checkbox"
                selection={this.editButtonChange}
                compact={false}
                selectionPreservedOnEmptyClick={true}
              />
            </div>
          )}
          {/* <Segment placeholder>
            {/* <Header icon>
              {!selectedKlant ? (
                <span>
                  <Icon name="search" />
                  Nog geen klant geselecteerd
                </span>
              ) : (
                <div></div>
              )}
            </Header> */}
          {/* <Dropdown
              button
              className="icon"
              floating
              labeled
              icon="address card outline"
              options={klanten}
              search
              text="Selecteer klant"
              onChange={this.changeSomething}
            /> */}
          {/* </Segment> */}

          {selectedKlant && this.state.hideGridview ? (
            <Segment padded>
              <Segment.Inline>
                <h3>{selectedKlant.text}</h3>
                <Button
                  positive
                  circular
                  icon="add"
                  onClick={() =>
                    this.setState((state) => ({
                      treeData: state.treeData.concat({
                        id: 0,
                        title: "NIEUW Proces",
                        NormNaam: "",
                        NormTypeNaam: "",
                        Klantnaam: "",
                        Url: "",
                        LinkedPage: "",
                        MenuType: "",
                        CustomStyle: false,
                        CustomColor: "",
                        NewTab: false,
                        children: [],
                        subtitle: "Proces",
                        type: "Proces",
                        noChildren: false,
                        noDragging: false,
                        noEdit: false,
                        noAdd: false,
                        Sequence: 0
                      }),
                    }))
                  }
                />
              </Segment.Inline>

              <Segment.Group>
                <SortableTree
                  maxDepth={3}
                  isVirtualized={false}
                  treeData={treeData}
                  canDrop={canDrop}
                  onChange={(treeData) => this.setState({ treeData })}
                  generateNodeProps={({ node, path, parentNode }) => ({
                    buttons: [
                      <>
                        <InfoModal node={node} type={node.type} />
                        {node.noEdit ? null : (
                          <>
                            <DeleteModal
                              node={node}
                              closeAction={() =>
                                this.setState((state) => ({
                                  treeData: removeNodeAtPath({
                                    treeData: state.treeData,
                                    path,
                                    getNodeKey,
                                  }),
                                }))
                              }
                            />
                            <EditModal
                              node={node}
                              path={path}
                              saveAction={this.editLevel}
                              type={node.type}
                            />
                          </>
                        )}
                        {node.noAdd ? null : (
                          <Button
                            as="a"
                            icon="add"
                            color="green"
                            title="node toevoegen"
                            onClick={(e) => this.addLevel(e, node, path)}
                            size="mini"
                          ></Button>
                        )}
                      </>,
                    ],
                  })}
                />
              </Segment.Group>
              <Segment.Inline>
                <PrimaryButton className="submitButton" onClick={() => this.saveProces()} positive>
                  Opslaan
                </PrimaryButton>
                <DefaultButton
                  onClick={() => this.setState({ hideGridview: false })}
                  positive
                >
                  Annuleer
                </DefaultButton>
              </Segment.Inline>
            </Segment>
          ) : (
            <div></div>
          )}
        </>
      );
    } else {
      return null;
    }
  }
}
