import React, { Component } from "react";
import "./styles.css";
import { Icon, PrimaryButton } from "@fluentui/react";
import {
  DetailsList,
  DetailsListLayoutMode,
  Selection,
  SelectionMode,
} from "@fluentui/react/lib/DetailsList"
import { CommandBarEditDeleteControl } from "../Controls/CommandBar/commandBarEditDelete";
import { TilesService } from "../Services/TilesService";
import { EditTilesPanel } from "../Controls/Tiles/EditTilesPanel";
import { Tile } from "../Services/Entities/Tile";

export class TilesInvullen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editButton: false,
      tiles:[],
      selectedItem: new Tile(),
      selectableSections:[],
      panelOpen: false,
    };

    this._changeNewItem = () => {
      this.setState({
        panelOpen: true,
        selectedItem: new Tile(),
      });
    };

    this.getTiles = () => {
      if(this.props.selectedOrganisation != null)
      {
        console.log("this.props.selectedOrganisation: ", this.props.selectedOrganisation);
        TilesService.Instance.GetTilesByCustomer(this.props.selectedOrganisation).then((customerTiles)=>{
          console.log("CUSTOMER TILES", customerTiles);
          this.setState({tiles: customerTiles})
          });
      }
      else
      {
        TilesService.Instance.GetAll().then((allTiles) => {
          console.log("TILES", allTiles);
          this.setState({ tiles: allTiles });
        });
      }
    };

    this._selection = new Selection({
      onSelectionChanged: () =>
        this.setState({
          editButton: true,
        }),
    });

    this.editItem = () => {
      const selectionCount = this._selection.getSelectedCount();

      if (selectionCount === 1) {
        this.setState({
          selectedItem: this._selection.getSelection()[0],
          panelOpen: true,
        });
      }
    };

    this.onDeleteSelectedItem = () => {
      var selectedTile = this._selection.getSelection()[0];

      console.log("Delete selected tile", selectedTile);
      fetch("/api/tiles/remove", {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(selectedTile),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("Success:", data);
          this.setState(
            {
              newItemCheck: false,
              editButton: false,
              tiles: [],
              selectedItem: null,
              panelOpen: false
            },
            () => {
              this.getTiles();
              
            }
          );
        })
        .catch((error) => {
          console.error("Error:", error);
        });
       
    };
  
    this.closeEditAddPanel = () => {
      this.setState({ panelOpen: false });
    };

    this.onEditAddPanelSave = (item) => {
      TilesService.Instance.SaveTiles(item).then((isSucces) => {
        this.setState({ panelOpen: false });
        this.getTiles();
      });
    };
  }

  componentDidMount() {
    this.getTiles();
  }

  render() {
    this._columns = [
      {
        key: "organisation",
        name: "Organisatie",
        fieldName: "organisation",
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
      },
      {
        key: "sectionTitle",
        name: "Sectie Titel",
        fieldName: "sectionTitle",
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
      },
      {
        key: "tileTitle",
        name: "Tegel Titel",
        fieldName: "tileTitle",
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
      },
      {
        key: "iconName",
        name: "Tegel Icon",
        fieldName: "iconName",
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
      },
      {
        key: "sequence",
        name: "Tegel Sequence",
        fieldName: "sequence",
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
      },
      {
        key: "paginatitle",
        name: "Pagina Titel",
        fieldName: "paginaTitle",
        minWidth: 100,
        maxWidth: 200,
        isResizable: true
      },
      {
        key: "pageUrl",
        name: "Pagina Url",
        fieldName: "pageUrl",
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
      },
    ];
    return (
      <div className="gridWrapper">
        <h1>Tegel invullen</h1>
        <div className="ms-Grid-col ms-sm12 ms-md12">
          <PrimaryButton
            iconProps={{ iconName: "Add", className: "ms-AddIcon" }}
            onClick={this._changeNewItem}
            className="submitButton"
            text="Nieuwe tegel"
          />
        </div>
        <h4 class="section-divider sectionHeadings">
          <Icon style={{ verticalAlign: "bottom" }} iconName="Info"></Icon>{" "}
          Tegel invullen
        </h4>
        <div className="ms-Grid-col ms-col12 ms-md12">
          {/* {this.state.editButton && this.state.selectedItem !== null && (
            <CommandBarControl EditItem={this.editItem} />
          )} */}
           {this.state.editButton && this.state.selectedItem !== null && (
            <CommandBarEditDeleteControl EditItem={this.editItem} DeleteItem={this.onDeleteSelectedItem} />
          )}
          <div className="detailList">
            <DetailsList
              items={this.state.tiles}
              selectionMode={SelectionMode.single}
              enterModalSelectionOnTouch={true}
              onColumnResize={false}
              textAlign="left"
              verticalAlign="left"
              columns={this._columns}
              setKey="set"
              layoutMode={DetailsListLayoutMode.justified}
              ariaLabelForSelectionColumn="Toggle selection"
              ariaLabelForSelectAllCheckbox="Toggle selection for all items"
              checkButtonAriaLabel="Row checkbox"
              selection={this._selection}
              compact={false}
              selectionPreservedOnEmptyClick={true}
            />
          </div>
          <EditTilesPanel
            panelOpen={this.state.panelOpen}
            onDismiss={this.closeEditAddPanel}
            selectableOrganisations={this.props.selectableOrganisations}
            itemToEdit={this.state.selectedItem}
            OnPanelSave={this.onEditAddPanelSave}
            selectableSections={this.state.selectableSections}
            selectedOrganisation={this.props.selectedOrganisation}
          ></EditTilesPanel>
        </div>
      </div>
    );
  }
}
