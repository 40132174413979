export class TilePageService {

    static instance;

    static get Instance() {

        if(this.instance == null) {
            this.instance = new TilePageService();
        }

        return this.instance;
    }

    GetAll(customerFilter) {
        var url = "/api/tilepage/all";
        if (customerFilter && customerFilter > 0)
        {
            url = "/api/tilepage/" + customerFilter + "/all"
        }
        return new Promise((resolve, reject) => {
            fetch(url)
            .then((response) => response.json())
            .then((data) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
        });
    }

    GetPageTitlesByCustomer(customerFilter)
    {
        var url = "/api/tiles/pagetitles/" + customerFilter;
        return new Promise((resolve, reject) => {
            fetch(url)
            .then((response) => response.json())
            .then((data) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
        });
    }

    GetSupportedTilePageTemplateOptions() {
        var selectableDropdownOptions = [
            { key: "TT2", text: "Template 2 - Flow" },
            { key: "TT4", text: "Template 4 - Tekst" },
            { key: "TT5", text: "Template 5 - Flow en toelichting" }
          ];

        return selectableDropdownOptions;
    }

    Save(pageItem)
    {
        // POST for add, PUT for update
        var restMethod = pageItem.id == null ? "POST" : "PUT";
        //var restMethod = "POST";
        
        return new Promise((resolve, reject) => {
            fetch("/api/tilepage", {
                method: restMethod,
                headers: {
                "Content-Type": "application/json",
                },
                body: JSON.stringify(pageItem),
            })
            .then((response) => response.json())
            .then((data) => {
                console.log("Success:", data);
                resolve(true);            
            })
            .catch((error) => {
                console.error("Error:", error);
                reject(error);
            });
        });
    }

    SaveFile(file)
    {
        const formData = new FormData();
        formData.append("image", file);
    
        const options = {
            method: "POST",
            body: formData,
        };
    
        return new Promise((resolve, reject) => {
            fetch("/api/files", options)
            .then((response) => response.json())
            .then((data) => {
                resolve(data);
            })
            .catch((error) => {
                console.error("Error:", error);
                reject(error);
            });
        });
    }
}