import React, { Component } from 'react';
import { Button, Header, Icon, Modal } from 'semantic-ui-react'


export default class DeleteModal extends Component {
  state = { open: false }

  onClose = (e) => {
    const { node } = this.props

    this.props.closeAction(e, node)
    this.onOpen(e)
  }

  onOpen = (e) => {
    const { open } = this.state
    e.preventDefault();
    this.setState({ open: !open })
  }

  render () {
    const { open } = this.state
    const { node } = this.props
    return (
      <Modal open={open} size='large' onClose={(e) => this.onOpen(e)} closeIcon closeOnDocumentClick={true} trigger={
          <Button as='a' icon='delete' color='red' title='Verwijderen' onClick={(e) => this.onOpen(e)} size='mini'>
          </Button>
        }>
        
        <Header>
          <Icon name='trash' />
          <Header.Content>{node.title} verwijderen</Header.Content>
        </Header>
        <Modal.Content>
          <p>Dit zal de huidige node en eventuele node(s) dieper verwijderen.</p>
          <p>Weet u dit zeker?</p>
        </Modal.Content>
        <Modal.Actions>
          <Button negative as='a' onClick={(e) => this.onOpen(e)}><Icon name='close' /> Nee</Button>
          <Button positive as='a' onClick={(e) => this.onClose(e)} active><Icon name='trash' /> Ja</Button>
        </Modal.Actions>
      </Modal>
    )
  }
}