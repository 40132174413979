import React, { Component } from "react";
import { TextField } from "office-ui-fabric-react/lib/TextField";
import { ComboBox, registerDefaultFontFaces } from "office-ui-fabric-react";
import NormSelector from "../NormSelector/normSelector"
export class EditLicentie extends Component {
    constructor(props) {
        super(props);
        this.state = {
            normResults:[]
        }
    
//         this.convertToObject = ()=>
//         {
//             // var stringToConvert = this.props.Norms;
//             // var convertedArray  = [...stringToConvert];
//             // return convertedArray;
//             const data = this.props.Norms;
//             const usingSplit = data.split(',');
//             const result = JSON.stringify(usingSplit)
//             return result;
//         }
//         this.componentDidMount = () => 
//    {

//     const results = this.convertToObject();
//     this.setState({normResults:results})
//    }
}
  render() {
    
 

  


   
  
    return (
       
      <div>
        <TextField
          readOnly
          name="uniqueID"
          defaultValue={this.props.GUID}
          label="GUID"
        />
        <TextField
          readOnly
          required={true}
          name="text"
          defaultValue={this.props.Organisatie}
          label="Organisatie"
        />
        <TextField
          required={true}
          name="voornaam"
          defaultValue={this.props.Voornaam}
          onChange={this.props.onChange}
          label="Naam"
        />
        {/* <TextField name="naam" defaultValue={this.props.Naam} onChange={this.props.onChange} label="Naam"/> */}
        <TextField
          required={true}
          name="email"
          defaultValue={this.props.Email}
          onChange={this.props.onChange}
          label="Email"
        />
        <TextField
          required={true}
          name="telefoon"
          defaultValue={this.props.Telefoon}
          onChange={this.props.onChange}
          label="Telefoon"
        />
     
           <div>
        <NormSelector klantId={this.props.KlantId} klantNaam={this.props.Organisatie}></NormSelector></div>
        {/* <ComboBox
          multiSelect
          label="Norm selectie"
          id="normSelector"
         
          required={true}
        //   defaultValue={a}
        // selectedKey={"BRC"}
        // defaultValue={results}
    
        defaultSelectedKey={this.state.normResults}
   
        placeholder={this.props.Norms}
        
          //     onChanged={this.handleNormsChange}
          //     return { key: item.value, text: item };
          //   })}
       
          onChange={this.props.comboboxChange}
          onChanged={this.props.comboboxChange}
          options={this.props.normSelection}
          // onError={this.errorMultiselect}
        /> */}
      </div>
    );
  }
}
