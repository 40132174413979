import React, { Component } from "react";
import {
  DefaultButton,
  PrimaryButton,
} from "office-ui-fabric-react/lib/Button";
import { Panel } from "office-ui-fabric-react/lib/Panel";
import { EditLicentie } from "./editLicentieInfo";
import "./style.css"

export class LicentiePanel extends Component {
  closePanel = () => {
    return (
      <div>
        <PrimaryButton className="submitButton" onClick={this.props.onPanelSave.bind(this)}>
          Opslaan
        </PrimaryButton>
        <DefaultButton className="submitButton" onClick={this.props.onDismiss.bind(this)}>
          Annuleer
        </DefaultButton>
      </div>
    );
  };

  render = () => {
    return (
      <div className="customPanel">
        <Panel
          isOpen={this.props.panelOpen}
          onDismiss={this.props.onDismiss.bind(this)}
          headerText={this.props.panelHeader}
          closeButtonAriaLabel="Close"
          aria-multiselectable={false}
          onRenderFooterContent={this.closePanel}
          isFooterAtBottom={true}
        >
          <EditLicentie
            Naam={this.props.Naam}
            Voornaam={this.props.Voornaam}
            Telefoon={this.props.Telefoon}
            Email={this.props.Email}
            Organisatie={this.props.Organisatie}
            GUID={this.props.GUID}
            Norms={this.props.Norms}
            normSelection={this.props.normSelection}
            KlantId={this.props.KlantId}
            onChange={this.props.onChange}
            className="customPanel"
            comboboxChange={this.props.comboboxChange}
          ></EditLicentie>
        </Panel>
      </div>
    );
  };
}
