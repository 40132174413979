// Data Definition of a Tile. 
// must match the columns returned from the Rest API.
export class Tile {

    tileID;
    tileTitle;
    klantId;
    sequence
    iconName;
    pageID;
    sectionID;

    constructor()
    {
        this.tileID = null;
        this.tileTitle = "";
        this.klantId = "";
        this.sequence = "";
        this.iconName = "";
        this.pageID = null;
        this.sectionID = "";
    }
}