import React, { Component } from "react";
import {
  DefaultButton,
  PrimaryButton,
} from "office-ui-fabric-react/lib/Button";
import { Panel } from "office-ui-fabric-react/lib/Panel";
import { EditAdmin } from "./editAdmin";

export class CustomPanel extends Component {
  closePanel = () => {
    return (
      <div>
        <PrimaryButton className="submitButton" onClick={this.props.onPanelSave.bind(this)}>
          Save
        </PrimaryButton>
        <DefaultButton className="submitButton" onClick={this.props.onDismiss.bind(this)}>
          Cancel
        </DefaultButton>
      </div>
    );
  };

  render = () => {
    // const {dismissPanel} = this.state
    return (
      <div>
        <Panel
          isOpen={this.props.panelOpen}
          onDismiss={this.props.onDismiss.bind(this)}
          headerText={this.props.panelHeader}
          closeButtonAriaLabel="Close"
          aria-multiselectable={false}
          onRenderFooterContent={this.closePanel}
          // Stretch panel content to fill the available height so the footer is positioned
          // at the bottom of the page
          isFooterAtBottom={true}
        >
          <EditAdmin
            Naam={this.props.Naam}
            Email={this.props.Email}
            Organisatie={this.props.Organisatie}
            OrganisatieSelectie={this.props.OrganisatieSelectie}
            SuperAdmin={this.props.SuperAdmin}
            ToggleOnchange={this.props.ToggleOnchange}
            ToggleDropdown={this.props.ToggleDropdown}
            EditItems={this.props.EditItems}
          ></EditAdmin>
        </Panel>
      </div>
    );
  };
}
