import React, { Component } from "react";
import { OpenListButton } from "../Controls/OpenListButton/openListButton";
import "./styles.css";
export class Wetgeving extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listURL: "",
    };
  }
  openListURL = () => {
    window.open(this.props.siteURL+"/Lists/Overzicht%20wet%20en%20regelgeving/AllItems1.aspx");
  };
  render() {
    return (
      <div className="gridWrapper">
        <h1>Wet- en regelgeving</h1>
        <p>
          In deze omgeving worden alle van toepassing zijnde wet- en regelgeving
          ingevoerd. Daarbij wordt aangegeven in welke mate wetgeving van
          toepassing is, waarom en wat precies de gevolgen zijn voor de
          organisatie. Het is mogelijk om alle wetgeving toe te voegen en later
          te kunnen filteren op kwaliteit of informatiebeveiliging. Daarbij
          wordt de bron vermeld en wordt aangegeven hoe kan worden aangetoond
          dat de organisatie voldoet aan de geldende wetgeving. Tevens is
          inzichtelijk wanneer de laatste aanpassingen hebben plaatsgevonden.{" "}
        </p>
        {this.props.siteURL ? (
          <OpenListButton openList={this.openListURL} />
        ) : (
          <p className="noOpenListButton">
            U heeft geen toegang tot dit scherm...
          </p>
        )}
      </div>
    );
  }
}
