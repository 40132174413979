// Data Definition of a NewsItem. 
// must match the columns returned from the Rest API.
export class BranchItem {
    branchID;
    name;
    klantId;

    constructor()
    {
        this.branchID = "";
        this.name = "";
        this.klantId = "";
    }
}