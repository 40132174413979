import React, { Component } from "react";
import "./styles.css";
import KlantReisManager from "../Containers/Klantreis/KlantReisManager";

export class OpmakenKlantries extends Component {
  render() {
    return (
      <div className="gridWrapper">
        <h1>Opmaken reis</h1>
        <p>
          In deze omgeving wordt het primaire proces van klant tot klant
          weergegeven. Er kan ook worden gekozen voor een modelketen of andere
          procesvormen. Belangrijk hierbij is, hoe de processen toegankelijk
          gemaakt kunnen worden voor medewerkers. Waarin herkennen medewerkers
          zich het snelst? Er kunnen maximaal 9 deelprocessen worden ingevoerd,
          en per deelproces kunnen er 2 verdiepingsslagen worden gemaakt. Aan
          alle processen zit een documentenbibliotheek, zodat verdere
          instructies en formulieren hieraan gekoppeld kunnen worden. De
          managementprocessen, ondersteunende processen en beheersingsprocessen
          worden als tegels weergegeven, die worden niet genoemd in de
          klantreis.
        </p>
        <KlantReisManager klantNaam={this.props.klantNaam} superAdmin={this.props.superAdmin}></KlantReisManager>
      </div>
    );
  }
}
