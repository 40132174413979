// Data Definition of a TilesSectionItem. 
// must match the columns returned from the Rest API.
export class TilesSectionItem {
    sectionID;
    sectionTitle;
    klantId;
    position;

    constructor()
    {
        this.sectionID = null;
        this.sectionTitle = "";
        this.klantId = null;
        this.position = null;
    }
}