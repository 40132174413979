import React, { Component } from "react";

import {
  Button,
  Checkbox,
  Dropdown,
  Form,
  Header,
  Icon,
  Input,
  Modal,
} from "semantic-ui-react";
import "jodit";
import "jodit/build/jodit.min.css";
import JoditEditor from "jodit-react";
import {
  Dialog,
  DialogType,
  DialogFooter,
} from "office-ui-fabric-react/lib/Dialog";
import {
  PrimaryButton,
  DefaultButton,
} from "office-ui-fabric-react/lib/Button";
import { ChromePicker } from "react-color";

// const rawMarkup = (text) => {
//   if (text) {
//     text = text.replace(new RegExp('<p>', 'g'), '')
//     text = text.replace(new RegExp('</p>', 'g'), '<br />')
//     return { __html: text }
//   }
//   else
//   {
//     return null
//   }
// }

const checkType = [
  { key: "1", text: "Checkbox", value: 1 },
  { key: "2", text: "Number", value: 2 },
  { key: "3", text: "Textbox", value: 3 },
  { key: "4", text: "DateTime", value: 4 },
  { key: "5", text: "DropDown", value: 5 },
];
const dialogContentProps = {
  type: DialogType.largeHeader,
  title: "Fout!",
  subText:
    "De titel van het proces mag niet langer zijn dan 28 letters. Corrigeer alstublieft",
};

export default class EditModal extends Component {
  state = { open: false, node: this.props.node, selected: [], dialog: false };

  jodit;
  setRef = (jodit) => (this.jodit = jodit);

  config = {
    readonly: false, // all options from https://xdsoft.net/jodit/doc/
    removeButtons: [
      "hr",
      "underline",
      "strikethrough",
      "image",
      "file",
      "video",
      "fullsize",
      "selectall",
      "print",
      "about",
      "symbol",
    ],
    defaultMode: "3",
    showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false,
    disablePlugins: "bold",
  };

  onOpen = (e) => {
    e.preventDefault();
    this.setState({
      open: true,
      node: this.props.node,
      selected:
        this.props.node["checkchoices"] > []
          ? this.props.node["checkchoices"].map((choice) => choice.value)
          : [],
    });
  };

  onClose = (e) => {
    e.preventDefault();
    this.setState({ open: false });
  };

  AddField = (e, { value }) => {
    // this is handled completely in dropDownChange!
  };

  dropDownChange = (e, { value }) => {
    let { node } = this.state;
    let choices = [];
    value.forEach((option) => {
      const getId = node.checkchoices
        .filter((checkchoice) => checkchoice.value === option)
        .map((checkchoice) => checkchoice.id);
      const Id = getId > [] ? String(getId) : 0;
      choices = [
        ...choices,
        {
          choiceoption: option,
          key: Math.random(),
          id: Id,
          text: option,
          value: option,
          checkid: node.id,
          ordering: 0,
        },
      ];
    });

    node["checkchoices"] = choices;

    this.setState({
      selected: value,
      node: node,
    });
  };

  editField = (e, { name, value, checked }) => {
    let { node } = this.state;

    if (value !== undefined) {
      node[name] = value;
    } else {
      node[name] = checked;
    }

    this.setState({ node: node });
  };

  editInfoField = (value) => {
    let { node } = this.state;
    node["information"] = value;
    this.setState({ node: node });
  };

  componentDidMount = () => {};

  handleChangeComplete = (color, event) => {
    let { node } = this.state;
    node["CustomColor"] = color.hex;
    this.setState({ node: node });
  };

  onSave = (e) => {
    const { path } = this.props;
    if (this.state.node.title.length <= 28) {
      this.props.saveAction(path, this.state.node);
      this.onClose(e);
    } else {
      // alert('The title of the process cant be more than 15 characters long, please correct')
      this.setState({ dialog: true,open:true });
    }
  };
  toggleHideDialog = () => {
    this.setState({ dialog: false,open:true});
  };

  capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  render() {
    const { open, node, selected } = this.state;
    console.log(node);
    const keys = Object.keys(node);
    console.log(keys);

    return (
      <React.Fragment>
        <Modal
          open={open}
          size="large"
          onOpen={this.onOpen}
          onClose={this.onClose}
          closeIcon
          closeOnDocumentClick={true}
          trigger={
            <Button
              as="a"
              color="blue"
              icon="pencil"
              title="Bewerken"
              size="mini"
            ></Button>
          }
        >
          <Header>
            <Icon name="pencil" />
            <Header.Content>{node.title} bewerken</Header.Content>
          </Header>
          <Modal.Content>
            <Form size="tiny">
              {keys.map((key, index) => {
                if (
                  key === "id" ||
                  key === "noEdit" ||
                  key === "noChildren" ||
                  key === "subtitle" ||
                  key === "noDragging" ||
                  key === "type" ||
                  key === "noAdd" ||
                  key === "expanded" ||
                  key === "children" ||
                  key === "Klantnaam" ||
                  key === "NormTypeNaam" ||
                  key === "NormNaam" ||
                  key === "ParentID" 
                ) {
                  return null;
                } else {
                  if (
                    typeof node[key] === "string" ||
                    typeof node[key] === "number"
                  ) {
                    if (key === "information") {
                      return (
                        <Form.Field width="16" key={index}>
                          <label>{this.capitalizeFirstLetter(key)}:</label>
                          <JoditEditor
                            style={{ minHeight: 100, width: 1010 }}
                            editorRef={this.jodit}
                            config={this.config}
                            name="information"
                            value={node[key]}
                            onChange={this.editInfoField}
                          />
                        </Form.Field>
                      );
                    } else if (key === "CustomColor") {
                      return (
                        <Form.Field width="16" key={index}>
                          <label>{this.capitalizeFirstLetter(key)}:</label>
                          <ChromePicker
                            disableAlpha={true}
                            onChangeComplete={this.handleChangeComplete}
                            color={node[key]}
                          />
                        </Form.Field>
                      );
                    } else if (key === "checktype") {
                      let AddNode = null;
                      if (Number(node[key]) === 5) {
                        AddNode = (
                          <Form.Field width="14" key={Math.random()}>
                            <Dropdown
                              key={Math.random()}
                              options={node["checkchoices"]}
                              placeholder="Add options"
                              search
                              selection
                              fluid
                              allowAdditions
                              multiple
                              value={selected}
                              onAddItem={this.AddField}
                              onChange={this.dropDownChange}
                            />
                          </Form.Field>
                        );
                      }

                      return (
                        <>
                          <Form.Field width="14" key={index}>
                            <label>{this.capitalizeFirstLetter(key)}:</label>
                            <Dropdown
                              name={key}
                              options={checkType}
                              selection
                              onChange={this.editField}
                              value={Number(node[key])}
                            />
                          </Form.Field>
                          {AddNode}
                        </>
                      );
                    } else {
                      return (
                        <Form.Field width="16" key={index}>
                          <label>{this.capitalizeFirstLetter(key)}:</label>
                          <Input
                            size="large"
                            value={node[key]}
                            name={key}
                            onChange={this.editField}
                          />
                        </Form.Field>
                      );
                    }
                  } else if (typeof node[key] == "boolean") {
                    return (
                      <Form.Field width="16" key={index}>
                        <label>{this.capitalizeFirstLetter(key)}:</label>
                        <Checkbox
                          size="large"
                          name={key}
                          defaultChecked={node[key]}
                          onChange={this.editField}
                        ></Checkbox>
                      </Form.Field>
                    );
                  } else if (node[key] === null) {
                    return (
                      <Form.Field width="16" key={index}>
                        <label>{this.capitalizeFirstLetter(key)}:</label>
                        <Input
                          size="large"
                          value={node[key]}
                          name={key}
                          onChange={this.editField}
                        />
                      </Form.Field>
                    );
                  }
                }
                return null;
              })}
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button
              as="a"
              onClick={this.onSave}
              content="Opslaan"
              color="blue"
            />
          </Modal.Actions>
        </Modal>
        <Dialog
          hidden={!this.state.dialog}
          onDismiss={this.toggleHideDialog}
          dialogContentProps={dialogContentProps}
        >
          <DialogFooter>
            <PrimaryButton onClick={this.toggleHideDialog} text="OK" />
          </DialogFooter>
        </Dialog>
      </React.Fragment>
    );
  }
}
