import React, { Component } from "react";
import { Form } from "semantic-ui-react";
import NormSelector from "../NormSelector/normSelector";
import {
  generatePallette,
  generatePowershellPallette,
} from "../ThemeGenerator/themeGen";
import { ChromePicker } from "react-color";
import {
  DetailsList,
  DetailsListLayoutMode,
  Selection,
} from "office-ui-fabric-react/lib/DetailsList";
import { Icon, MessageBar, MessageBarType } from "office-ui-fabric-react/lib";
import { TextField } from "office-ui-fabric-react/lib/TextField";
import { Checkbox } from "office-ui-fabric-react/lib/Checkbox";
import { PrimaryButton, DefaultButton } from "office-ui-fabric-react";
import { CommandBarControl } from "../CommandBar/commandBar";
import { Panel } from "office-ui-fabric-react/lib/Panel";
import "./style.css";

export default class KlantSelector extends Component {
  state = {
    klanten: [],
    loading: true,
    errorMessage: "",
    openPanel: false,
    editButton: false,
    selectedKlant: undefined,
    addKlant: false,
    newKlant: {
      uniqueID: "",
      text: "",
      enabled: false,
      primarycolor: "",
      textcolor: "",
      backgroundcolor: "",
      themeps: "",
    },
  };

  closePanel = () => {
    return (
      <div>
        <PrimaryButton className="submitButton" onClick={this.updateKlant}>
          Opslaan
        </PrimaryButton>
        <DefaultButton onClick={this.cancel}>Annuleer</DefaultButton>
      </div>
    );
  };

  // useEffect= (() => {
  //   alert('ekker')
  //   const { newKlant } = this.state;

  //   const primaryCol = newKlant.primarycolor;
  //   const backgroundCol = newKlant.backgroundcolor;
  //   const textColor = newKlant.textcolor;

  //   if (primaryCol && backgroundCol && textColor) {
  //      const powershellobject =  generatePowershellPallette(
  //       primaryCol,
  //       backgroundCol,
  //       textColor
  //     );
  //     const jsonobject = JSON.stringify(
  //       generatePallette(primaryCol, backgroundCol, textColor)
  //      );

  //     this.setState((prevState) => ({
  //       newKlant: {
  //         ...prevState.newKlant,
  //         themeps: powershellobject,
  //         themejson: jsonobject,

  //       },
  //     }));
  //   }
  // },[newKlant]);

  handleThemeChange = () => {
    const { newKlant } = this.state;

    const primaryCol = newKlant.primarycolor;
    const backgroundCol = newKlant.backgroundcolor;
    const textColor = newKlant.textcolor;

    if (primaryCol && backgroundCol && textColor) {
      const powershellobject = generatePowershellPallette(
        primaryCol,
        backgroundCol,
        textColor
      );
      // const jsonobject = JSON.stringify(
      //   generatePallette(primaryCol, backgroundCol, textColor)
      //  );

      this.setState((prevState) => ({
        newKlant: {
          ...prevState.newKlant,
          themeps: powershellobject,
          // themejson: jsonobject,
        },
      }));
    }
  };

  getKlanten = () => {
    const checkSuperAdmin = this.props.superAdmin;
    const organisatie = this.props.klantNaam;
    if(!checkSuperAdmin)
    {
    fetch("/api/klant/all")
      .then((response) => response.json())
      .then((data) => {
        // this.updateJSON();
        // this.updatePowerShellTheme();
        console.log("Klanten result data");
        console.log(data);
        this.setState({ klanten: data.filter(person => person.text === organisatie), loading: false });
      });
    }
    else{
      fetch("/api/klant/all")
      .then((response) => response.json())
      .then((data) => {
        // this.updateJSON();
        // this.updatePowerShellTheme();
        this.setState({ klanten: data, loading: false });
      });
    }
  };

  editButtonChange = new Selection({
    onSelectionChanged: () =>
      this.setState({
        editButton: this.editButtonChange.getSelectedCount() === 1,
      }),
  });
  AddKlant = () => {
    const { newKlant, addKlant } = this.state;
    fetch("/api/klant", {
      method: "POST", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newKlant),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
        // this.updateJSON();
        this.setState(
          {
            loading: true,
            addKlant: !addKlant,
            selectedKlant: undefined,
            newKlant: {
              uniqueID: "",
              text: "",
              enabled: false,
              primarycolor: "",
              textcolor: "",
              backgroundcolor: "",
              themeps: "",
            },
          },
          () => {
            this.getKlanten();
          }
        );
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  updateKlant = () => {
    const { newKlant } = this.state;

    if (newKlant.text) {
      fetch("/api/klant", {
        method: "PUT", // or 'PUT'
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newKlant),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("Success:", data);

          this.setState(
            {
              loading: true,
              selectedKlant: undefined,
              newKlant: {
                uniqueID: "",
                text: "",
                enabled: false,
                primarycolor: "",
                textcolor: "",
                backgroundcolor: "",
                themeps: "",
              },
            },
            () => {
              this.getKlanten();
            }
          );
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  cancel = () => {
    this.setState({
      selectedKlant: undefined,
      openPanel: false,
      addKlant: false,
      newKlant: {
        uniqueID: "",
        text: "",
        enabled: false,
        primarycolor: "",
        textcolor: "",
        backgroundcolor: "",
        themeps: "",
      },
    });
  };

  // editField = (e, { name, value, checked }) => {
  //   let { newKlant } = this.state;

  //   if (value !== undefined) {
  //     newKlant[name] = value;
  //   } else {
  //     newKlant[name] = checked;
  //   }

  //   this.setState({ newKlant: newKlant });
  // };

  editField = (e) => {
    let { newKlant } = this.state;

    if (e.target.value !== undefined) {
      newKlant[e.target.name] = e.target.value;
    } else {
      newKlant[e.target.name] = e.target.checked;
    }

    this.setState({ newKlant: newKlant });
    this.handleThemeChange();
    this.handleCheckValidation();
  };

  handleCheckboxChange = (e) => {
    // let { newKlant } = this.state;
    const { checked } = e.target;

    this.setState((prevState) => ({
      newKlant: {
        ...prevState.newKlant,
        enabled: checked,
      },
    }));
    this.handleThemeChange();
  };

  handlePrimaryColor = (color, event) => {
    console.log("Color", color.hex);
    this.setState((prevState) => ({
      newKlant: {
        ...prevState.newKlant,
        primarycolor: color.hex,
      },
    }));
    this.handleThemeChange();
  };

  handleBackgroundColor = (color, event) => {
    console.log("Color background", color.hex);
    this.setState((prevState) => ({
      newKlant: {
        ...prevState.newKlant,
        backgroundcolor: color.hex,
      },
    }));
    this.handleThemeChange();
  };

  handletextColor = (color, event) => {
    console.log("Color text", color.hex);
    this.setState((prevState) => ({
      newKlant: {
        ...prevState.newKlant,
        textcolor: color.hex,
      },
    }));
    this.handleThemeChange();
  };

  handleCheckValidation = () => {
    const { newKlant } = this.state;
    if (!newKlant.text) {
      this.setState({ errorMessage: "Dit veld is verplicht" });
    } else {
      this.setState({ errorMessage: "" });
    }
  };

  componentDidMount = () => {

    this.getKlanten();
    // this.filterList("QSN");
    console.log("props", this.props);
  };
  // componentDidUpdate = (prevstate,prevProps)=>{

  //   if(prevProps.newKlant===this.state.newKlant)
  //   {
  //     this.handleThemeChange();
  //   }

  // }

  // changeSomething = (e, data) => {
  //   console.log(data.options);

  //   var klant;
  //   data.options.forEach((element) => {
  //     if (element.value === data.value) {
  //       klant = element;
  //     }
  //   });
  //   this.updateJSON();
  //   this.setState({ selectedKlant: klant, addKlant: false, newKlant: klant });
  // };

  _getSelectionDetailsNameItem = () => {
    const selectionCount = this.editButtonChange.getSelectedCount();

    switch (selectionCount) {
      case 0:
        return "No items selected";
      case 1:
        // this.handleThemeChange();
        return this.editButtonChange.getSelection()[0];
      default:
        return `${selectionCount} items selected`;
    }
  };

  changeSomething = () => {
    var klant = this._getSelectionDetailsNameItem();
    // this.updateJSON();
    console.log("Klant", klant);

    this.setState({ selectedKlant: klant, addKlant: false, newKlant: klant });
  };

  showPanel = () => {
    this.setState({ openPanel: true });
    this.changeSomething();
  };

  addKlantScherm = () => {
    const { addKlant } = this.state;
    this.setState({
      addKlant: !addKlant,
      selectedKlant: undefined,
      newKlant: {
        uniqueID: "",
        text: "",
        enabled: false,
        primarycolor: "",
        textcolor: "",
        backgroundcolor: "",
        themeps: "",
      },
    });
  };

  render = () => {
    this._columns = [
      {
        key: "text",
        name: "Organisatie",
        fieldName: "text",
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
      },

      {
        key: "primarycolor",
        name: "Primaire kleur",
        fieldName: "primarycolor",
        minWidth: 100,
        maxWidth: 100,
        isResizable: true,
      },
      {
        key: "backgroundcolor",
        name: "Achtergrond kleur",
        fieldName: "backgroundcolor",
        minWidth: 100,
        maxWidth: 100,
        isResizable: true,
      },
      {
        key: "textcolor",
        name: "Tekst kleur",
        fieldName: "textcolor",
        minWidth: 100,
        maxWidth: 100,
        isResizable: true,
      },

      {
        key: "enabled",
        name: "Enabled",
        fieldName: "enabled",
        minWidth: 100,
        maxWidth: 100,
        isResizable: true,
      },
      {
        key: "uniqueID",
        name: "GUID",
        fieldName: "uniqueID",
        minWidth: 50,
        maxWidth: 50,
        isResizable: true,
      },
    ];
    const {
      loading,
      klanten,
      selectedKlant,
      addKlant,
      newKlant,
      editButton,
      openPanel,
    } = this.state;

    return (
      <>
        <h4 class="section-divider sectionHeadings">
          <Icon style={{ verticalAlign: "bottom" }} iconName="Info"></Icon>{" "}
          Klantgegevens
        </h4>
        {editButton && <CommandBarControl EditItem={this.showPanel} />}

        {selectedKlant ? (
          <Panel
            isOpen={openPanel}
            onDismiss={this.cancel}
            headerText={selectedKlant.text + " bewerken"}
            closeButtonAriaLabel="Close"
            aria-multiselectable={false}
            onRenderFooterContent={this.closePanel}
            isFooterAtBottom={true}
          >
            <Form>
              <TextField
                label="Uniek ID"
                placeholder=""
                readOnly
                value={newKlant.uniqueID}
              />
              <TextField
                label="Klantnaam"
                name="text"
                readOnly
                required
                placeholder={newKlant.text}
                defaultValue={newKlant.text}
                validateOnFocusOut
                errorMessage={this.state.errorMessage}
              />
              {/* <TextField
                label="Klant omschrijving"
                name="omschrijving"
                placeholder={newKlant.omschrijving}
                onChange={this.editField}
              /> */}
              <Form.Field>
                <p className="ms-Label">Primaire kleur</p>
                <ChromePicker
                  disableAlpha={true}
                  onChangeComplete={this.handlePrimaryColor}
                  color={newKlant.primarycolor}
                />
              </Form.Field>
              <Form.Field>
                <p className="ms-Label">Achtergrond kleur</p>
                <ChromePicker
                  disableAlpha={true}
                  onChangeComplete={this.handleBackgroundColor}
                  color={newKlant.backgroundcolor}
                />
              </Form.Field>
              <Form.Field>
                <p className="ms-Label">Tekst kleur</p>

                <ChromePicker
                  disableAlpha={true}
                  onChangeComplete={this.handletextColor}
                  color={newKlant.textcolor}
                />
              </Form.Field>
             
              <Form.Field>
                {this.props.superAdmin && (
                  <Checkbox
                    label="Enabled"
                    name="enabled"
                    onChange={(e) => this.handleCheckboxChange(e)}
                    defaultChecked={newKlant.enabled}
                  />
                )}
                {this.props.superAdmin && (
                <div className="normIndex">
                  <NormSelector
                    klantNaam={selectedKlant.text}
                    klantId={selectedKlant.key}
                  />
                </div>
                )}
                {this.state.errorMessage && (
                  <MessageBar
                    messageBarType={MessageBarType.error}
                    isMultiline={true}
                    dismissButtonAriaLabel="Close"
                  >
                    De vereiste velden (*) zijn benodigd voordat u deze gegevens
                    kunt versturen
                  </MessageBar>
                )}
              </Form.Field>
            </Form>
          </Panel>
        ) : null}
        <div className="detailList">
          <DetailsList
            selectionMode="single"
            items={klanten}
            columns={this._columns}
            setKey="set"
            layoutMode={DetailsListLayoutMode.justified}
            onItemInvoked={this._onItemInvoked}
            ariaLabelForSelectionColumn="Toggle selection"
            ariaLabelForSelectAllCheckbox="Toggle selection for all items"
            checkButtonAriaLabel="Row checkbox"
            selection={this.editButtonChange}
            compact={false}
            selectionPreservedOnEmptyClick={true}
          />
        </div>
        {/* <Segment loading={loading} placeholder>
          <Grid columns={2} stackable textAlign="center">
            <Divider vertical>Of</Divider>

            <Grid.Row verticalAlign="middle">
              <Grid.Column>
                <Header icon>
                  <Icon name="search" />
                  Selecteer klant
                </Header>

                <Dropdown
                  button
                  className="icon"
                  floating
                  labeled
                  icon="address card outline"
                  options={klanten}
                  search
                  text="Selecteer klant"
                  onChange={this.changeSomething}
                />
              </Grid.Column>

              <Grid.Column>
                <Header icon>
                  <Icon name="address card outline" />
                  Klant toevoegen
                </Header>
                <Button
                  icon
                  labelPosition="left"
                  positive
                  onClick={this.addKlantScherm}
                >
                  <Icon name="add" />
                  Klant toevoegen
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
        {selectedKlant ? (
          <Segment basic>
            <Header as="h1">{selectedKlant.text} bewerken</Header>
            <NormSelector klantId={selectedKlant.key} />

            <Form>
              <TextField
                label="Uniek ID"
                placeholder=""
                readOnly
                value={newKlant.uniqueID}
              />
              <TextField
                label="Klantnaam"
                name="text"
                placeholder={newKlant.text}
                onChange={this.editField}
              />
              <TextField
                label="Klant omschrijving"
                name="omschrijving"
                placeholder={newKlant.omschrijving}
                onChange={this.editField}
              />
              <Form.Field>
                <p className="ms-Label">Primaire kleur</p>
                <ChromePicker
                  disableAlpha={true}
                  onChangeComplete={this.handlePrimaryColor}
                  color={newKlant.primarycolor}
                />
              </Form.Field>
              <Form.Field>
                <p className="ms-Label">Achtergrond kleur</p>
                <ChromePicker
                  disableAlpha={true}
                  onChangeComplete={this.handleBackgroundColor}
                  color={newKlant.backgroundcolor}
                />
              </Form.Field>
              <Form.Field>
                <p className="ms-Label">Tekst kleur</p>

                <ChromePicker
                  disableAlpha={true}
                  onChangeComplete={this.handletextColor}
                  color={newKlant.textcolor}
                />
              </Form.Field>
              <Form.Field>
                <Checkbox
                  label="Enabled"
                  name="enabled"
                  onChange={this.editField}
                  checked={newKlant.enabled}
                />
              </Form.Field>
              <div className="ms-Grid">
                <div className="ms-Grid-row">
                  <div className="ms-Grid-col-2">
                    <PrimaryButton
                      className="submitButton"
                      onClick={this.updateKlant}
                      text="Opslaan"
                    />
                  </div>
                  <div className="ms-Grid-col-2">
                    <DefaultButton
                      className="submitButton"
                      onClick={this.cancel}
                      text="Annuleren"
                    />
                  </div>
                </div>
              </div>
            </Form>
          </Segment>
        ) : addKlant ? (
          <Segment basic>
            <Header as="h1">Klant toevoegen</Header>
            <Form>
              <Form.Input
                fluid
                label="Klantnaam"
                placeholder="Klantnaam"
                name="text"
                onChange={this.editField}
                value={newKlant["text"]}
              />
              <Form.Input
                fluid
                label="Klant omschrijving"
                name="omschrijving"
                placeholder="Omschrijving van klant"
                onChange={this.editField}
                value={newKlant["omschrijving"]}
              />
              <Form.Field>
                <label>Primaire kleur</label>
                <ChromePicker
                  disableAlpha={true}
                  onChangeComplete={this.handlePrimaryColor}
                  color={newKlant.primarycolor}
                />
              </Form.Field>
              <Form.Field>
                <label>Achtergrond kleur</label>
                <ChromePicker
                  disableAlpha={true}
                  onChangeComplete={this.handleBackgroundColor}
                  color={newKlant.backgroundcolor}
                />
              </Form.Field>
              <Form.Field>
                <label>Tekst kleur</label>
                <ChromePicker
                  disableAlpha={true}
                  onChangeComplete={this.handletextColor}
                  color={newKlant.textcolor}
                />
              </Form.Field>
              <Form.Field>
                <Checkbox
                  label="Enabled"
                  onChange={this.editField}
                  name="enabled"
                  checked={newKlant["enabled"]}
                />
              </Form.Field>
              <Button onClick={this.AddKlant} positive>
                Opslaan
              </Button>
              <Button onClick={this.cancel} negative>
                Annuleren
              </Button>
            </Form>
          </Segment>
        ) : (
          <div></div>
        )} */}
      </>
    );
  };
}
