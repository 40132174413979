import React, { Component } from "react";
import { TextField, Dropdown, Checkbox } from "office-ui-fabric-react/lib/";
export class EditAdmin extends Component {
  render() {
    return (
      <div>
        <TextField name="naam" placeholder={this.props.Naam} label="Naam"   onChange={this.props.EditItems} />
        <TextField name="email" placeholder={this.props.Email} label="Email" onChange={this.props.EditItems}/>
        <Dropdown
          name="organisatie"
          options={this.props.OrganisatieSelectie}
          onChange={this.props.ToggleDropdown}
          defaultSelectedKeys={this.props.Organisatie}
          placeholder={this.props.Organisatie}
          label="Organisatie"
        />
        <Checkbox className="checkboxStyle" label="Super Admin?"
        defaultChecked={this.props.SuperAdmin}
          onChange={(e) => this.props.ToggleOnchange(e)}/>
         
      </div>
    );
  }
}
