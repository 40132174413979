// Data Definition of a TilePage. 
// must match the columns returned from the Rest API.
export class TilePage {

    id;
    klantid;
    klantnaam;
    paginatitle;
    pageurl;
    template;
    procesdoel;
    procesbewaking;
    procesbescrijving;
    processuitleg;
    verantwoordelijken;
    eigenaar;
    filename;    

    constructor()
    {
        this.id = null;
        this.klantid = "";
        this.klantnaam = "";
        this.paginatitle = "";
        this.pageurl = "";
        this.template = "";
        this.procesdoel = "";
        this.procesbewaking = "";
        this.procesbescrijving = "";
        this.processuitleg = "";
        this.eigenaar = "";
        this.verantwoordelijken = "";
        this.filename = "";        
    }
}