import React, { Component } from "react";
import { OpenListButton } from "../Controls/OpenListButton/openListButton";
import { softwareInfoDoc } from "../files/SoftwareInfo.docx";
import { PrimaryButton } from "office-ui-fabric-react";

export class SoftwareInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listURL: "",
    };
  }

  render() {
    return (
      <div className="gridWrapper">
        <h1>Software info</h1>
        <h3>Information regarding Free and Open Source Software</h3>
        <p>
          Note: The following form is only for customers who have questions
          regarding Free and Open Source Software.
        </p>
        <p>
          QSN Quality Services Network B.V. (“QSN”) utilises the so-called “Free
          and Open Source Software” in its products. These are software
          components which have been publicly released by programmers under
          specific conditions and which are used and distributed by QSN as part
          of its products. Some of these conditions require, among other things,
          that we make available to you as user of our products, license texts,
          copyright notices and, if applicable, the source codes of the used
          Free and Open Source Software components and derivative works, or
          provide you with other information or support.
        </p>
        <p>
          If the license conditions require to provide the source code, you will
          find a corresponding offer in the license notes of the respective QSN
          software, which are included in the software itself or were delivered
          with it. You can request the respective source code from us via this
          e-mail address (software@rms365.nl of software@qsn.nl even overleg
          graag).
        </p>
        <p>
          In order to provide you with the appropriate source code or to be able
          to answer your requests, please include in your e-mailrequest provide
          the details of the QSN product to which you refer, the exact
          identification of the specific Free and Open Source Software component
          whose source code you would like to receive and the corresponding
          offer to obtain the source code or, if applicable, the other license
          conditions to which you refer.
        </p>
        <p>
          We trust that you will understand that we have to charge you for a
          compensation of our reasonable expenses required for the production
          and dispatch of a physical data medium for the source code. The same
          applies to expenses due to other support. We will clarify the details
          with you individually for your specific case.
        </p>
        <p>
          If you have any further comments or questions about the use of Free
          and Open Source Software by QSN, we welcome you to get in contact with
          us.
        </p>
        <p>
          ====================================================================
        </p>
        <p>General Open Source Provisions and written offer</p>
        <p>
          ====================================================================
        </p>
        <p>
          The license terms listed below form the contractual basis for your use
          of the corresponding software components and supersede any other
          contractual agreements concerning this&nbsp;QSN&nbsp;product, solution
          or service (product).
        </p>
        <p>&nbsp;</p>
        <p>
          Modifications of the proprietary software within your product for your
          own use and reverse engineering for debugging such modifications are
          herewith permitted to the extent such software components are linked
          to program libraries under the GNU Lesser General Public License
          (LGPL). However, you must not disclose the knowledge acquired during
          reverse engineering or debugging, nor the information obtained from
          the reengineering, nor the modified software itself to any third
          party. Please note that any modification is at your own risk and will
          void any lead to a disclaimer of warranty for defects resulting from
          the modification. In addition, the product may not be usable for its
          intended purpose. This provision takes precedence over any other
          contractual provision between you and&nbsp;QSN.
        </p>
        <p>&nbsp;</p>
        <p>
          For further information regarding the below listed software components
          listed below and the corresponding license terms and &ldquo;where
          required by license&ldquo; source codes, please
          contact&nbsp;QSN&nbsp;at www.rms365.nl/softwareinfo.
        </p>
        <p>&nbsp;</p>
        <p>
          Some licenses, however, require the provision of physical copies of
          source and/or object code. In this case, you and any third party may
          obtain a copy of the source and/or object codes according to the
          requirements of the applicable Open Source License by contacting us at
          www.rms365.nl/softwareinfo. Furthermore, please contact us at the
          foregoing URL in case you need any other assistance regarding the
          exercise of rights guaranteed granted by an Open Source License. A
          nominal fee (i.e., the cost of physically performing the distribution)
          might be charged for these services.
        </p>
        <p>&nbsp;</p>
        <p>
          On request of the authors and right holders of the Open Source
          Software used in this product we inform you about the following:
        </p>
        <p>&nbsp;</p>
        <p>
          &ldquo;THE OPEN SOURCE SOFTWARE IN THIS PRODUCT IS DISTRIBUTED IN THE
          HOPE THAT IT WILL BE USEFUL, BUT WITHOUT ANY WARRANTY, WITHOUT EVEN
          THE IMPLIED WARRANTY OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR
          PURPOSE. See the applicable licenses for more details.&rdquo;
        </p>
        <p>&nbsp;</p>
        <p>
          Note: your copy of this product may not contain code covered by one or
          more of the licenses listed here, depending on the exact product and
          version you choose.
        </p>
        <p>
          ====================================================================
        </p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>
          ====================================================================
        </p>
        <p>&nbsp;</p>
        <p>Project:&nbsp;RMS365</p>
        <p>&nbsp;</p>
        <p>
          The following list contains attribution notices, among them, where
          applicable, attribution notices of various relevant modules.&nbsp;
        </p>
        <p>&nbsp;</p>
        <p>
          ====================================================================
        </p>
        <div>
          {/* <PrimaryButton
            text="Download file"
            iconProps={{ iconName: "View", className: "ms-AddIcon" }}
            className="submitButton"
            onClick={this.downloadEmployeeData}
            allowDisabledFocus
          />
          <PrimaryButton variant="contained" component="label">
            <a href={softwareInfoDoc} target="_blank" download>
              Download csv
            </a>
          </PrimaryButton> */}
        </div>
      </div>
    );
  }
}
