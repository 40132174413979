import { ComboBox } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { Button, Icon, Segment } from "semantic-ui-react";
import {
  Modal,
  Dialog,
  IconButton,
  PrimaryButton,
  DefaultButton,
} from "@fluentui/react";

import CheckBoxes from "./checkBoxes";
import "./style.css"

const NormSelector = (props) => {
  const [open, setOpen] = useState(false);
  const [normen, setNormen] = useState({ normen: [], loading: false });
  const [checked, setChecked] = useState([]);
  const [defaultChecked, setDefaultChecked] = useState([]);

  useEffect(() => {
    setNormen({ loading: true });
    fetch("/api/norm/" + props.klantId, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((response) => {
        let toBeChecked = [];
        response.forEach((element) => {
          toBeChecked.push({ checked: true, value: element.value });
        });

        setDefaultChecked(response);
        setChecked(toBeChecked);
      });

    fetch("/api/norm", {
      method: "GET",
    })
      .then((res) => res.json())
      .then((response) => {
        console.log("response", response);
        setNormen({ data: response, loading: false });
      });
  }, [props]);

  function saveNormen() {
    console.log("to be communicated", checked);
    fetch("/api/norm", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ klant: props.klantId }),
    })
      .then((res) => res.json())
      .then((response) => {
        fetch("/api/norm", {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ klant: props.klantId, checked }),
        })
          .then((res) => res.json())
          .then((response) => {
            setOpen(false);
          });
      });
  }

  function removeElementOutArray(arr) {
    var what,
      a = arguments,
      L = a.length,
      ax;
    while (L > 1 && arr.length) {
      what = a[--L];
      while ((ax = arr.indexOf(what)) !== -1) {
        arr.splice(ax, 1);
      }
    }
    return arr;
  }

  function clickEvent(e, data) {
    let newChecked = [];

    if (!data.checked) {
      newChecked = checked;
      checked.forEach((element) => {
        console.log("checking on", element.value, data.value);
        if (element.value === data.value) {
          newChecked = removeElementOutArray(checked, element);
          console.log("newchecked", newChecked);
        }
      });
    }
    if (newChecked.length > 0) {
      console.log("newchecked has more then 0");
      setChecked(newChecked);
    } else {
      console.log("adding data to checked");
      setChecked((oldChecked) => [...oldChecked, data]);
    }
  }

  const { data, loading } = normen;

  return (
    <React.Fragment>
      <PrimaryButton className="submitButton" onClick={() => setOpen(true)}>Norm selectie</PrimaryButton>
      <Modal isOpen={open} onDismiss={() => setOpen(true)} open={open}>
        <div>
          <p className="normTitle">{props.klantNaam} normen</p>

          <div>
            <PrimaryButton className="submitButton" onClick={() => saveNormen()} positive>
              Opslaan
            </PrimaryButton>
            <DefaultButton className="cancelButton" onClick={() => setOpen(false)} negative>
              Annuleren
            </DefaultButton>
          </div>
        </div>
        <div>
          <Segment loading={loading}>
            <CheckBoxes
              defaultChecked={defaultChecked}
              data={data}
              clickEvent={(e, data) => clickEvent(e, data)}
            />
          </Segment>
        </div>
        {/* <Modal.Header>Normen beheer</Modal.Header>
            <Modal.Content>
            <Modal.Description>

                <Segment loading={loading}>
                    <CheckBoxes defaultChecked={defaultChecked} data={data} clickEvent={(e, data) => clickEvent(e, data)}/>
                </Segment>

            </Modal.Description></Modal.Content>
            <Modal.Actions>
                <Button icon labelPosition='right' onClick={() => setOpen(false)} negative>
                    Annuleren 
                    <Icon name='cancel' />
                </Button>
                <Button icon labelPosition='right' onClick={() => saveNormen()} positive>
                    Opslaan 
                    <Icon name='save' />
                </Button>
            </Modal.Actions> */}
      </Modal>
    </React.Fragment>
  );
};

export default NormSelector;
