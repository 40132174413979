import React, { Component } from "react";
import {
  DefaultButton,
  PrimaryButton,
} from "office-ui-fabric-react/lib/Button";
import { Panel } from "office-ui-fabric-react/lib/Panel";
import { Dropdown, Label, Stack } from "@fluentui/react"
import "./style.css";

export class PersonaPanel extends Component {
  closePanel = () => {
    return (
      <div>
        <PrimaryButton
          className="submitButton"
          onClick={this.props.signOut.bind(this)}
        >
          Uitloggen
        </PrimaryButton>
      </div>
    );
  };

  render = () => {
    return (
      <div className="customPanel">
        <Panel
          isOpen={this.props.panelOpen}
          onDismiss={this.props.onDismiss.bind(this)}
          headerText={this.props.panelHeader}
          closeButtonAriaLabel="Close"
          aria-multiselectable={false}
          onRenderFooterContent={this.closePanel}
          isFooterAtBottom={true}
        >
          <Stack stackTokens={{childrenGap: 10}}>
            <p>{this.props.userEmail}</p>
            <Dropdown
              onChange={this.props.onChangeOrganisation}
              options={this.props.selectableOrganisations}
              name="organisatie"
              label="Organisatie"
              placeholder={"Selecteer een organisatie"}
              selectedKey={this.props.selectedOrganisation}

            />
            <div style={{position: "absolute", bottom: "15px", right: "20px", textAlign: "right"}}>
              <Label>Version</Label>
              <p>{this.props.versionNumber}</p>
            </div>
          </Stack>        
          
        </Panel>
      </div>
    );
  };
}
