import React, { Component } from 'react';
import {
    Dialog,
    DialogType,
    DialogFooter,
  } from "office-ui-fabric-react/lib/Dialog";
  import {
    PrimaryButton,
    DefaultButton,
  } from "office-ui-fabric-react/lib/Button";

export class CustomDialog extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        const dialogContentProps = {
            type: DialogType.largeHeader,
            title: `${this.props.dialogTitle}`,
            subText:
            `${this.props.dialogBody}`,
          };
        return (
            <Dialog
            hidden={!this.props.dialogState}
            onDismiss={this.props.onDismiss}
            dialogContentProps={dialogContentProps}
          >
            <DialogFooter>
              <PrimaryButton className="submitButton" onClick={this.props.primaryButtonClick} text="OK" />
            </DialogFooter>
          </Dialog>
        );
    }
}

