import React, { Component } from "react";
import {
  TextField,
  Dropdown,
  PrimaryButton,
  DefaultButton,
  Label,
} from "office-ui-fabric-react/lib/";
import { MessageBar, MessageBarType } from "office-ui-fabric-react";
import { CustomDialog } from "../../Controls/Dialog/dialog";
import RichTextEditor from "react-rte";

import "./style.css";
import { RichTextEditorToolbarConfig } from "../../Helpers/RichTextEditorToolbarConfig";
export class AddProcessen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newPagina: {
        klantid: "",
        klantnaam: "",
        paginaurl: this.props.siteURL,
        template: "",
        paginatitle: "",
        procesdoel: "",
        procesbescrijving: "",
        procesbewaking: "",
        processuitleg: "",
        visiodiagram: null,
        blob: null,
        eigenaar: "",
        filename: "",
      },
      valueProcessBeschrijving: RichTextEditor.createEmptyValue(),
      valueDoelVanProces: RichTextEditor.createEmptyValue(),
      valueProcesBewaking: RichTextEditor.createEmptyValue(),
      valueUitlegvanProces: RichTextEditor.createEmptyValue(),
      clientSiteUrl: "",
      uploadSuccess: "",
      dialog: false,
      messageFileExtension: "",
      showFileMessage: "",
      fileSubmissionMessage: "",
      validation: false,
      errorEncounered: false,
      validationMessageEmail: "",
      processenSelectie: [],
    };
  }
  handleTemplate = (e, item) => {
    console.log(item.text);
    this.setState((prevState) => ({
      newPagina: {
        ...prevState.newPagina,
        template: item.text,
      },
    }));
  };

  handleProcesstitle = (e, item) => {
    console.log(item.text);
    this.setState((prevState) => ({
      newPagina: {
        ...prevState.newPagina,
        paginatitle: item.id,
      },
    }));
  };

  handleOrgSecelectie = (e, item) => {
    this.getKlientId(item.text);
    this.setState((prevState) => ({
      newPagina: {
        ...prevState.newPagina,
        klantnaam: item.text,
        paginaurl: item.site,
      },
    }));
  };

  getProcessen = async (klantId) => {
    await fetch("/api/proces/pagetitles/" + klantId)
      .then((response) => response.json())
      .then((data) => {
        console.log("Processen child", data);
        const processen = data;
        this.setState({ processenSelectie: processen });
      })
      .catch((error) => {
        console.log("klantID", error);
      });
  };

  getKlientId = async (organisatie) => {
    await fetch("/api/klant/organisatie/" + organisatie)
      .then((response) => response.json())
      .then((data) => {
        console.log("klantID", data);
        const klantId = data;
        this.setState((prevState) => ({
          newPagina: {
            ...prevState.newPagina,
            klantid: klantId,
          },
        }));
        this.getProcessen(klantId);
        // console.log("KlantID: ", data);
      })
      .catch((error) => {
        console.log("klantID", error);
      });
  };

  handleFields = (e) => {
    let { newPagina } = this.state;
    newPagina[e.target.name] = e.target.value;
    this.setState({ newPagina: newPagina });
  };

  handleEmail = (e) => {
    let { newPagina } = this.state;
    newPagina[e.target.name] = e.target.value;
    this.setState({ validationMessageEmail: "" });
    const emailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line
    if (!emailRegex.test(e.target.value)) {
      const errMsg = "Het email adres voldoet niet aan de eisen";
      this.setState({ validationMessageEmail: errMsg });
    } else {
      this.setState({ newPagina: newPagina, validationMessageEmail: "" });
    }
  };

  handleClearMessage = () => {
    this.setState({ messageFileExtension: "", showFileMessage: false });
  };

  handlefileUpload = (e) => {
    const file = e.target.files;
    const allowedFileTypes = [
      "image/png",
      "image/jpeg",
      "application/vnd.visio",
      "application/vnd.ms-visio.drawing",
      "application/vnd.ms-visio.viewer",
    ];
    const allowedExtensions = [
        "vsd",
        "vsdx",
    ];
    for (var x = 0; x < file.length; x++) {
      var noAllowedFileTypeUsed = allowedFileTypes.every((type) => file[x].type !== type);
      var noAllowedExtensionUsed = allowedExtensions.every((extension) => file[x].name.split('.').pop() !== extension);
      if (noAllowedFileTypeUsed && noAllowedExtensionUsed) {
        // create error message and assign to container
        this.setState({
          messageFileExtension: file[x].name + " is not a supported format\n",
          showFileMessage: true,
          errorEncounered: true,
          visiodiagram: "",
        });
      } else {
        console.log("File :", file[0]);
        console.log("File Name : ", file[0].name);
        this.handleClearMessage();
        // const formData = new FormData();
        // formData.append("file", file[0]);
        // console.log("File Name: ", formData);
        this.setState((prevState) => ({
          newPagina: {
            ...prevState.newPagina,
            visiodiagram: file[0],
            filename: file[0].name,
          },
        }));
      }
    }
  };
  handleClearForm = () => {
    this.setState(
      {
        valueProcessBeschrijving: RichTextEditor.createEmptyValue(),
        valueDoelVanProces: RichTextEditor.createEmptyValue(),
        valueProcesBewaking: RichTextEditor.createEmptyValue(),
        valueUitlegvanProces: RichTextEditor.createEmptyValue(),
        newPagina: {
          template: "",
          klantnaam: "",
          klantid: "",
          paginaurl: "",
          paginatitle: "",
          processDoel: "",
          processBescrijving: "",
          processBewaking: "",
          uitlegvanProcess: "",
        },
      },
      () => {
        console.log(this.state);
      }
    );
  };
  handleOnDismiss = () => {
    this.setState({ dialog: false });
    this.refreshPage();
  };
  handleOnDismissValidation = () => {
    this.setState({ validation: false });
  };

  uploadDoc = async () => {
    const file = this.state.newPagina.visiodiagram;
    const formData = new FormData();

    formData.append("image", file);

    const options = {
      method: "POST",
      body: formData,
    };

    fetch("/api/files", options)
      .then((response) => response.json())
      .then((data) => {
        this.setState((prevState) => ({
          newPagina: {
            ...prevState.newPagina,
            filename: data.filename,
          },
        }));
      });
  };

  handleSharePointFileUpload = () => {
    const { newPagina } = this.state;

    if (newPagina.visiodiagram) {
      this.uploadDoc();
      this.setState({
        uploadSuccess: true,
        fileSubmissionMessage: "Het document is succesvol geüpload",
      });
    }
  };

  handleAddPagina = async () => {
    const { newPagina } = this.state;

    switch (newPagina.template) {
      case "Template 1 - Klantreis en flow":
        if (
          newPagina.paginatitle &&
          newPagina.processuitleg &&
          newPagina.visiodiagram &&
          newPagina.eigenaar &&
          newPagina.klantnaam
        ) {
          fetch("/api/pagina", {
            method: "POST", // or 'PUT'
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(newPagina),
          })
            .then((response) => response.json())
            .then((data) => {
              console.log("Success:", data);

              this.setState({ dialog: true });
              this.handleClearForm();
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        } else {
          this.setState({ validation: true });
        }
        break;
      case "Template 2 - Flow":
        if (
          newPagina.paginatitle &&
          newPagina.processuitleg &&
          newPagina.visiodiagram &&
          newPagina.eigenaar &&
          newPagina.klantnaam
        ) {
          fetch("/api/pagina", {
            method: "POST", // or 'PUT'
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(newPagina),
          })
            .then((response) => response.json())
            .then((data) => {
              console.log("Success:", data);

              this.setState({ dialog: true });
              this.handleClearForm();
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        } else {
          this.setState({ validation: true });
        }
        break;
      case "Template 3 - Klantreis en tekst":
        if (
          newPagina.paginatitle &&
          newPagina.procesbescrijving &&
          newPagina.eigenaar &&
          newPagina.klantnaam
        ) {
          await fetch("/api/pagina", {
            method: "POST", // or 'PUT'
            headers: {
              "Content-Type": "application/json",
              Accept: "multipart/form-data",
            },
            body: JSON.stringify(newPagina),
          })
            .then((response) => response.json())
            .then((data) => {
              console.log("Success:", data);

              this.setState({
                dialog: true,
                newPagina: {
                  template: "",
                  klantnaam: "",
                  klantid: "",
                  paginaurl: "",
                  paginatitle: "",
                  processDoel: "",
                  processBescrijving: "",
                  processBewaking: "",
                  uitlegvanProcess: "",
                },
              });
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        } else {
          this.setState({ validation: true });
        }
        break;
      case "Template 4 - Tekst":
        if (
          newPagina.paginatitle &&
          newPagina.procesbescrijving &&
          newPagina.eigenaar &&
          newPagina.klantnaam
        ) {
          await fetch("/api/pagina", {
            method: "POST", // or 'PUT'
            headers: {
              "Content-Type": "application/json",
              Accept: "multipart/form-data",
            },
            body: JSON.stringify(newPagina),
          })
            .then((response) => response.json())
            .then((data) => {
              console.log("Success:", data);

              this.setState({
                dialog: true,
                newPagina: {
                  template: "",
                  klantnaam: "",
                  klantid: "",
                  paginaurl: "",
                  paginatitle: "",
                  processDoel: "",
                  processBescrijving: "",
                  processBewaking: "",
                  uitlegvanProcess: "",
                },
              });
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        } else {
          this.setState({ validation: true });
        }
        break;
    }
  };

  refreshPage = () => {
    window.location.reload();
  };
  updateBescrijving = (value) => {
    this.setState((prevState) => ({
      newPagina: {
        ...prevState.newPagina,
        procesbescrijving: value,
      },
    }));
  };

  updateUitleg = (value) => {
    this.setState((prevState) => ({
      newPagina: {
        ...prevState.newPagina,
        processuitleg: value,
      },
    }));
  };

  updateProcesBewaking = (value) => {
    this.setState((prevState) => ({
      newPagina: {
        ...prevState.newPagina,
        procesbewaking: value,
      },
    }));
  };

  updateDoelVanProces = (value) => {
    this.setState((prevState) => ({
      newPagina: {
        ...prevState.newPagina,
        procesdoel: value,
      },
    }));
  };

  onChangeProcesBewaking = (value) => {
    console.log("Value :", value.toString("html"));

    this.setState({ valueProcesBewaking: value });

    if (this.onChange) {
      this.onChange(value.toString("html"));
    }
    this.updateProcesBewaking(value._cache.html);
    console.log("State:", value._cache.html);
  };

  onChangeRich = (value) => {
    console.log("Value :", value.toString("html"));

    this.setState({ valueProcessBeschrijving: value });

    if (this.onChange) {
      this.onChange(value.toString("html"));
    }
    this.updateBescrijving(value._cache.html);
    console.log("State:", value._cache.html);
  };

  onChangeUitlegProces = (value) => {
    console.log("Value :", value.toString("html"));
    this.setState({ valueUitlegvanProces: value });

    if (this.props.onChange) {
      this.props.onChange(value.toString("html"));
    }
    this.updateUitleg(value._cache.html);
    console.log("State:", value._cache.html);
  };

  onChangeProcesDoel = (value) => {
    console.log("Value :", value.toString("html"));
    this.setState({ valueDoelVanProces: value });

    if (this.props.onChange) {
      this.props.onChange(value.toString("html"));
    }
    this.updateDoelVanProces(value._cache.html);
    console.log("State:", value._cache.html);
  };

  componentDidMount = () => {
    
  };

  render() {
    const cat = [
      { key: "KRF", text: "Template 1 - Klantreis en flow" },
      { key: "GPT", text: "Template 2 - Flow" },
      { key: "KRT", text: "Template 3 - Klantreis en tekst" },
      { key: "PPT", text: "Template 4 - Tekst" },
    ];
    const { newPagina, showFileMessage, uploadSuccess, visiodiagram } =
      this.state;
    const toolbarConfig = RichTextEditorToolbarConfig.Instance.GetDefault();

    return (
      <React.Fragment>
        <div className="detailList">
          <div className="ms-Grid" dir="ltr">
            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-col12 ms-md12">
                <Dropdown
                  onChange={this.handleOrgSecelectie}
                  options={this.props.selectableOrganisations}
                  name="organisatie"
                  label="Organisatie"
                  placeholder={"Selecteer een organisatie"}
                  // value={newPagina.organisatie}
                  required
                />
              </div>
              <div className="ms-Grid-col ms-col12 ms-md6">
                <Dropdown
                  onChange={this.handleTemplate}
                  options={cat}
                  name="template"
                  label="Processen template"
                  placeholder="
                  Selecteer een 
                  template"
                  value={newPagina.template}
                  required
                />
              </div>
              {newPagina.template && (
                <div className="ms-Grid-col ms-col12 ms-md6">
                  {/* <TextField
                    onChange={this.handleFields}
                    required
                    label="Pagina titel"
                    name="paginatitle"
                    value={this.state.paginatitle}
                    //   errorMessage={this.state.validationMessage}
                  /> */}
                  <Dropdown
                    onChange={this.handleProcesstitle}
                    options={this.state.processenSelectie}
                    name="paginatitle"
                    label="Pagina titel"
                    placeholder="
                  Selecteer een 
                  pagina title"
                    value={newPagina.paginatitle}
                    required
                  />
                </div>
              )}
              {newPagina.template === "Template 4 - Tekst" && (
                <div>
                  <div className="ms-Grid-col ms-sm12 ms-md6">
                    <Label>Doel van het proces</Label>
                    <RichTextEditor
                      toolbarConfig={toolbarConfig}
                      name="procesdoel"
                      value={this.state.valueDoelVanProces}
                      onChange={this.onChangeProcesDoel}
                    />
                  </div>
                  <div className="ms-Grid-col ms-sm12 ms-md6">
                    <Label required>Proces beschrijving</Label>
                    <RichTextEditor
                      toolbarConfig={toolbarConfig}
                      name="procesbescrijving"
                      value={this.state.valueProcessBeschrijving}
                      onChange={this.onChangeRich}
                    />
                  </div>
                  <div className="ms-Grid-col ms-sm12 ms-md6">
                    <Label>Proces bewaking</Label>
                    <RichTextEditor
                      toolbarConfig={toolbarConfig}
                      name="procesbewaking"
                      value={this.state.valueProcesBewaking}
                      onChange={this.onChangeProcesBewaking}
                    />
                  </div>
                  <div className="ms-Grid-col ms-sm12 ms-md6">
                    <TextField
                      name="eigenaar"
                      onChange={this.handleEmail}
                      placeholder="Eigenaar email adres"
                      required
                      label="Proces eigenaar"
                      errorMessage={this.state.validationMessageEmail}
                    />
                  </div>
                </div>
              )}
              {newPagina.template === "Template 3 - Klantreis en tekst" && (
                <div>
                  <div className="ms-Grid-col ms-sm12 ms-md6">
                    <Label>Doel van het proces</Label>
                    <RichTextEditor
                      toolbarConfig={toolbarConfig}
                      name="procesdoel"
                      value={this.state.valueDoelVanProces}
                      onChange={this.onChangeProcesDoel}
                    />
                  </div>
                  <div className="ms-Grid-col ms-sm12 ms-md6">
                    <Label required>Proces beschrijving</Label>
                    <RichTextEditor
                      toolbarConfig={toolbarConfig}
                      name="procesbescrijving"
                      value={this.state.valueProcessBeschrijving}
                      onChange={this.onChangeRich}
                    />
                  </div>
                  <div className="ms-Grid-col ms-sm12 ms-md6">
                    <Label>Proces bewaking</Label>
                    <RichTextEditor
                      toolbarConfig={toolbarConfig}
                      name="procesbewaking"
                      value={this.state.valueProcesBewaking}
                      onChange={this.onChangeProcesBewaking}
                    />
                  </div>
                  <div className="ms-Grid-col ms-sm12 ms-md6">
                    <TextField
                      name="eigenaar"
                      onChange={this.handleEmail}
                      placeholder="Eigenaar email adres"
                      required
                      label="Proces eigenaar"
                      errorMessage={this.state.validationMessageEmail}
                    />
                  </div>
                
                </div>
              )}

              {newPagina.template === "Template 1 - Klantreis en flow" && (
                <React.Fragment>
                  <div className="ms-Grid-col ms-sm12 ms-md6">
                    <Label required>Uitleg van dit proces</Label>
                    <RichTextEditor
                      value={this.state.valueUitlegvanProces}
                      name="processuitleg"
                      toolbarConfig={toolbarConfig}
                      onChange={this.onChangeUitlegProces}
                    />
                  </div>
                  <div className="ms-Grid-col ms-sm12 ms-md6">
                    <TextField
                      name="eigenaar"
                      onChange={this.handleEmail}
                      required
                      placeholder="Eigenaar email adres"
                      label="Proces eigenaar"
                      errorMessage={this.state.validationMessageEmail}
                    />
                  </div>

                  <div className="ms-Grid-col ms-sm12 ms-md6">
                    <Label required>Visio diagram</Label>
                    <input
                      name="visiodiagram"
                      onChange={this.handlefileUpload}
                      type="file"
                    />
                    {this.state.newPagina.visiodiagram && (
                      <PrimaryButton
                        className="submitButton"
                        onClick={this.handleSharePointFileUpload}
                        text="Upload"
                      />
                    )}
                    {uploadSuccess && (
                      <MessageBar
                        messageBarType={MessageBarType.success}
                        isMultiline={false}
                      >
                        {this.state.fileSubmissionMessage}
                      </MessageBar>
                    )}
                    {showFileMessage && (
                      <MessageBar
                        messageBarType={MessageBarType.error}
                        isMultiline={false}
                      >
                        {this.state.messageFileExtension}
                      </MessageBar>
                    )}
                  </div>
                </React.Fragment>
              )}

              {newPagina.template === "Template 2 - Flow" && (
                <React.Fragment>
                  <div className="ms-Grid-col ms-sm12 ms-md6">
                    <Label required>Uitleg van dit proces</Label>
                    <RichTextEditor
                      value={this.state.valueUitlegvanProces}
                      name="processuitleg"
                      toolbarConfig={toolbarConfig}
                      onChange={this.onChangeUitlegProces}
                    />
                  </div>
                  <div className="ms-Grid-col ms-sm12 ms-md6">
                    <TextField
                      name="eigenaar"
                      onChange={this.handleEmail}
                      required
                      placeholder="Eigenaar email adres"
                      label="Proces eigenaar"
                      errorMessage={this.state.validationMessageEmail}
                    />
                  </div>

                  <div className="ms-Grid-col ms-sm12 ms-md6">
                    <Label required>Visio diagram</Label>
                    <input
                      name="visiodiagram"
                      onChange={this.handlefileUpload}
                      type="file"
                    />
                    {this.state.newPagina.visiodiagram && (
                      <PrimaryButton
                        className="submitButton"
                        onClick={this.handleSharePointFileUpload}
                        text="Upload"
                      />
                    )}
                    {uploadSuccess && (
                      <MessageBar
                        messageBarType={MessageBarType.success}
                        isMultiline={false}
                      >
                        {this.state.fileSubmissionMessage}
                      </MessageBar>
                    )}
                    {showFileMessage && (
                      <MessageBar
                        messageBarType={MessageBarType.error}
                        isMultiline={false}
                      >
                        {this.state.messageFileExtension}
                      </MessageBar>
                    )}
                  </div>
                </React.Fragment>
              )}

              <div className="ms-Grid-col ms-sm12 ms-md6"></div>
              <br></br>
              <div
                style={{ "padding-top": 10 }}
                className="ms-Grid-col ms-sm12 ms-md12"
              ></div>
              <div className="ms-Grid-row">
                <div className="ms-Grid-col">
                  <PrimaryButton
                    className="submitButton"
                    onClick={this.handleAddPagina}
                    text="Opslaan"
                  />
                </div>
                <div className="ms-Grid-col">
                  <DefaultButton
                    className="annuleerButton"
                    onClick={this.handleClearForm}
                    text="Annuleer"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <CustomDialog
          dialogTitle="Success!"
          dialogBody="Dit process pagina was succesvol aangemaakt"
          dialogState={this.state.dialog}
          onDismiss={this.handleOnDismiss}
          primaryButtonClick={this.handleOnDismiss}
        />
        <CustomDialog
          dialogTitle="Fout!"
          dialogBody="De vereiste velden (*) zijn benodigd voordat u deze gegevens kunt versturen"
          dialogState={this.state.validation}
          onDismiss={this.handleOnDismissValidation}
          primaryButtonClick={this.handleOnDismissValidation}
        />
      </React.Fragment>
    );
  }
}
