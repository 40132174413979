export class PageService {

    static instance;

    static get Instance() {

        if(this.instance == null) {
            this.instance = new PageService();
        }

        return this.instance;
    }

    GetAll(customerFilter) {
        var url = "/api/pagina/all";
        if (customerFilter && customerFilter > 0)
        {
            url = "/api/pagina/" + customerFilter + "/all"
        }
        return new Promise((resolve, reject) => {
            fetch(url)
            .then((response) => response.json())
            .then((data) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
        });
    }

    GetPageTitlesByCustomer(customerFilter)
    {
        var url = "/api/proces/pagetitles/" + customerFilter;
        return new Promise((resolve, reject) => {
            fetch(url)
            .then((response) => response.json())
            .then((data) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
        });
    }

    
    GetSupportedPageTemplateOptions() {
        var selectableDropdownOptions = [
            { key: "KRF", text: "Template 1 - Klantreis en flow" },
            { key: "GPT", text: "Template 2 - Flow" },
            { key: "KRT", text: "Template 3 - Klantreis en tekst" },
            { key: "PPT", text: "Template 4 - Tekst" },
            { key: "TP5", text: "Template 5 - Flow en toelichting" },
            { key: "TP6", text: "Template 6 - Klantries, flow en toelichting"}
          ];

        return selectableDropdownOptions;
    }

    Save(pageItem)
    {
        // POST for add, PUT for update
        var restMethod = pageItem.id == null ? "POST" : "PUT";
        //var restMethod = "POST";
        
        return new Promise((resolve, reject) => {
            fetch("/api/pagina", {
                method: restMethod,
                headers: {
                "Content-Type": "application/json",
                },
                body: JSON.stringify(pageItem),
            })
            .then((response) => response.json())
            .then((data) => {
                console.log("Success:", data);
                resolve(true);            
            })
            .catch((error) => {
                console.error("Error:", error);
                reject(error);
            });
        });
    }

    SaveFile(file)
    {
        const formData = new FormData();
        formData.append("image", file);
    
        const options = {
            method: "POST",
            body: formData,
        };
    
        return new Promise((resolve, reject) => {
            fetch("/api/files", options)
            .then((response) => response.json())
            .then((data) => {
                resolve(data);
            })
            .catch((error) => {
                console.error("Error:", error);
                reject(error);
            });
        });
    }
}