import React, { Component } from "react";
import "./styles.css";
import { PrimaryButton, DefaultButton, Dropdown } from "office-ui-fabric-react";
import {
  DetailsList,
  DetailsListLayoutMode,
  SelectionMode
} from "office-ui-fabric-react/lib/DetailsList";
import { Icon } from "@fluentui/react";
import { EditPublicationPanel } from "../Controls/Publication/EditPublicationPanel";
import { CustomerService } from "../Services/CustomerService";
import { PublicationService } from "../Services/PublicationService";
export class Publication extends Component  {
  constructor(props) {
    super(props);
    this.state = {
      newPublicationItemCheck: false,
      publicationItems: [],
      panelOpen: false
    };

    this._changeNewPublicationItem = () => {
      this.setState({ 
        newPublicationItemCheck: true,
        panelOpen:true });
    };

    this.getPublicationItems = () => {
      PublicationService.Instance.GetAll().then((publicationItems) =>
      {
        this.setState({ publicationItems:publicationItems });
      });
    };

    this.closeEditAddPanel = () => {
      this.setState({ panelOpen: false });
    };

    this.onEditAddPanelSave = (item) => {
      PublicationService.Instance.Save(item).then((isSucces) =>
      {
        this.setState({ panelOpen: false });
        this.getPublicationItems();
      });
    } 
  }

  componentDidMount () {
    this.getPublicationItems();
  }

  render() {
    this._columns = [
      {
        key: "organisation",
        name: "Organisatie",
        fieldName: "organisation",
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
      },
      {
        key: "updateDate",
        name: "Update",
        fieldName: "updateDate",
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        onRender: (item) => {
          return <span>{new Date(item.updateDate).toLocaleString()}</span>
        }
      },
      {
        key: "site",
        name: "Portaal",
        fieldName: "site",
        minWidth: 50,
        maxWidth: 75,
        isResizable: true,
        onRender: (item) => {
            return <span>{item.site ? "Ja": "Nee"}</span>
          }
      },
      {
        key: "norms",
        name: "Normen",
        fieldName: "norms",
        minWidth: 50,
        maxWidth: 75,
        isResizable: true,
        onRender: (item) => {
            return <span>{item.norms ? "Ja": "Nee"}</span>
          }
      },
      {
        key: "users",
        name: "Gebruikers",
        fieldName: "users",
        minWidth: 50,
        maxWidth: 75,
        isResizable: true,
        onRender: (item) => {
            return <span>{item.users ? "Ja": "Nee"}</span>
          }
      },
      {
        key: "pages",
        name: "Pagina's",
        fieldName: "pages",
        minWidth: 50,
        maxWidth: 75,
        isResizable: true,
        onRender: (item) => {
            return <span>{item.pages ? "Ja": "Nee"}</span>
          }
      },
      {
        key: "news",
        name: "Nieuws",
        fieldName: "news",
        minWidth: 50,
        maxWidth: 75,
        isResizable: true,
        onRender: (item) => {
            return <span>{item.news ? "Ja": "Nee"}</span>
          }
      },
      {
        key: "status",
        name: "Status",
        fieldName: "status",
        minWidth: 300,
        maxWidth: 500,
        isResizable: true
      }
    ];

    return (
      <div>
        {this.props.klantNaam ? (
          <div className="gridWrapper">
            <h1>Portaalpublicatie</h1>
            <div className="ms-Grid-col ms-sm12 ms-md12">
                  <PrimaryButton
                    iconProps={{ iconName: "Add", className: "ms-AddIcon" }}
                    onClick={this._changeNewPublicationItem}
                    className="submitButton"
                    text="Publiceer wijziging naar SharePoint"
                  />
                </div>
            <h4 class="section-divider sectionHeadings">
              <Icon style={{ verticalAlign: "bottom" }} iconName="Info"></Icon>{" "}
              Publicaties naar het portaal
            </h4>
            <div className="ms-Grid-col ms-col12 ms-md12">
                  <div className="detailList">
                    <DetailsList
                      items={this.state.publicationItems}
                      selectionMode={SelectionMode.none}
                      onColumnResize={false}
                      textAlign="left"
                      verticalAlign="left"
                      columns={this._columns}
                      layoutMode={DetailsListLayoutMode.justified}
                      compact={false}
                    />
                  </div>
                  <EditPublicationPanel panelOpen={this.state.panelOpen} 
                    onDismiss={this.closeEditAddPanel} 
                    selectableOrganisations={this.props.selectableOrganisations} 
                    selectedOrganisation={this.props.selectedOrganisation}
                    superAdmin={this.props.superAdmin}
                    itemToEdit={this.state.selectedNewsItem} 
                    OnPanelSave={this.onEditAddPanelSave}
                  >
                  </EditPublicationPanel>
                </div>
          </div>
        ) : (
          <div className="gridWrapper">
            {" "}
            <h1>Portaalpublicatie</h1>{" "}
            <p className="noOpenListButton">
              U heeft geen toegang tot dit scherm 
            </p>
          </div>
        )}
      </div>
    );
  }
}
