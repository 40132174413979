// Data Definition of a NewsItem. 
// must match the columns returned from the Rest API.
export class NewsItem {
    newsID;
    title;
    klantId;
    publicationDate;
    articleBody;

    constructor()
    {
        this.newsID = null;
        this.title = "";
        this.klantId = "";
        this.publicationDate = null;
        this.articleBody = "";
    }
}