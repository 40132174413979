import React, { Component } from "react";
import "./styles.css";
import { Icon, PrimaryButton } from "@fluentui/react";
import {
  DetailsList,
  DetailsListLayoutMode,
  Selection,
  SelectionMode
} from "@fluentui/react";
import { TilesService } from "../Services/TilesService";
import { EditSectionPanel } from "../Controls/Tiles/EditSectionPanel";
import { TilesSectionItem } from "../Services/Entities/TilesSectionItem";
import { CommandBarEditDeleteControl } from "../Controls/CommandBar/commandBarEditDelete";

export class TilesSection extends Component  {
  constructor(props) {
    super(props);
    this.state = {
      newItemCheck: false,
      editButton: false,
      sectionItems: [],
      selectedItem: new TilesSectionItem(),
      panelOpen: false
    };

    this._changeNewItem = () => {
      this.setState({ 
        newItemCheck: true,
        editButton: false,
        panelOpen:true,
        selectedItem: new TilesSectionItem() });
    };

    this.getSectionItems = () => {
      console.log("getSectionItems");
      if(this.props.selectedOrganisation != null)
      {
        //console.log("this.props.selectedOrganisation: ", this.props.selectedOrganisation);
        TilesService.Instance.GetSectionsByCustomer(this.props.selectedOrganisation).then((customerSections)=>{
          console.log("CUSTOMER SECTIONS", customerSections);
          this.setState({sectionItems: customerSections})
          });
      }
      else
      {
        TilesService.Instance.GetAllSections().then((sectionItems) =>
        {
          console.log("sectionItems", sectionItems);
          this.setState({ sectionItems:sectionItems });
        });
      }
    };

    this._selection = new Selection({
      onSelectionChanged: () =>
        this.setState({          
          editButton: true,
        })
    });

    this._showDialog = () => {
      this.setState({ openDialog: true });
    };

    this.editItem = () => {
      const selectionCount = this._selection.getSelectedCount();

      if (selectionCount === 1) {
        console.log(this._selection.getSelection()[0]);

        this.setState({
          selectedItem: this._selection.getSelection()[0]
          , panelOpen:true
        });
      }     
    }
    
    this.closeEditAddPanel = () => {
      this.setState({ panelOpen: false });
    };

    this.onEditAddPanelSave = (item) => {
      TilesService.Instance.SaveSection(item).then((isSucces) =>
      {
        this.setState({ panelOpen: false });
        this.getSectionItems();
      });
    };

    this.onDeleteSelectedItem = () => {
      var selectedTileSection = this._selection.getSelection()[0];

      console.log("Delete selected tile section", selectedTileSection);
      fetch("/api/tilesSection/remove", {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(selectedTileSection),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("Success:", data);
          this.setState(
            {
              newItemCheck: false,
              editButton: false,
              sectionItems: [],
              selectedItem: null,
              panelOpen: false
            },
            () => {
              this.getSectionItems();
              
            }
          );
        })
        .catch((error) => {
          console.error("Error:", error);
        });
       
    };
  }

  componentDidMount () {
    this.getSectionItems();
  }

  render() {
    this._columns = [
      {
        key: "organisation",
        name: "Organisatie",
        fieldName: "organisation",
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
      },
      {
        key: "sectionTitle",
        name: "Sectie Titel",
        fieldName: "sectionTitle",
        minWidth: 300,
        maxWidth: 500,
        isResizable: true,
      },
      {
        key: "position",
        name: "Positie",
        fieldName: "position",
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
      },
      
    ];

    const positionOptions = [
        { key: 1, text: 'Linksboven' },
        { key: 2, text: 'Rechtsboven' },
        { key: 3, text: 'Linksonder' },
        { key: 4, text: 'Rechtsonder'},
      ];

    return (
      <div className="gridWrapper">
        <h1>Tegelblokken</h1>
        <div className="ms-Grid-col ms-sm12 ms-md12">
              <PrimaryButton
                iconProps={{ iconName: "Add", className: "ms-AddIcon" }}
                onClick={this._changeNewItem}
                className="submitButton"
                text="Nieuwe sectie"
              />
            </div>
        <h4 class="section-divider sectionHeadings">
          <Icon style={{ verticalAlign: "bottom" }} iconName="Info"></Icon>{" "}
          Tegelblokken
        </h4>
        <div className="ms-Grid-col ms-col12 ms-md12">
              {this.state.editButton && this.state.selectedItem !== null && (
            <CommandBarEditDeleteControl EditItem={this.editItem} DeleteItem={this.onDeleteSelectedItem} />
          )}
              <div className="detailList">
                <DetailsList
                  items={this.state.sectionItems}
                  selectionMode={SelectionMode.single}
                  enterModalSelectionOnTouch={true}
                  onColumnResize={false}
                  textAlign="left"
                  verticalAlign="left"
                  columns={this._columns}
                  setKey="set"
                  layoutMode={DetailsListLayoutMode.justified}
                  ariaLabelForSelectionColumn="Toggle selection"
                  ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                  checkButtonAriaLabel="Row checkbox"
                  selection={this._selection}
                  compact={false}
                  selectionPreservedOnEmptyClick={true}
                />
              </div>
              <EditSectionPanel
                panelOpen={this.state.panelOpen} 
                onDismiss={this.closeEditAddPanel} 
                selectableOrganisations={this.props.selectableOrganisations}
                itemToEdit={this.state.selectedItem} 
                OnPanelSave={this.onEditAddPanelSave}
                selectablePositions={positionOptions}
                selectedOrganisation={this.props.selectedOrganisation}
              >
              </EditSectionPanel>
            </div>
      </div>
    );
  }
}
