import React, { Component } from "react";
import { TextField,Dropdown } from "office-ui-fabric-react/lib";
export class EditGebruikers extends Component {
  render() {
    return (
      <div>
        <TextField name="naam" required onChange={this.props.onChange} placeholder={this.props.Naam} label="Naam" />
        <TextField name="email" required onChange={this.props.onChange} placeholder={this.props.Email} label="Email" />
        <Dropdown
          name="organisatie"
          options={this.props.organisatieSelectie}
          onChange={this.props.ToggleDropdown}
          defaultSelectedKeys={this.props.Organisatie}
          placeholder={this.props.Organisatie}
          label="Organisatie"
          required
        />
         <Dropdown
          name="rolid"
          options={this.props.RechtenSelectie}
          onChange={this.props.ToggleRechtenDropdown}
          defaultSelectedKeys={this.props.Rechten}
          placeholder={this.props.Rechten}
          label="Rechten"
          required
        />
      </div>
    );
  }
}
