import React, { Component } from "react";
import "./styles.css";
import { PrimaryButton, DefaultButton, Dropdown } from "office-ui-fabric-react";
import {
  DetailsList,
  DetailsListLayoutMode,
  Selection,
  SelectionMode
} from "office-ui-fabric-react/lib/DetailsList";
import { CommandBarControl } from "../Controls/CommandBar/commandBar";
import { Icon } from "@fluentui/react";
import { BranchService } from "../Services/BranchService";
import { EditBranchPanel } from "../Controls/Branch/EditBranchPanel";
import { CustomerService } from "../Services/CustomerService";
export class Branch extends Component  {
  constructor(props) {
    super(props);
    this.state = {
      editButton: false,
      branchItems: [],
      selectedItem: [],
      selectableCustomers: [],
      panelOpen: false
    };

    this._changeNewItem = () => {
      this.setState({ 
        panelOpen:true,
        selectedItem:[] });
    };

    this.getBranchItems = () => {
      BranchService.Instance.GetAll().then((branchItems) =>
      {
        this.setState({ branchItems:branchItems });
      });
    };

    this.getCustomers = () => {
      CustomerService.Instance.GetAll().then((customers) =>
      {
        this.setState({selectableCustomers: customers});
      });
    };

    this._selection = new Selection({
      onSelectionChanged: () =>
        this.setState({
          editButton: true,
        })
    });

    this.editItem = () => {
      const selectionCount = this._selection.getSelectedCount();

      if (selectionCount == 1)
      {
        this.setState({
          selectedItem : this._selection.getSelection()[0]
          ,panelOpen:true });
      }     
    }

    this.closeEditAddPanel = () => {
      this.setState({ panelOpen: false });
    };

    this.onEditAddPanelSave = (item) => {
      BranchService.Instance.Save(item).then((isSucces) =>
      {
        this.setState({ panelOpen: false });
        this.getBranchItems();
      });
    } 
  }

  componentDidMount () {
    this.getCustomers();
    this.getBranchItems();
  }

  render() {
    this._columns = [
      {
        key: "organisation",
        name: "Organisatie",
        fieldName: "organisation",
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
      },
      {
        key: "name",
        name: "Naam",
        fieldName: "name",
        minWidth: 300,
        maxWidth: 500,
        isResizable: true,
      }
    ];

    return (
      <div className="gridWrapper">
        <h1>Vestiging</h1>
        <div className="ms-Grid-col ms-sm12 ms-md12">
              <PrimaryButton
                iconProps={{ iconName: "Add", className: "ms-AddIcon" }}
                onClick={this._changeNewItem}
                className="submitButton"
                text="Nieuwe vestiging"
              />
            </div>
        <h4 class="section-divider sectionHeadings">
          <Icon style={{ verticalAlign: "bottom" }} iconName="Info"></Icon>{" "}
          Vestigingen
        </h4>
        <div className="ms-Grid-col ms-col12 ms-md12">
              {this.state.editButton && this.state.selectedItem !== null && (
                <CommandBarControl EditItem={this.editItem} />
              )}
              <div className="detailList">
                <DetailsList
                  items={this.state.branchItems}
                  selectionMode={SelectionMode.single}
                  enterModalSelectionOnTouch={true}
                  onColumnResize={false}
                  textAlign="left"
                  verticalAlign="left"
                  columns={this._columns}
                  setKey="set"
                  layoutMode={DetailsListLayoutMode.justified}
                  ariaLabelForSelectionColumn="Toggle selection"
                  ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                  checkButtonAriaLabel="Row checkbox"
                  selection={this._selection}
                  compact={false}
                  selectionPreservedOnEmptyClick={true}
                />
              </div>
              <EditBranchPanel panelOpen={this.state.panelOpen} 
                onDismiss={this.closeEditAddPanel} 
                selectableOrganisations={this.state.selectableCustomers} 
                itemToEdit={this.state.selectedItem} 
                OnPanelSave={this.onEditAddPanelSave}
              >
              </EditBranchPanel>
            </div>
      </div>
    );
  }
}
