import React, { Component } from "react";
import "./styles.css";
import { Icon, PrimaryButton } from "@fluentui/react";
import {
  DetailsList,
  DetailsListLayoutMode,
  Selection,
  SelectionMode
} from "@fluentui/react";
import { TilePageService } from "../Services/TilePageService";
import { EditTilePagePanel } from "../Controls/Tiles/EditTilePagePanel";
import { TilePage } from "../Services/Entities/TilePage";
import { CommandBarEditDeleteControl } from "../Controls/CommandBar/commandBarEditDelete";

export class TilePages extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newItemCheck: false,
      editButton: false,
      tilePages: [],
      selectedItem: new TilePage(),
      panelOpen: false
    };

    this._changeNewItem = () => {
      this.setState({
        newItemCheck: true,
        editButton: false,
        panelOpen: true,
        selectedItem: new TilePage()
      });
    };

    this.getItems = () => {
      TilePageService.Instance.GetAll(this.props.selectedOrganisation).then((pageItems) => {
        console.log(pageItems);
        this.setState({ tilePages: pageItems });
      });
    };

    this._selection = new Selection({
      onSelectionChanged: () =>
        this.setState({
          editButton: true,
        })
    });
    
    this._showDialog = () => {
      this.setState({ openDialog: true });
    };

    this.editItem = () => {
      const selectionCount = this._selection.getSelectedCount();

      if (selectionCount === 1) {
        console.log(this._selection.getSelection()[0]);

        this.setState({
          selectedItem: this._selection.getSelection()[0]
          , panelOpen: true
        });
      }
    }

    this.closeEditAddPanel = () => {
      this.setState({ panelOpen: false });
    };

    this.onEditAddPanelSave = (item) => {
      TilePageService.Instance.Save(item).then((isSucces) => {
        this.setState({ panelOpen: false });
        this.getItems();
      });
    };

    this.onDeleteSelectedItem = () => {
      var selectedTilePage = this._selection.getSelection()[0];

      console.log("Delete selected tile page", selectedTilePage);
      fetch("/api/tilepage/remove", {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(selectedTilePage),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("Success:", data);
          this.setState(
            {
              newItemCheck: false,
              editButton: false,
              tilePages: [],
              selectedItem: null,
              panelOpen: false
            },
            () => {
              this.getItems();
              
            }
          );
        })
        .catch((error) => {
          console.error("Error:", error);
        });
       
    };
  }

  componentDidMount() {
    this.getItems();
  }

  render() {
    this._columns = [
      {
        key: "klantnaam",
        name: "Organisatie",
        fieldName: "klantnaam",
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
      },
      {
        key: "paginatitle",
        name: "Titel",
        fieldName: "paginatitle",
        minWidth: 100,
        maxWidth: 200,
        isResizable: true
      },
      {
        key: "template",
        name: "Template",
        fieldName: "template",
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
      },
      {
        key: "eigenaar",
        name: "Proceseigenaar",
        fieldName: "eigenaar",
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
      },
      {
        key: "pageurl",
        name: "Page Url",
        fieldName: "pageurl",
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
      },
    ];

    return (
      <div className="gridWrapper">
        <h1>Tegelpagina invullen </h1>
        <p>
          Dit is de invoermodule van een tegelpagina. Je kunt hier een pagina aanmaken voor gebruik in een tegel. 
          In de volgende stap Tegel invullen kun je de url naar deze pagina vervolgens gebruiken.
          Bij een klik op een tegel gaat de gebruiker dan naar de betreffende pagina. {" "}
        </p>
        <div className="ms-Grid-col ms-sm12 ms-md12">
          <PrimaryButton
            iconProps={{ iconName: "Add", className: "ms-AddIcon" }}
            onClick={this._changeNewItem}
            className="submitButton"
            text="Nieuwe tegelpagina"
          />
        </div>
        <h4 class="section-divider sectionHeadings">
          <Icon style={{ verticalAlign: "bottom" }} iconName="Info"></Icon>{" "}
          Tegelpagina's
        </h4>
        <div className="ms-Grid-col ms-col12 ms-md12">
          {this.state.editButton && this.state.selectedItem !== null && (
            <CommandBarEditDeleteControl EditItem={this.editItem} DeleteItem={this.onDeleteSelectedItem} />
          )}
          <div className="detailList">
            <DetailsList
              items={this.state.tilePages}
              selectionMode={SelectionMode.single}
              enterModalSelectionOnTouch={true}
              onColumnResize={false}
              textAlign="left"
              verticalAlign="left"
              columns={this._columns}
              setKey="set"
              layoutMode={DetailsListLayoutMode.justified}
              ariaLabelForSelectionColumn="Toggle selection"
              ariaLabelForSelectAllCheckbox="Toggle selection for all items"
              checkButtonAriaLabel="Row checkbox"
              selection={this._selection}
              compact={false}
              selectionPreservedOnEmptyClick={true}
            />
          </div>
          <EditTilePagePanel
            panelOpen={this.state.panelOpen}
            onDismiss={this.closeEditAddPanel}
            selectableOrganisations={this.props.selectableOrganisations}
            itemToEdit={this.state.selectedItem}
            OnPanelSave={this.onEditAddPanelSave}
            selectedOrganisation={this.props.selectedOrganisation}
          >
          </EditTilePagePanel>
        </div>
      </div>
    );
  }
}
