import React, { Component } from "react";
import "./styles.css";
import { AddProcessen } from "../Containers/ProcessenInvullen/addProcessen";
import { Icon, PrimaryButton } from "@fluentui/react";
import {
  DetailsList,
  DetailsListLayoutMode,
  Selection,
  SelectionMode
} from "@fluentui/react"
import { CommandBarControl } from "../Controls/CommandBar/commandBar";
import { PageService } from "../Services/PageService";
import { EditProcessPagePanel } from "../Controls/Process/EditProcessPagePanel";
import { ProcessPage } from "../Services/Entities/ProcessPage";
import { CommandBarEditDeleteControl } from "../Controls/CommandBar/commandBarEditDelete";

export class ProcessInvullen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newItemCheck: false,
      editButton: false,
      procesPages: [],
      selectedItem: new ProcessPage(),
      panelOpen: false
    };

    this._changeNewItem = () => {
      this.setState({
        newItemCheck: true,
        editButton: false,
        panelOpen: true,
        selectedItem: new ProcessPage()
      });
    };

    this.getItems = () => {
      PageService.Instance.GetAll(this.props.selectedOrganisation).then((pageItems) => {
        console.log(pageItems);
        this.setState({ procesPages: pageItems });
      });
    };

    this._selection = new Selection({
      onSelectionChanged: () =>
        this.setState({
          editButton: true,
        })
    });
    
    this._showDialog = () => {
      this.setState({ openDialog: true });
    };

    this.editItem = () => {
      const selectionCount = this._selection.getSelectedCount();

      if (selectionCount == 1) {
        console.log(this._selection.getSelection()[0]);

        this.setState({
          selectedItem: this._selection.getSelection()[0]
          , panelOpen: true
        });
      }
    }

    this.closeEditAddPanel = () => {
      this.setState({ panelOpen: false });
    };

    this.onEditAddPanelSave = (item) => {
      PageService.Instance.Save(item).then((isSucces) => {
        this.setState({ panelOpen: false });
        this.getItems();
      });
    };

    this.onDeleteSelectedItem = () => {
      var selectedProcess = this._selection.getSelection()[0];

      console.log("Delete selected proces pagina", selectedProcess);
      fetch("/api/proces/remove", {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(selectedProcess),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("Success:", data);
          this.setState(
            {
              newItemCheck: false,
              editButton: false,
              procesPages: [],
              selectedItem: null,
              panelOpen: false
            },
            () => {
              this.getItems();
              
            }
          );
        })
        .catch((error) => {
          console.error("Error:", error);
        });
       
    };
  }

  componentDidMount() {
    this.getItems();
  }

  render() {
    this._columns = [
      {
        key: "klantnaam",
        name: "Organisatie",
        fieldName: "klantnaam",
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
      },
      {
        key: "paginatitle",
        name: "Titel",
        fieldName: "paginatitle",
        minWidth: 100,
        maxWidth: 200,
        isResizable: true
      },
      {
        key: "template",
        name: "Template",
        fieldName: "template",
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
      },
      {
        key: "eigenaar",
        name: "Proceseigenaar",
        fieldName: "eigenaar",
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
      },
    ];

    return (
      <div className="gridWrapper">
        <h1>Processen invullen </h1>
        <p>
          Dit is de invoermodule van de processen van de klantreis. Je kunt
          daarbij kiezen uit twee formats. Een format met stroomschema en een
          format met alleen tekst. Bij ieder proces wordt tevens een
          proceseigenaar benoemd. Dit is een medewerker die het proces kan
          overzien en veel kennis heeft over het proces. Hij/zij kan worden
          benaderd in het geval van vragen, en zal periodiek het proces reviewen
          op actualiteit en potentiële verbeteringen. Uiteindelijk keurt de
          proceseigenaar het proces goed, voordat het proces toegankelijk is
          voor alle gebruikers.{" "}
        </p>
        <div className="ms-Grid-col ms-sm12 ms-md12">
          <PrimaryButton
            iconProps={{ iconName: "Add", className: "ms-AddIcon" }}
            onClick={this._changeNewItem}
            className="submitButton"
            text="Nieuw proces pagina"
          />
        </div>
        <h4 class="section-divider sectionHeadings">
          <Icon style={{ verticalAlign: "bottom" }} iconName="Info"></Icon>{" "}
          Processen
        </h4>
        <div className="ms-Grid-col ms-col12 ms-md12">
          {this.state.editButton && this.state.selectedNewsItem !== null && (
            <CommandBarEditDeleteControl EditItem={this.editItem} DeleteItem={this.onDeleteSelectedItem} />
          )}
          <div className="detailList">
            <DetailsList
              items={this.state.procesPages}
              selectionMode={SelectionMode.single}
              enterModalSelectionOnTouch={true}
              onColumnResize={false}
              textAlign="left"
              verticalAlign="left"
              columns={this._columns}
              setKey="set"
              layoutMode={DetailsListLayoutMode.justified}
              ariaLabelForSelectionColumn="Toggle selection"
              ariaLabelForSelectAllCheckbox="Toggle selection for all items"
              checkButtonAriaLabel="Row checkbox"
              selection={this._selection}
              compact={false}
              selectionPreservedOnEmptyClick={true}
            />
          </div>
          <EditProcessPagePanel panelOpen={this.state.panelOpen}
            onDismiss={this.closeEditAddPanel}
            selectableOrganisations={this.props.selectableOrganisations}
            itemToEdit={this.state.selectedItem}
            OnPanelSave={this.onEditAddPanelSave}
            selectedOrganisation={this.props.selectedOrganisation}
          >
          </EditProcessPagePanel>
        </div>
      </div>
    );
  }
}
