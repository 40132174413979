import React, { Component } from "react";
import {
  Checkbox,
  DefaultButton,
  PrimaryButton,
  Stack,
} from "@fluentui/react";
import { Dropdown } from "@fluentui/react"
import { Panel, PanelType } from "@fluentui/react";
import { PublicationItem } from "../../Services/Entities/PublicationItem";
import { PublicationService } from "../../Services/PublicationService";

export class EditPublicationPanel extends Component {

    constructor(props) {
        super(props);
        
        this.state = {
            valueItemId: null,
            valueSelectedOrganisation: null,
            valueSite: false,
            valueNorms: false,
            valueUsers: false,
            valuePages: false,
            valueNews: false,
            portaalDisabled: false
        }
    };

    onSave = () => {
        var returnedItem = new PublicationItem();
        returnedItem.publicationId = this.state.valueItemId;
        returnedItem.klantId = this.props.selectedOrganisation != null ? this.props.selectedOrganisation : this.state.valueSelectedOrganisation;
        returnedItem.site = this.state.valueSite;
        returnedItem.norms = this.state.valueNorms;
        returnedItem.users = this.state.valueUsers;
        returnedItem.pages = this.state.valuePages;
        returnedItem.news = this.state.valueNews;
        console.log(returnedItem);
        this.props.OnPanelSave(returnedItem);
        
        this.clearItemState();
    }

    onDismiss = () => {
        this.clearItemState();
        this.props.onDismiss();
    }

    clearItemState = () => {
        this.setState({
            valueItemId: null,
            valueSelectedOrganisation: null,
            valueSite: false,
            valueNorms: false,
            valueUsers: false,
            valuePages: false,
            valueNews: false,
            portaalDisabled: false
        });
    }

    closePanel = () => {
        return (
        <div>
            <PrimaryButton className="submitButton" onClick={this.onSave.bind(this)}>
                Opslaan
            </PrimaryButton>
            <DefaultButton className="submitButton" onClick={this.onDismiss.bind(this)}>
                Annuleer
            </DefaultButton>
        </div>
        );
    };

    render = () => {
        const stackTokens = { childrenGap: 10 };

        return (
            <div className="customPanel">
                <Panel
                    isOpen={this.props.panelOpen}
                    onDismiss={this.props.onDismiss.bind(this)}
                    headerText="Wijziging publiceren"
                    closeButtonAriaLabel="Close"
                    aria-multiselectable={false}
                    onRenderFooterContent={this.closePanel}
                    isFooterAtBottom={true}
                    type={PanelType.medium}
                >
                    <Stack tokens={stackTokens} >
                        <Dropdown
                            onChange={this.onChangeOrganisation}
                            options={this.props.selectableOrganisations}
                            name="organisatie"
                            label="Organisatie"
                            placeholder={"Selecteer een organisatie"}
                            selectedKey={this.props.selectedOrganisation != null ? this.props.selectedOrganisation : this.state.valueSelectedOrganisation}
                            required
                            disabled={this.props.selectedOrganisation != null}
                            />

                        {this.props.superAdmin ? (
                            <Checkbox
                                label="Portaal"
                                checked={this.state.valueSite}
                                onChange={this.onChangeSite}
                                disabled={this.state.portaalDisabled}
                            />
                            ) : (
                            <Checkbox
                                label="Portaal"
                                onChange=""
                                disabled="{true}"
                            />
                        )}

                         <Checkbox
                            label="Normen"
                            checked={this.state.valueNorms}
                            onChange={this.onChangeNorms}
                         />

                         <Checkbox
                            label="Gebruikers"
                            checked={this.state.valueUsers}
                            onChange={this.onChangeUsers}
                         />

                         <Checkbox
                            label="Pagina's"
                            checked={this.state.valuePages}
                            onChange={this.onChangePages}
                         />

                         <Checkbox
                            label="Nieuws"
                            checked={this.state.valueNews}
                            onChange={this.onChangeNews}
                         />
                    </Stack>
                    
                </Panel>
            </div>
        );
    };

    onChangeOrganisation = (event, item) =>
    {
        this.clearItemState();
        this.setState({ valueSelectedOrganisation: item.key });
        PublicationService.Instance.GetAllByKlantId(item.key).then((publicationItems) =>
        {
            publicationItems.forEach(publication => {
                if (publication.site) {
                    // this.setState({ portaalDisabled: true });
                }
            });
        });
    }
    onChangeSite = (event, value) => {
        this.setState({ valueSite: value })
    }
    onChangeNorms = (event, value) => {
        this.setState({ valueNorms: value })
    }
    onChangeUsers = (event, value) => {
        this.setState({ valueUsers: value })
    }
    onChangePages = (event, value) => {
        this.setState({ valuePages: value })
    }
    onChangeNews = (event, value) => {
        this.setState({ valueNews: value })
    }
}