import { Nav, INavStyles, INavLinkGroup } from "office-ui-fabric-react/lib/Nav";
import { initializeIcons } from "office-ui-fabric-react/lib";
import React, { Component } from "react";
import { browserHistory, withRouter } from "react-router-dom";
import "../LeftNavigation/leftnavStyles.css";
import { Link } from "react-router-dom";

class Leftnavigation extends Component {
    
  constructor(props) {
    super(props);
    this.state = {
        isTilesExpanded: false
    };
  }

  onHandleClick = (event, item) => {
    //console.log("onHandleClick event", event);
    //console.log("onHandleClick item", item);
    if(item && item.name && item.name === "Tegels")
    {
      //console.log("onHandleClick Tegels: ", item);

      // Use the negate of isExpanded as this is the state at the moment of click, so after the click the state is the oppsosite.
      this.setState({ isTilesExpanded: !item.isExpanded });
    }
  }

  render() {

    initializeIcons();

    var navgroups = this.createNavigation();

    return (
      <div className="styledSideNav">
        <Nav groups={navgroups} ariaLabel="QSN" onLinkClick={this.onHandleClick} onLinkExpandClick={this.onHandleClick} />
      </div>
    );
  }

  createNavigation() {
    var navigation = [
      {
        name: "RMS 365",
        links: [
          {
            name: (
              <Link className="leftnavLink" to="/start">
                Startpagina
              </Link>
            ),
            key: "Home",
            icon: "HomeSolid",
          },
        ],
      },
    ];

    this.addNavigationSettings(navigation);
    this.addRmsConfiguration(navigation);
    this.addSettingsInfo(navigation);

    return navigation;
  }

  addNavigationSettings(navigation) {
    if (this.props.superAdmin) {
      navigation[0].links.push({
        //   name: 'Instellingen',
        //   url: '/instellingen',
        name: (
          <Link className="leftnavLink" to="/instellingen">
            Instellingen
          </Link>
        ),
        isExpanded: true,
        key: "Instellingen",

        icon: "Settings",
        expandAriaLabel: "Expand Parent link 1",
        collapseAriaLabel: "Collapse Parent link 1",
        links: [
          {
            key: "Licentie informatie",
            name: (
              <Link className="leftnavLink" to="/LI">
                Licentie informatie
              </Link>
            ),
            target: "_self",

            //   url: '/LI',
          },
          {
            key: "Administrator RMS",
            //   url: '/AR',
            name: (
              <Link className="leftnavLink" to="/AR">
                Administrator RMS
              </Link>
            ),
            target: "_self",
          },
        ],
      });
    }
  }

  addSettingsInfo(navigation) {
    navigation[0].links.push({
      name: (
        <Link className="leftnavLink" to="/RMSI">
          Informatie
        </Link>
      ),
      key: "Infomatie",
      //   url:'/RMSI' ,
      target: "_self",
      icon: "Info",
      isExpanded: true,
      expandAriaLabel: "Expand Child link 2",
      collapseAriaLabel: "Collapse Child link 2",
      links: [
        {
          key: "Software info",
          name: (
            <Link className="leftnavLink" to="/SI">
              Software Info
            </Link>
          ),
          target: "_self",

          //   url: '/LI',
        },
        {
          name: (
            <Link className="leftnavLink" to="/LU">
              Licenties
            </Link>
          ),
          //   url: '/OK',
          key: "Licenties",
          target: "_self",
        },
      ],
    });
  }

  addRmsConfiguration(navigation) {
    navigation[0].links.push({
      name: (
        <Link className="leftnavLink" to="/RMSI">
          RMS instellingen
        </Link>
      ),
      key: "RMS instellingen",
      //   url:'/RMSI' ,
      target: "_self",
      icon: "PlayerSettings",
      isExpanded: true,
      expandAriaLabel: "Expand Child link 2",
      collapseAriaLabel: "Collapse Child link 2",
      links: [
        {
          key: "Gebruikers",
          name: (
            <Link className="leftnavLink" to="/GB">
              Gebruikers
            </Link>
          ),
          target: "_self",

          //   url: '/LI',
        },
        {
          name: (
            <Link className="leftnavLink" to="/OK">
              Opmaken klantreis
            </Link>
          ),
          //   url: '/OK',
          key: "Opmaken klantreis",
          target: "_self",
        },
        {
          name: (
            <Link className="leftnavLink" to="/PI">
              Processen invullen
            </Link>
          ),
          // url: '/PI',
          target: "_self",
          key: "Processen invullen",
        },
        {
          name: (
            <Link className="leftnavLink" to="/NEWS">
              Nieuws
            </Link>
          ),
          // name: 'Nieuws',
          // url: '/NEWS',
          target: "_self",
          key: "Nieuws",
        },
        {
          name: (
            <Link className="leftnavLink" to="/BRANCH">
              Vestigingen
            </Link>
          ),
          // name: 'Vestigingen',
          // url: '/BRANCH',
          target: "_self",
          key: "Vestigingen",
        },
        {
          name: "Tegels",
          key: "Tegels",
          //   url:'/SECTION' ,
          target: "_self",
          icon: "Tiles",
          isExpanded: this.state.isTilesExpanded != null ? this.state.isTilesExpanded : false,
          expandAriaLabel: "Expand Child link",
          collapseAriaLabel: "Collapse Child link",
          links: [
            {
              name: (
                <Link className="leftnavLink" to="/SECTION">
                  Tegel blokken
                </Link>
              ),
              // name: 'Tegel blokken',
              // url: '/SECTION',
              target: "_self",
              key: "Tegel blokken",
            },
            {
              name: (
                <Link className="leftnavLink" to="/tegelpagina">
                  Tegel pagina invullen
                </Link>
              ),
              // name: 'Tegel pagina invullen',
              // url: '/tegelpagina',
              target: "_self",
              key: "Tegel pagina invullen",
            },
            {
              name: (
                <Link className="leftnavLink" to="/tegelinvullen">
                  Tegel invullen
                </Link>
              ),
              // name: 'Tegel invullen',
              // url: '/tegelinvullen',
              target: "_self",
              key: "Tegel invullen",
            },
          ],
        },
        {
          name: (
            <Link className="leftnavLink" to="/CA">
              Contextanalyse
            </Link>
          ),
          // name: 'Context en risico analyse',
          // url: '/CA',
          target: "_self",
          key: "Context en risico analyse",
        },
        {
          name: (
            <Link className="leftnavLink" to="/SA">
              Stakeholderanalyse
            </Link>
          ),
          // name: 'Stakeholderanalyse',
          // url: '/SA',
          target: "_self",
          key: "Stakeholderanalyse",
        },
        {
          name: (
            <Link className="leftnavLink" to="/RA">
              Risicoanalyse
            </Link>
          ),
          // name: 'Risico analyse',
          // url: '/RA',
          target: "_self",
          key: "Risico analyse",
        },
        {
          name: (
            <Link className="leftnavLink" to="/WR">
              Wet- en regelgeving
            </Link>
          ),
          // name: 'Wet- en regelgeving',
          // url: '/WR',
          target: "_self",
          key: "Wet- en regelgeving",
        },
        {
          name: "Overzicht continu verbeteren",
          name: (
            <Link className="leftnavLink" to="/OCV">
              Overzicht continu verbeteren
            </Link>
          ),
          // url: '/OCV',
          target: "_self",
          key: "Overzicht continu verbeteren",
        },
        {
          name: "Portaalpublicatie",
          name: (
            <Link className="leftnavLink" to="/Publish">
              Portaalpublicatie
            </Link>
          ),
          target: "_self",
          key: "Portaalpublicatie",
        },
      ],
    });
  }
}

export default Leftnavigation;
