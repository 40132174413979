import React, { Component } from "react";
import { DefaultButton, PrimaryButton, Stack } from "@fluentui/react";
import { Dropdown, TextField, Label } from "@fluentui/react";
import { Panel, PanelType } from "@fluentui/react";
import { TilesSectionItem } from "../../Services/Entities/TilesSectionItem";
//import { IconPicker } from '@pnp/spfx-controls-react/lib/IconPicker';
//import { Icon } from "@fluentui/react";

export class EditSectionPanel extends Component {
  constructor(props) {
    super(props);

    this.state = this.GetDefaultState();
  };

  GetDefaultState = () => {
    return {
      valueItemId: null,
      valueSelectedOrganisation: null,
      valueTitle: "",
      valuePosition: null,
    };
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.itemToEdit && prevProps.itemToEdit !== this.props.itemToEdit)
    {
        console.log(this.props.selectablePositions)
        console.log(this.props.itemToEdit);
        this.setState({
            valueItemId: this.props.itemToEdit.sectionID,
            valueSelectedOrganisation: this.props.itemToEdit.klantId,
            valueTitle: this.props.itemToEdit.sectionTitle,
            valuePosition: this.props.itemToEdit.position,
        });
    }
  };

  onSave = () => {
    var requiredFieldsAreFilled = true;
        
    // Validate required fields.
    if( ((this.props.selectedOrganisation === "" || this.props.selectedOrganisation == null) && (this.state.valueSelectedOrganisation === "" || this.state.valueSelectedOrganisation == null )) ||
        this.state.valueTitle === "" || this.state.valueTitle == null ||
        this.state.valuePosition === "" || this.state.valuePosition == null )
    {
        requiredFieldsAreFilled = false;
    }

    if(!requiredFieldsAreFilled)
    {
        alert('Gelieve de verplichte velden, gemarkeerd met *, in te vullen');
    }
    else
    {
      var returnedItem = new TilesSectionItem();
      returnedItem.sectionID = this.state.valueItemId;
      returnedItem.sectionTitle = this.state.valueTitle;

      var selectedCustomerId = this.props.selectedOrganisation != null ? this.props.selectedOrganisation : this.state.valueSelectedOrganisation;
      returnedItem.klantId = selectedCustomerId;

      returnedItem.position = this.state.valuePosition;
      console.log(returnedItem);
      this.props.OnPanelSave(returnedItem);
      
      this.clearItemState();
    }
  };

  onDismiss = () => {
    this.clearItemState();
    this.props.onDismiss();
  };

  clearItemState = () => {
    this.setState(this.GetDefaultState());
  };

  closePanel = () => {
    return (
      <div>
        <PrimaryButton
          className="submitButton"
          onClick={this.onSave.bind(this)}
        >
          Opslaan
        </PrimaryButton>
        <DefaultButton
          className="submitButton"
          onClick={this.onDismiss.bind(this)}
        >
          Annuleer
        </DefaultButton>
      </div>
    );
  };

  render = () => {
    //console.log("EditSectionPanel this.props.selectableOrganisations: ", this.props.selectableOrganisations);
    //console.log("EditSectionPanel this.props.selectedOrganisation: ", this.props.selectedOrganisation);
    //console.log("EditSectionPanel this.state.valueSelectedOrganisation: ", this.state.valueSelectedOrganisation);
    //console.log("this.props.itemToEdit.klantId: ", this.props.itemToEdit.klantId);
    return (
      <div className="customPanel">
        <Panel
          isOpen={this.props.panelOpen}
          onDismiss={this.props.onDismiss.bind(this)}
          headerText="Sectie bewerken\aanmaken"
          closeButtonAriaLabel="Close"
          aria-multiselectable={false}
          onRenderFooterContent={this.closePanel}
          isFooterAtBottom={true}
          type={PanelType.medium}
        >
          <Stack>
            <Dropdown
              onChange={this.onChangeOrganisation}
              options={this.props.selectableOrganisations}
              name="organisatie"
              label="Organisatie"
              placeholder={"Selecteer een organisatie"}
              selectedKey={this.props.selectedOrganisation != null ? this.props.selectedOrganisation : this.state.valueSelectedOrganisation}
              required
              disabled={this.props.selectedOrganisation != null}
            />
            <TextField
              label="Titel"
              name="text"
              required
              onChange={this.onChangeTitle}
              value={this.state.valueTitle}
              validateOnFocusOut
              //errorMessage={this.state.errorMessage}
            />
            <Dropdown
              onChange={this.onChangePosition}
              options={this.props.selectablePositions}
              name="position"
              label="positie"
              placeholder={"Selecteer een positie"}
              selectedKey={this.state.valuePosition}
              required
            />
          </Stack>
        </Panel>
      </div>
    );
  };

  //onChangeOrganisation = (event, item) => {
  onChangeOrganisation = async (event, item) => {
    this.setState({ valueSelectedOrganisation: item.key });
  };

  onChangeTitle = (event, value) => {
    this.setState({ valueTitle: value });
  };

  onChangePosition = (event, item) => {
    this.setState({ valuePosition: item.key });
  };
}
