import React, { Component } from "react";
import {
  DefaultButton,
  PrimaryButton,
  Stack,
} from "@fluentui/react";
import { Dropdown, TextField, Label } from "@fluentui/react"
import { Panel, PanelType } from "@fluentui/react";
import { BranchItem } from "../../Services/Entities/BranchItem";

export class EditBranchPanel extends Component {

    constructor(props) {
        super(props);

        this.state = {
            valueItemId: null,
            valueSelectedOrganisation: null,
            valueName: "",
        }
    };

    componentDidUpdate = (prevProps) => {
        if (this.props.itemToEdit && prevProps.itemToEdit !== this.props.itemToEdit)
        {
            this.setState({
                valueItemId: this.props.itemToEdit.branchID,
                valueSelectedOrganisation: this.props.itemToEdit.klantId,
                valueName: this.props.itemToEdit.name,
            });
        }
    }

    onSave = () => {
        var returnedItem = new BranchItem();
        returnedItem.branchID = this.state.valueItemId;
        returnedItem.name = this.state.valueName;
        returnedItem.klantId = this.state.valueSelectedOrganisation;
        console.log(returnedItem);
        this.props.OnPanelSave(returnedItem);
        
        this.clearItemState();
    }

    onDismiss = () => {
        this.clearItemState();
        this.props.onDismiss();
    }

    clearItemState = () => {
        this.setState({
            valueItemId: null,
            valueSelectedOrganisation: null,
            valueName: ""
        });
    }

    closePanel = () => {
        return (
        <div>
            <PrimaryButton className="submitButton" onClick={this.onSave.bind(this)}>
                Opslaan
            </PrimaryButton>
            <DefaultButton className="submitButton" onClick={this.onDismiss.bind(this)}>
                Annuleer
            </DefaultButton>
        </div>
        );
    };

    render = () => {

        return (
            <div className="customPanel">
                <Panel
                    isOpen={this.props.panelOpen}
                    onDismiss={this.props.onDismiss.bind(this)}
                    headerText="Vestiging bewerken\aanmaken"
                    closeButtonAriaLabel="Close"
                    aria-multiselectable={false}
                    onRenderFooterContent={this.closePanel}
                    isFooterAtBottom={true}
                    type={PanelType.medium}
                >
                    <Stack>
                        <Dropdown
                            onChange={this.onChangeOrganisation}
                            options={this.props.selectableOrganisations}
                            name="organisatie"
                            label="Organisatie"
                            placeholder={"Selecteer een organisatie"}
                            selectedKey={this.state.valueSelectedOrganisation}
                            required
                            />
                         <TextField
                            label="Naam"
                            name="text"
                            required
                            onChange={this.onChangeName}
                            value={this.state.valueName}
                            validateOnFocusOut
                            //errorMessage={this.state.errorMessage}
                        />
                    </Stack>
                    
                </Panel>
            </div>
        );
    };

    onChangeOrganisation = (event, item) =>
    {
        this.setState({ valueSelectedOrganisation: item.key })
    }
    onChangeName = (event, value) => {
        this.setState({ valueName: value })
    }

}