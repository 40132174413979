// Data Definition of a PublicationItem. 
// must match the columns returned from the Rest API.
export class PublicationItem {
    publicationId;
    klantId;
    site;
    norms;
    users;
    pages;
    news;
    status;
    updateDate;

    constructor()
    {
        this.publicationId = null;
        this.klantId = "";
        this.site = false;
        this.norms = false;
        this.users = false;
        this.pages = false;
        this.news = false;
        this.status = "";
        this.updateDate = null;
    }
}