import React, { Component } from "react";
import "./styles.css";
import "office-ui-fabric-react/dist/css/fabric.css";
import { TextField } from "office-ui-fabric-react/lib/TextField";
import "regenerator-runtime/runtime";
import {
  Dialog,
  DialogFooter,
  DialogType,
} from "office-ui-fabric-react/lib/Dialog";
import {
  PrimaryButton,
  Stack,
  ComboBox,
  DefaultButton,
  arraysEqual,
} from "office-ui-fabric-react";
import { Icon } from "office-ui-fabric-react/lib";
import {
  DetailsList,
  DetailsListLayoutMode,
  Selection,
} from "office-ui-fabric-react/lib/DetailsList";
import { CommandBarControlLicentie } from "../Controls/CommandBar/commandBarLicentie";
import { LicentiePanel } from "../Controls/Panel/licentiePanel";
import {
  generatePallette,
  generatePowershellPallette,
} from "../Controls/ThemeGenerator/themeGen";

export class LicentieInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newKlantCheck: false,
      editButton: false,
      openPanel: false,
      openDialog: false,
      openErrorDialog: false,
      openSiteDialog: false,
      validationMessage: "",
      newKlant: {
        uniqueID: "",
        text: "",
        email: "",
        voornaam: "",
        telefoon: "",
        organisatie: "",
        enabled: true,
        naam: "",
        primarycolor: "#0078d4",
        textcolor: "#323130",
        backgroundcolor: "#ffffff",
        themejson: "",
        themeps: "",
        norm: [],
        site: "",
      },
      klanten: [],
      normen: [],
      loading: false,
      selectedKlant: undefined,
      addKlant: false,
      validationMessageEmail: "",
    };
    this.changeSomething = () => {
      var klant = this._getSelectionDetailsNameItem();
      // this.updateJSON();
      console.log("Klant", klant);

      this.setState({ selectedKlant: klant, addKlant: false, newKlant: klant });
    };

    this.componentDidMount = () => {
      console.log("componentDidMount");
      this.getKlanten();
      this.getNorm();
      console.log("props", this.props);
    };

    this.copyArray = (array) => {
      const newArray = [];
      for (let i = 0; i < array.length; i++) {
        newArray[i] = array[i];
      }
      return newArray;
    };

    this.hideSiteDialog = () => {
      this.setState({ openSiteDialog: false });
    };

    this.handleEmail = (e) => {
      let { newKlant } = this.state;

      this.setState({ validationMessageEmail: "" });
      const emailRegex =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line
      if (!emailRegex.test(e.target.value)) {
        const errMsg = "Het email adres voldoet niet aan de eisen";
        this.setState({ validationMessageEmail: errMsg });
      } else {
        newKlant[e.target.name] = e.target.value;
        this.setState({
          newKlant: newKlant,
          validationMessageEmail: "",
        });
      }
    };

    this.updateJSON = () => {
      const { newKlant } = this.state;

      const primaryCol = newKlant.primarycolor;
      const backgroundCol = newKlant.backgroundcolor;
      const textColor = newKlant.textcolor;

      if (primaryCol && backgroundCol && textColor) {
        const powershellobject = generatePowershellPallette(
          primaryCol,
          backgroundCol,
          textColor
        );
        this.setState((prevState) => ({
          newKlant: {
            ...prevState.newKlant,
            themeps: powershellobject,
          },
        }));
      }
    };

    //this.handleNormsChange = new Selection({

    // onSelectionChanged: () =>
    //           this.setState({
    //             editButton: true,
    //             //   Naam: this._getSelectionDetailsName("naam"),
    //             //   Voornaam: this._getSelectionDetailsName("voornaam"),
    //             //   Email: this._getSelectionDetailsName("email"),
    //             //   Organisatie: this._getSelectionDetailsName("text"),
    //             //   Telefoon: this._getSelectionDetailsName("telefoon"),
    //           }),

    //       });

    this.handleNormsChange = (e) => {
      console.log("Norms", e);
      console.log("Norms", e.target.id);
      const norms = this.state.newKlant.norm
        ? this.copyArray(this.state.newKlant.norm)
        : [];
      if (e.target.ariaChecked === "true") {
        norms.push(e.target.ariaLabel);
      } else {
        const currIndex = norms.indexOf(e.target.ariaLabel);
        if (currIndex > -1) {
          norms.splice(currIndex, 1);
        }
      }

      this.setState((prevState) => ({
        newKlant: {
          ...prevState.newKlant,
          norm: norms,
        },
      }));
    };
    this.AddKlant = () => {
      const { newKlant } = this.state;

      if (
        newKlant.text !== "" &&
        newKlant.email !== "" &&
        newKlant.voornaam !== "" &&
        newKlant.telefoon !== ""
      ) {
        this.updateJSON();
        fetch("/api/klant", {
          method: "POST", // or 'PUT'
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newKlant),
        })
          .then((response) => response.json())
          .then((data) => {
            console.log("Success:", data);
            this._showDialog();
            this.setState(
              {
                loading: true,
                newKlant: {
                  uniqueID: "",
                  text: "",
                  email: "",
                  voornaam: "",
                  telefoon: "",
                  organisatie: "",
                  enabled: false,
                  naam: "",
                  themeps: "",
                },
              },
              () => {
                this.getKlanten();
              }
            );
          })
          .catch((error) => {
            console.error("Error:", error);
            alert("Error:", error);
          });
      } else {
        this.setState({
          openErrorDialog: true,
          validationMessage: "Vereiste veld",
        });
      }
    };
    this.updateKlant = () => {
      const { newKlant } = this.state;
      fetch("/api/klant", {
        method: "PUT", // or 'PUT'
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newKlant),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("Success:", data);
          this._showDialog();

          this.setState(
            {
              newKlant: {
                uniqueID: "",
                text: "",
                email: "",
                voornaam: "",
                naam: "",
                telefoon: "",
                organisatie: "",
                enabled: false,
                themeps: "",
              },
            },
            () => {
              this.getKlanten();
            }
          );
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    };

    this.getKlanten = () => {
      fetch("/api/klant/all")
        .then((response) => response.json())
        .then((data) => {
          this.setState({ klanten: data, loading: false });
        });
    };

    this.getNorm = () => {
      fetch("/api/norm/")
        .then((response) => response.json())
        .then((data) => {
          this.setState({ normen: data });
        });
    };
    this.navigateToSite = async () => {
      await this.changeSomething();
      if (this.state.selectedKlant.site) {
        window.open(`${this.state.selectedKlant.site}`);
      } else {
        this.setState({ openSiteDialog: true });
      }
    };

    this.ChangeNewFormValue = (e) => {
      this.setState({ [e.target.name]: e.target.value });
    };
    this._changeNewKlant = () => {
      this.setState({ newKlantCheck: true, editButton: false });
    };
    this._changeNewKlantClose = () => {
      this.setState({ newKlantCheck: false });
    };
    this._changeRightNavOpen = () => {
      this.setState({ openRightNav: false });
    };
    this._showPanel = () => {
      this.setState({ openPanel: true });
      this.changeSomething();
    };
    this._closePanel = () => {
      this.setState({
        openPanel: false,
        Email: "",
        Naam: "",
        Organisatie: "",
        Telefoon: "",
        Voornaam: "",
      });
    };
    this._showDialog = () => {
      this.setState({ openDialog: true });
    };
    this._hideDialog = () => {
      this.setState({ openDialog: false, newKlantCheck: false });
    };
    this._hideErrorDialog = () => {
      this.setState({ openErrorDialog: false, validationMessage: "" });
    };
    this._getSelectionDetailsName = (name) => {
      const selectionCount = this._selection.getSelectedCount();

      switch (selectionCount) {
        case 0:
          return "No items selected";
        case 1:
          return this._selection.getSelection()[0][name];
        default:
          return `${selectionCount} items selected`;
      }
    };
    this._getSelectionDetailsNameItem = () => {
      const selectionCount = this._selection.getSelectedCount();

      switch (selectionCount) {
        case 0:
          return "No items selected";
        case 1:
          return this._selection.getSelection()[0];
        default:
          return `${selectionCount} items selected`;
      }
    };
    this._selection = new Selection({
      onSelectionChanged: () =>
        this.setState({
          editButton: this._selection.getSelectedCount() === 1,
          //   Naam: this._getSelectionDetailsName("naam"),
          //   Voornaam: this._getSelectionDetailsName("voornaam"),
          //   Email: this._getSelectionDetailsName("email"),
          //   Organisatie: this._getSelectionDetailsName("text"),
          //   Telefoon: this._getSelectionDetailsName("telefoon"),
        }),
    });
    this.editField = (e) => {
      let { newKlant } = this.state;

      if (e.target.value !== undefined) {
        newKlant[e.target.name] = e.target.value;
      }
      this.setState({ newKlant: newKlant });
      this.updateJSON();
    };
  }
  render() {
    this._columns = [
      {
        key: "text",
        name: "Organisatie",
        fieldName: "text",
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
      },
      {
        key: "voornaam",
        name: "Naam",
        fieldName: "voornaam",
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
      },
      {
        key: "email",
        name: "Email",
        fieldName: "email",
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
      },

      {
        key: "telefoon",
        name: "Telefoon",
        fieldName: "telefoon",
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
      },
      {
        key: "site",
        name: "Site",
        fieldName: "site",
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
      },
      {
        key: "uniqueID",
        name: "GUID",
        fieldName: "uniqueID",
        minWidth: 100,
        maxWidth: 100,
        isResizable: true,
      },
    ];

    const stackTokens = { childrenGap: 10 };
    const {
      newKlantCheck,
      editButton,
      openDialog,
      openErrorDialog,
      klanten,
      newKlant,
      normen,
      selectedKlant,
      openSiteDialog,
    } = this.state;
    const comboBoxMultiStyle = {
      maxWidth: 300,
      display: "block",
      marginTop: "10px",
    };
    const dialogContentProps = {
      type: DialogType.normal,
      title: "Licentie informatie is succesvol opgeslagen",
    };
    const dialogSiteContentProps = {
      type: DialogType.normal,
      title: "Site URL is leeg",
    };
    const dialogContentErrorProps = {
      type: DialogType.normal,
      title:
        "De vereiste velden (*) zijn benodigd voordat u deze gegevens kunt versturen",
    };

    return (
      <div>
        {this.props.superAdmin ? (
          <div className="gridWrapper">
            <h1>Licentie informatie</h1>
            <div className="ms-Grid-col ms-sm12 ms-md12">
              <PrimaryButton
                iconProps={{ iconName: "Add", className: "ms-AddIcon" }}
                onClick={this._changeNewKlant}
                className="submitButton"
                text="Nieuwe Klant"
              />
            </div>
            <h4 class="section-divider sectionHeadings">
              <Icon style={{ verticalAlign: "bottom" }} iconName="Info"></Icon>{" "}
              Klantgegevens
            </h4>
            <div className="ms-Grid-col ms-col12 ms-md12">
              {editButton && selectedKlant !== null && (
                <CommandBarControlLicentie
                  siteURL={this.navigateToSite}
                  EditItem={this._showPanel}
                />
              )}
              {!newKlantCheck && normen && normen.length > 0 && (
                <div className="detailList">
                  <DetailsList
                    items={klanten}
                    selectionMode={"single"}
                    enterModalSelectionOnTouch={true}
                    onColumnResize={false}
                    textAlign="left"
                    verticalAlign="left"
                    columns={this._columns}
                    setKey="set"
                    layoutMode={DetailsListLayoutMode.justified}
                    ariaLabelForSelectionColumn="Toggle selection"
                    ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                    checkButtonAriaLabel="Row checkbox"
                    selection={this._selection}
                    compact={false}
                    selectionPreservedOnEmptyClick={true}
                  />
                </div>
              )}
            </div>
            {newKlantCheck ? (
              <div className="detailList">
                <div className="ms-Grid" dir="ltr">
                  <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-col12 ms-md6">
                      <TextField
                        onChange={this.editField}
                        required
                        label="Organisatie"
                        name="text"
                        errorMessage={this.state.validationMessage}
                      />
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md6">
                      <TextField
                        onChange={this.editField}
                        required
                        label="Naam"
                        name="voornaam"
                        errorMessage={this.state.validationMessage}
                      />
                    </div>
                    {/* <div className="ms-Grid-col ms-sm12 ms-md6">
                  <TextField
                    onChange={this.editField}
                    required
                    label="Naam"
                    name="naam"
                  />
                </div> */}
                    <div className="ms-Grid-col ms-sm12 ms-md6">
                      <TextField
                        name="telefoon"
                        onChange={this.editField}
                        required
                        label="Telefoon"
                        errorMessage={this.state.validationMessage}
                      />
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md6">
                      <TextField
                        name="email"
                        onChange={this.handleEmail}
                        required
                        label="Email"
                        errorMessage={this.state.validationMessageEmail}
                      />
                    </div>
                    <br></br>
                    <div
                      style={{ "padding-top": 10 }}
                      className="ms-Grid-col ms-sm12 ms-md12"
                    >
                      <Stack tokens={stackTokens}>
                        {/* <NormSelector klantNaam="Nieuwe klant" />
                    <ComboBox
                      multiSelect
                      label="Norm selectie"
                      id="normSelector"
                      errorMessage={this.state.validationMessage}
                      placeHolder="Normselectie:"
                      required={true}
                     
                    //   selectedKey={this.state.newKlant.norms} 
                    //     onChanged={this.handleNormsChange}
                      onChanged={this.handleNormsChange}
                      onChange={this.handleNormsChange}
                  
                     
                      // options={normen.map((item) => {
                      //   return { key: item.value, text: item };
                      // })}
                    options={normen}
                      // onError={this.errorMultiselect}
                    /> */}
                      </Stack>
                    </div>
                    <div className="ms-Grid-row">
                      <div className="ms-Grid-col">
                        <PrimaryButton
                          className="submitButton"
                          onClick={this.AddKlant}
                          text="Opslaan"
                        />
                      </div>
                      <div className="ms-Grid-col">
                        <DefaultButton
                          className="submitButton"
                          onClick={this._changeNewKlantClose}
                          text="Annuleer"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            <LicentiePanel
              onDismiss={this._closePanel}
              onPanelSave={this.updateKlant}
              panelOpen={this.state.openPanel}
              panelHeader={"Licentie informatie"}
              Naam={this.state.newKlant.naam}
              Email={this.state.newKlant.email}
              Organisatie={this.state.newKlant.text}
              Telefoon={this.state.newKlant.telefoon}
              Voornaam={this.state.newKlant.voornaam}
              GUID={this.state.newKlant.uniqueID}
              Norms={this.state.newKlant.norm}
              normSelection={this.state.normen}
              onChange={this.editField}
              comboboxChange={this.handleNormsChange}
              KlantId={this.state.newKlant.key}
            />
            <Dialog
              hidden={!openDialog}
              onDismiss={this._hideDialog}
              dialogContentProps={dialogContentProps}
              styles={{ "z-index": 1, backgroundcolor : "white" }}
            >
              <DialogFooter>
                <PrimaryButton onClick={this._hideDialog} text="OK" />
              </DialogFooter>
            </Dialog>
            <Dialog
              hidden={!openSiteDialog}
              onDismiss={this.hideSiteDialog}
              dialogContentProps={dialogSiteContentProps}
              styles={{ "z-index": 1, backgroundcolor : "white"  }}
            >
              <DialogFooter>
                <PrimaryButton onClick={this.hideSiteDialog} text="OK" />
              </DialogFooter>
            </Dialog>
            <Dialog
              hidden={!openErrorDialog}
              onDismiss={this._hideErrorDialog}
              dialogContentProps={dialogContentErrorProps}
              styles={{ "z-index": 1, backgroundcolor : "white"  }}
            >
              <DialogFooter>
                <PrimaryButton onClick={this._hideErrorDialog} text="OK" />
              </DialogFooter>
            </Dialog>
          </div>
        ) : (
          <div className="gridWrapper">
            {" "}
            <h1>Licentie info</h1>{" "}
            <p className="noOpenListButton">
              U heeft geen toegang tot dit scherm
            </p>
          </div>
        )}
      </div>
    );
  }
}
