import React, { Component } from "react";
import { PrimaryButton } from "office-ui-fabric-react";

export class OpenListButton extends Component {
  render() {
    return (
      <div>
        <PrimaryButton
          text="Open lijst"
          iconProps={{ iconName: "View", className: "ms-AddIcon" }}
          onClick={this.props.openList}
          className="submitButton"
          allowDisabledFocus
        />
      </div>
    );
  }
}


