import React, { Component } from "react";
import { DefaultButton, PrimaryButton, Stack } from "@fluentui/react";
import { Dropdown, TextField } from "@fluentui/react";
import { Panel, PanelType } from "@fluentui/react";
import { Tile } from "../../Services/Entities/Tile";
//import { IconPicker } from '@pnp/spfx-controls-react/lib/IconPicker';
import { Icon } from "@fluentui/react";

export class EditTilesPanel extends Component {
  constructor(props) {
    super(props);

    this.state = this.GetDefaultState();
  };

  GetDefaultState = () => {
    return {
      valueItemId: null,

      valueSelectedOrganisation: null,
      valueTitle: "",
      valueSequence: null,
      valueIcon: "",

      selectablePages: [],
      valueSelectedPageID: null,

      valueAllSelectionSection: [],
      valueSectionID: "",
    };
  }

  componentDidUpdate = (prevProps) => {
    if (
      this.props.itemToEdit &&
      prevProps.itemToEdit !== this.props.itemToEdit
    ) {
      console.log("Item to edit: ", this.props.itemToEdit);
      console.log("TESTa")
      console.log("Klant ID:",this.props.itemToEdit.klantId);
      //console.log("TEST1a this.props.itemToEdit.klantid:",this.props.itemToEdit.klantid);
      //console.log("TEST2a this.props.itemToEdit.klantId:",this.props.itemToEdit.klantId);
      //console.log("TEST3a this.props.itemToEdit.klantID:",this.props.itemToEdit.klantID);

      this.setState({
        valueItemId: this.props.itemToEdit.tileID,

        valueSelectedOrganisation: this.props.itemToEdit.klantId,
        valueTitle: this.props.itemToEdit.tileTitle,
        valueSequence: this.props.itemToEdit.sequence,
        valueIcon: this.props.itemToEdit.iconName,

        valueSelectedPageID: this.props.itemToEdit.pageID,
        valueSectionID: this.props.itemToEdit.sectionId,
      });
     
      //console.log("TEST1b this.props.itemToEdit.klantid:",this.props.itemToEdit.klantid);
      //console.log("TEST2b this.props.itemToEdit.klantId:",this.props.itemToEdit.klantId);
      //console.log("TEST3b this.props.itemToEdit.klantID:",this.props.itemToEdit.klantID);
      
      var klantId = "";
      if(this.props.itemToEdit.klantId)
      {
        console.log("componentDidUpdate - klantId: ", this.props.itemToEdit.klantId , " (this.props.itemToEdit.klantId)");
        klantId = this.props.itemToEdit.klantId;
      }
      else if(this.props.selectedOrganisation)
      {
        console.log("componentDidUpdate - klantId: ", this.props.selectedOrganisation , " (this.props.selectedOrganisation)");
        klantId = this.props.selectedOrganisation;
      }
      else if(this.state.valueSelectedOrganisation)
      {
        console.log("componentDidUpdate - klantId: ", this.state.valueSelectedOrganisation , " (this.state.valueSelectedOrganisation)");
        klantId = this.state.valueSelectedOrganisation;
      }

      if(klantId)
      {
        this.getAvailableSection(klantId);
        this.getPages(klantId);
      }
      else
      {
        console.log("componentDidUpdate - klantId has no value");
      }
    }
  };

  getAvailableSection = (klantId) => {
    if(klantId) {
      console.log("klantId: " + klantId)
      fetch("/api/tilesSection/customerSection/" + klantId, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => response.json())
      .then((data) => {
        console.log("Available sections", data);
        data = data.map( function(dataItem) { return { key: dataItem.key, text: dataItem.sectionTitle } } )
        console.log("Available sections mapped to key and text for klantId: " + klantId, data);
        this.setState({ valueAllSelectionSection: data });
      })
      .catch((error) => {
        console.error("Error fetching available sections:", error);
      });
    }
  };

  getPages = (klantId) => {
    if(klantId) {
      console.log("klantId: " + klantId)
      fetch("/api/tilepage/" + klantId + "/all", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => response.json())
      .then((data) => {
        console.log("Available pages", data);
        //data = data.map( function(dataItem) { return { key: dataItem.key, text: dataItem.paginatitle } } )
        data = data.map( function(dataItem) { return { key: dataItem.key, text: dataItem.text } } )
        console.log("Available pages mapped to key and text for klantId: " + klantId, data);
        this.setState({ selectablePages: data });
      })
      .catch((error) => {
        console.error("Error fetching available pages:", error);
      });
    }
  };

  onSave = () => {
    var requiredFieldsAreFilled = true;
        
    // Validate required fields.
    if( ((this.props.selectedOrganisation === "" || this.props.selectedOrganisation == null) && (this.state.valueSelectedOrganisation === "" || this.state.valueSelectedOrganisation == null )) ||
        this.state.valueTitle === "" || this.state.valueTitle == null ||
        this.state.valueSectionID === "" || this.state.valueSectionID == null ||
        this.state.valueIcon === "" || this.state.valueIcon == null ||
        this.state.valueSequence === "" || this.state.valueSequence == null )
    {
        requiredFieldsAreFilled = false;
    }

    if(!requiredFieldsAreFilled)
    {
        alert('Gelieve de verplichte velden, gemarkeerd met *, in te vullen');
    }
    else
    {
      var returnedItem = new Tile();

      returnedItem.tileID = this.state.valueItemId;
      returnedItem.tileTitle = this.state.valueTitle;

      var selectedCustomerId = this.props.selectedOrganisation != null ? this.props.selectedOrganisation : this.state.valueSelectedOrganisation;
      returnedItem.klantId = selectedCustomerId;
      
      returnedItem.sequence = this.state.valueSequence;
      returnedItem.iconName = this.state.valueIcon;

      returnedItem.pageID = this.state.valueSelectedPageID;
      returnedItem.sectionID = this.state.valueSectionID;
      
      console.log(returnedItem);
      this.props.OnPanelSave(returnedItem);

      this.clearItemState();
    }
  };

  onDismiss = () => {
    this.clearItemState();
    this.props.onDismiss();
  };

  clearItemState = () => {
    this.setState(this.GetDefaultState());
  };

  closePanel = () => {
    return (
      <div>
        <PrimaryButton
          className="submitButton"
          onClick={this.onSave.bind(this)}
        >
          Opslaan
        </PrimaryButton>
        <DefaultButton
          className="submitButton"
          onClick={this.onDismiss.bind(this)}
        >
          Annuleer
        </DefaultButton>
      </div>
    );
  };

  render = () => {

    //console.log("EditTilesPanel this.props.selectableOrganisations: ", this.props.selectableOrganisations);
    //console.log("EditTilesPanel this.props.selectedOrganisation: ", this.props.selectedOrganisation);
    //console.log("EditTilesPanel this.state.valueSelectedOrganisation: ", this.state.valueSelectedOrganisation);

    const stackTokens = { childrenGap: 10 };
    //Icons are from this source: https://github.com/OfficeDev/office-ui-fabric-core/blob/master/src/data/icons.json    
    this.iconOptions = [
      {
        "key": "12PointStar",
        "text": "12PointStar",
        "data": {
          "icon": "12PointStar"
        }
      },
      {
        "key": "6PointStar",
        "text": "6PointStar",
        "data": {
          "icon": "6PointStar"
        }
      },
      {
        "key": "AADLogo",
        "text": "AADLogo",
        "data": {
          "icon": "AADLogo"
        }
      },
      {
        "key": "Accept",
        "text": "Accept",
        "data": {
          "icon": "Accept"
        }
      },
      {
        "key": "AccessibiltyChecker",
        "text": "AccessibiltyChecker",
        "data": {
          "icon": "AccessibiltyChecker"
        }
      },
      {
        "key": "AccessLogo",
        "text": "AccessLogo",
        "data": {
          "icon": "AccessLogo"
        }
      },
      {
        "key": "AccountActivity",
        "text": "AccountActivity",
        "data": {
          "icon": "AccountActivity"
        }
      },
      {
        "key": "AccountBrowser",
        "text": "AccountBrowser",
        "data": {
          "icon": "AccountBrowser"
        }
      },
      {
        "key": "AccountManagement",
        "text": "AccountManagement",
        "data": {
          "icon": "AccountManagement"
        }
      },
      {
        "key": "Accounts",
        "text": "Accounts",
        "data": {
          "icon": "Accounts"
        }
      },
      {
        "key": "ActionCenter",
        "text": "ActionCenter",
        "data": {
          "icon": "ActionCenter"
        }
      },
      {
        "key": "ActivateOrders",
        "text": "ActivateOrders",
        "data": {
          "icon": "ActivateOrders"
        }
      },
      {
        "key": "ActivityFeed",
        "text": "ActivityFeed",
        "data": {
          "icon": "ActivityFeed"
        }
      },
      {
        "key": "Add",
        "text": "Add",
        "data": {
          "icon": "Add"
        }
      },
      {
        "key": "AddBookmark",
        "text": "AddBookmark",
        "data": {
          "icon": "AddBookmark"
        }
      },
      {
        "key": "AddEvent",
        "text": "AddEvent",
        "data": {
          "icon": "AddEvent"
        }
      },
      {
        "key": "AddFavorite",
        "text": "AddFavorite",
        "data": {
          "icon": "AddFavorite"
        }
      },
      {
        "key": "AddFavoriteFill",
        "text": "AddFavoriteFill",
        "data": {
          "icon": "AddFavoriteFill"
        }
      },
      {
        "key": "AddFriend",
        "text": "AddFriend",
        "data": {
          "icon": "AddFriend"
        }
      },
      {
        "key": "AddGroup",
        "text": "AddGroup",
        "data": {
          "icon": "AddGroup"
        }
      },
      {
        "key": "AddHome",
        "text": "AddHome",
        "data": {
          "icon": "AddHome"
        }
      },
      {
        "key": "AddIn",
        "text": "AddIn",
        "data": {
          "icon": "AddIn"
        }
      },
      {
        "key": "AddLink",
        "text": "AddLink",
        "data": {
          "icon": "AddLink"
        }
      },
      {
        "key": "AddNotes",
        "text": "AddNotes",
        "data": {
          "icon": "AddNotes"
        }
      },
      {
        "key": "AddOnlineMeeting",
        "text": "AddOnlineMeeting",
        "data": {
          "icon": "AddOnlineMeeting"
        }
      },
      {
        "key": "AddPhone",
        "text": "AddPhone",
        "data": {
          "icon": "AddPhone"
        }
      },
      {
        "key": "AddReaction",
        "text": "AddReaction",
        "data": {
          "icon": "AddReaction"
        }
      },
      {
        "key": "AddTo",
        "text": "AddTo",
        "data": {
          "icon": "AddTo"
        }
      },
      {
        "key": "AddToShoppingList",
        "text": "AddToShoppingList",
        "data": {
          "icon": "AddToShoppingList"
        }
      },
      {
        "key": "AddWork",
        "text": "AddWork",
        "data": {
          "icon": "AddWork"
        }
      },
      {
        "key": "Admin",
        "text": "Admin",
        "data": {
          "icon": "Admin"
        }
      },
      {
        "key": "AdminALogo32",
        "text": "AdminALogo32",
        "data": {
          "icon": "AdminALogo32"
        }
      },
      {
        "key": "AdminALogoFill32",
        "text": "AdminALogoFill32",
        "data": {
          "icon": "AdminALogoFill32"
        }
      },
      {
        "key": "Airplane",
        "text": "Airplane",
        "data": {
          "icon": "Airplane"
        }
      },
      {
        "key": "AirplaneSolid",
        "text": "AirplaneSolid",
        "data": {
          "icon": "AirplaneSolid"
        }
      },
      {
        "key": "AirTickets",
        "text": "AirTickets",
        "data": {
          "icon": "AirTickets"
        }
      },
      {
        "key": "AlarmClock",
        "text": "AlarmClock",
        "data": {
          "icon": "AlarmClock"
        }
      },
      {
        "key": "Album",
        "text": "Album",
        "data": {
          "icon": "Album"
        }
      },
      {
        "key": "AlbumRemove",
        "text": "AlbumRemove",
        "data": {
          "icon": "AlbumRemove"
        }
      },
      {
        "key": "AlertSettings",
        "text": "AlertSettings",
        "data": {
          "icon": "AlertSettings"
        }
      },
      {
        "key": "AlertSolid",
        "text": "AlertSolid",
        "data": {
          "icon": "AlertSolid"
        }
      },
      {
        "key": "AlignCenter",
        "text": "AlignCenter",
        "data": {
          "icon": "AlignCenter"
        }
      },
      {
        "key": "AlignLeft",
        "text": "AlignLeft",
        "data": {
          "icon": "AlignLeft"
        }
      },
      {
        "key": "AlignRight",
        "text": "AlignRight",
        "data": {
          "icon": "AlignRight"
        }
      },
      {
        "key": "AllApps",
        "text": "AllApps",
        "data": {
          "icon": "AllApps"
        }
      },
      {
        "key": "AllAppsMirrored",
        "text": "AllAppsMirrored",
        "data": {
          "icon": "AllAppsMirrored"
        }
      },
      {
        "key": "AllCurrency",
        "text": "AllCurrency",
        "data": {
          "icon": "AllCurrency"
        }
      },
      {
        "key": "AnalyticsLogo",
        "text": "AnalyticsLogo",
        "data": {
          "icon": "AnalyticsLogo"
        }
      },
      {
        "key": "AnalyticsQuery",
        "text": "AnalyticsQuery",
        "data": {
          "icon": "AnalyticsQuery"
        }
      },
      {
        "key": "AnalyticsReport",
        "text": "AnalyticsReport",
        "data": {
          "icon": "AnalyticsReport"
        }
      },
      {
        "key": "AnalyticsView",
        "text": "AnalyticsView",
        "data": {
          "icon": "AnalyticsView"
        }
      },
      {
        "key": "AnchorLock",
        "text": "AnchorLock",
        "data": {
          "icon": "AnchorLock"
        }
      },
      {
        "key": "Annotation",
        "text": "Annotation",
        "data": {
          "icon": "Annotation"
        }
      },
      {
        "key": "AppIconDefault",
        "text": "AppIconDefault",
        "data": {
          "icon": "AppIconDefault"
        }
      },
      {
        "key": "AppIconDefaultAdd",
        "text": "AppIconDefaultAdd",
        "data": {
          "icon": "AppIconDefaultAdd"
        }
      },
      {
        "key": "Archive",
        "text": "Archive",
        "data": {
          "icon": "Archive"
        }
      },
      {
        "key": "AreaChart",
        "text": "AreaChart",
        "data": {
          "icon": "AreaChart"
        }
      },
      {
        "key": "Arrivals",
        "text": "Arrivals",
        "data": {
          "icon": "Arrivals"
        }
      },
      {
        "key": "Articles",
        "text": "Articles",
        "data": {
          "icon": "Articles"
        }
      },
      {
        "key": "Ascending",
        "text": "Ascending",
        "data": {
          "icon": "Ascending"
        }
      },
      {
        "key": "AspectRatio",
        "text": "AspectRatio",
        "data": {
          "icon": "AspectRatio"
        }
      },
      {
        "key": "AssessmentGroup",
        "text": "AssessmentGroup",
        "data": {
          "icon": "AssessmentGroup"
        }
      },
      {
        "key": "AssessmentGroupTemplate",
        "text": "AssessmentGroupTemplate",
        "data": {
          "icon": "AssessmentGroupTemplate"
        }
      },
      {
        "key": "AssetLibrary",
        "text": "AssetLibrary",
        "data": {
          "icon": "AssetLibrary"
        }
      },
      {
        "key": "Assign",
        "text": "Assign",
        "data": {
          "icon": "Assign"
        }
      },
      {
        "key": "Asterisk",
        "text": "Asterisk",
        "data": {
          "icon": "Asterisk"
        }
      },
      {
        "key": "AsteriskSolid",
        "text": "AsteriskSolid",
        "data": {
          "icon": "AsteriskSolid"
        }
      },
      {
        "key": "ATPLogo",
        "text": "ATPLogo",
        "data": {
          "icon": "ATPLogo"
        }
      },
      {
        "key": "Attach",
        "text": "Attach",
        "data": {
          "icon": "Attach"
        }
      },
      {
        "key": "AuthenticatorApp",
        "text": "AuthenticatorApp",
        "data": {
          "icon": "AuthenticatorApp"
        }
      },
      {
        "key": "AutoDeploySettings",
        "text": "AutoDeploySettings",
        "data": {
          "icon": "AutoDeploySettings"
        }
      },
      {
        "key": "AutoEnhanceOff",
        "text": "AutoEnhanceOff",
        "data": {
          "icon": "AutoEnhanceOff"
        }
      },
      {
        "key": "AutoEnhanceOn",
        "text": "AutoEnhanceOn",
        "data": {
          "icon": "AutoEnhanceOn"
        }
      },
      {
        "key": "AutoFillTemplate",
        "text": "AutoFillTemplate",
        "data": {
          "icon": "AutoFillTemplate"
        }
      },
      {
        "key": "AutoHeight",
        "text": "AutoHeight",
        "data": {
          "icon": "AutoHeight"
        }
      },
      {
        "key": "AutoRacing",
        "text": "AutoRacing",
        "data": {
          "icon": "AutoRacing"
        }
      },
      {
        "key": "AwayStatus",
        "text": "AwayStatus",
        "data": {
          "icon": "AwayStatus"
        }
      },
      {
        "key": "Back",
        "text": "Back",
        "data": {
          "icon": "Back"
        }
      },
      {
        "key": "BackgroundColor",
        "text": "BackgroundColor",
        "data": {
          "icon": "BackgroundColor"
        }
      },
      {
        "key": "Backlog",
        "text": "Backlog",
        "data": {
          "icon": "Backlog"
        }
      },
      {
        "key": "BacklogBoard",
        "text": "BacklogBoard",
        "data": {
          "icon": "BacklogBoard"
        }
      },
      {
        "key": "BackToWindow",
        "text": "BackToWindow",
        "data": {
          "icon": "BackToWindow"
        }
      },
      {
        "key": "Badge",
        "text": "Badge",
        "data": {
          "icon": "Badge"
        }
      },
      {
        "key": "Balloons",
        "text": "Balloons",
        "data": {
          "icon": "Balloons"
        }
      },
      {
        "key": "Bank",
        "text": "Bank",
        "data": {
          "icon": "Bank"
        }
      },
      {
        "key": "BankSolid",
        "text": "BankSolid",
        "data": {
          "icon": "BankSolid"
        }
      },
      {
        "key": "BarChart4",
        "text": "BarChart4",
        "data": {
          "icon": "BarChart4"
        }
      },
      {
        "key": "BarChartHorizontal",
        "text": "BarChartHorizontal",
        "data": {
          "icon": "BarChartHorizontal"
        }
      },
      {
        "key": "BarChartVertical",
        "text": "BarChartVertical",
        "data": {
          "icon": "BarChartVertical"
        }
      },
      {
        "key": "Baseball",
        "text": "Baseball",
        "data": {
          "icon": "Baseball"
        }
      },
      {
        "key": "BeerMug",
        "text": "BeerMug",
        "data": {
          "icon": "BeerMug"
        }
      },
      {
        "key": "BIDashboard",
        "text": "BIDashboard",
        "data": {
          "icon": "BIDashboard"
        }
      },
      {
        "key": "BIDashboard",
        "text": "BIDashboard",
        "data": {
          "icon": "BIDashboard"
        }
      },
      {
        "key": "BirthdayCake",
        "text": "BirthdayCake",
        "data": {
          "icon": "BirthdayCake"
        }
      },
      {
        "key": "BlockContact",
        "text": "BlockContact",
        "data": {
          "icon": "BlockContact"
        }
      },
      {
        "key": "Blocked",
        "text": "Blocked",
        "data": {
          "icon": "Blocked"
        }
      },
      {
        "key": "BlockedSite",
        "text": "BlockedSite",
        "data": {
          "icon": "BlockedSite"
        }
      },
      {
        "key": "Blog",
        "text": "Blog",
        "data": {
          "icon": "Blog"
        }
      },
      {
        "key": "BlowingSnow",
        "text": "BlowingSnow",
        "data": {
          "icon": "BlowingSnow"
        }
      },
      {
        "key": "Blur",
        "text": "Blur",
        "data": {
          "icon": "Blur"
        }
      },
      {
        "key": "Boards",
        "text": "Boards",
        "data": {
          "icon": "Boards"
        }
      },
      {
        "key": "BookAnswers",
        "text": "BookAnswers",
        "data": {
          "icon": "BookAnswers"
        }
      },
      {
        "key": "Breakfast",
        "text": "Breakfast",
        "data": {
          "icon": "Breakfast"
        }
      },
      {
        "key": "Brightness",
        "text": "Brightness",
        "data": {
          "icon": "Brightness"
        }
      },
      {
        "key": "Bug",
        "text": "Bug",
        "data": {
          "icon": "Bug"
        }
      },
      {
        "key": "Build",
        "text": "Build",
        "data": {
          "icon": "Build"
        }
      },
      {
        "key": "BulletedList",
        "text": "BulletedList",
        "data": {
          "icon": "BulletedList"
        }
      },
      {
        "key": "BullseyeTargetEdit",
        "text": "BullseyeTargetEdit",
        "data": {
          "icon": "BullseyeTargetEdit"
        }
      },
      {
        "key": "Bus",
        "text": "Bus",
        "data": {
          "icon": "Bus"
        }
      },
      {
        "key": "Calendar",
        "text": "Calendar",
        "data": {
          "icon": "Calendar"
        }
      },
      {
        "key": "Camera",
        "text": "Camera",
        "data": {
          "icon": "Camera"
        }
      },
      {
        "key": "Cancel",
        "text": "Cancel",
        "data": {
          "icon": "Cancel"
        }
      },
      {
        "key": "Car",
        "text": "Car",
        "data": {
          "icon": "Car"
        }
      },
      {
        "key": "ChangeEntitlements",
        "text": "ChangeEntitlements",
        "data": {
          "icon": "ChangeEntitlements"
        }
      },
      {
        "key": "Checkbox",
        "text": "Checkbox",
        "data": {
          "icon": "Checkbox"
        }
      },
      {
        "key": "CheckboxComposite",
        "text": "CheckboxComposite",
        "data": {
          "icon": "CheckboxComposite"
        }
      },
      {
        "key": "CheckboxCompositeReversed",
        "text": "CheckboxCompositeReversed",
        "data": {
          "icon": "CheckboxCompositeReversed"
        }
      },
      {
        "key": "CheckboxFill",
        "text": "CheckboxFill",
        "data": {
          "icon": "CheckboxFill"
        }
      },
      {
        "key": "CheckboxIndeterminate",
        "text": "CheckboxIndeterminate",
        "data": {
          "icon": "CheckboxIndeterminate"
        }
      },
      {
        "key": "CheckList",
        "text": "CheckList",
        "data": {
          "icon": "CheckList"
        }
      },
    {
        "key": "CheckMark",
        "text": "CheckMark",
        "data": {
          "icon": "CheckMark"
        }
      },
      {
        "key": "ChevronDown",
        "text": "ChevronDown",
        "data": {
          "icon": "ChevronDown"
        }
      },
      {
        "key": "ChevronLeft",
        "text": "ChevronLeft",
        "data": {
          "icon": "ChevronLeft"
        }
      },
      {
        "key": "ChevronRight",
        "text": "ChevronRight",
        "data": {
          "icon": "ChevronRight"
        }
      },
      {
        "key": "ChevronUp",
        "text": "ChevronUp",
        "data": {
          "icon": "ChevronUp"
        }
      },
      {
        "key": "Cloud",
        "text": "Cloud",
        "data": {
          "icon": "Cloud"
        }
      },
      {
        "key": "Color",
        "text": "Color",
        "data": {
          "icon": "Color"
        }
      },
      {
        "key": "CommandPrompt",
        "text": "CommandPrompt",
        "data": {
          "icon": "CommandPrompt"
        }
      },
      {
        "key": "CommentSolid",
        "text": "CommentSolid",
        "data": {
          "icon": "CommentSolid"
        }
      },
      {
        "key": "ComplianceAudit",
        "text": "ComplianceAudit",
        "data": {
          "icon": "ComplianceAudit"
        }
      },
      {
        "key": "Contact",
        "text": "Contact",
        "data": {
          "icon": "Contact"
        }
      },
    {
        "key": "ContactCard",
        "text": "ContactCard",
        "data": {
          "icon": "ContactCard"
        }
      },
      {
        "key": "ContactInfo",
        "text": "ContactInfo",
        "data": {
          "icon": "ContactInfo"
        }
      },
      {
        "key": "Contrast",
        "text": "Contrast",
        "data": {
          "icon": "Contrast"
        }
      },
      {
        "key": "Crop",
        "text": "Crop",
        "data": {
          "icon": "Crop"
        }
      },
      {
        "key": "Delete",
        "text": "Delete",
        "data": {
          "icon": "Delete"
        }
      },
      {
        "key": "DependencyAdd",
        "text": "DependencyAdd",
        "data": {
          "icon": "DependencyAdd"
        }
      },
      {
        "key": "DependencyRemove",
        "text": "DependencyRemove",
        "data": {
          "icon": "DependencyRemove"
        }
      },
      {
        "key": "Dialpad",
        "text": "Dialpad",
        "data": {
          "icon": "Dialpad"
        }
      },
      {
        "key": "Down",
        "text": "Down",
        "data": {
          "icon": "Down"
        }
      },
      {
        "key": "EaseOfAccess",
        "text": "EaseOfAccess",
        "data": {
          "icon": "EaseOfAccess"
        }
      },
      {
        "key": "EatDrink",
        "text": "EatDrink",
        "data": {
          "icon": "EatDrink"
        }
      },
      {
        "key": "Edit",
        "text": "Edit",
        "data": {
          "icon": "Edit"
        }
      },
      {
        "key": "Education",
        "text": "Education",
        "data": {
          "icon": "Education"
        }
      },
      {
        "key": "EMI",
        "text": "EMI",
        "data": {
          "icon": "EMI"
        }
      },
      {
        "key": "Emoji2",
        "text": "Emoji2",
        "data": {
          "icon": "Emoji2"
        }
      },
      {
        "key": "EntitlementPolicy",
        "text": "EntitlementPolicy",
        "data": {
          "icon": "EntitlementPolicy"
        }
      },
      {
        "key": "EntitlementRedemption",
        "text": "EntitlementRedemption",
        "data": {
          "icon": "EntitlementRedemption"
        }
      },
      {
        "key": "EraseTool",
        "text": "EraseTool",
        "data": {
          "icon": "EraseTool"
        }
      },
      {
        "key": "Error",
        "text": "Error",
        "data": {
          "icon": "Error"
        }
      },
      {
        "key": "Favicon",
        "text": "Favicon",
        "data": {
          "icon": "Favicon"
        }
      },
      {
        "key": "FavoriteList",
        "text": "FavoriteList",
        "data": {
          "icon": "FavoriteList"
        }
      },
      {
        "key": "FavoriteStar",
        "text": "FavoriteStar",
        "data": {
          "icon": "FavoriteStar"
        }
      },
      {
        "key": "FavoriteStarFill",
        "text": "FavoriteStarFill",
        "data": {
          "icon": "FavoriteStarFill"
        }
      },
      {
        "key": "Ferry",
        "text": "Ferry",
        "data": {
          "icon": "Ferry"
        }
      },
      {
        "key": "Filter",
        "text": "Filter",
        "data": {
          "icon": "Filter"
        }
      },
      {
        "key": "Filters",
        "text": "Filters",
        "data": {
          "icon": "Filters"
        }
      },
    {
        "key": "FilterSettings",
        "text": "FilterSettings",
        "data": {
          "icon": "FilterSettings"
        }
      },
      {
        "key": "Financial",
        "text": "Financial",
        "data": {
          "icon": "Financial"
        }
      },
    {
        "key": "Fingerprint",
        "text": "Fingerprint",
        "data": {
          "icon": "Fingerprint"
        }
      },
      {
        "key": "Flag",
        "text": "Flag",
        "data": {
          "icon": "Flag"
        }
      },
      {
        "key": "Flashlight",
        "text": "Flashlight",
        "data": {
          "icon": "Flashlight"
        }
      },
      {
        "key": "Forward",
        "text": "Forward",
        "data": {
          "icon": "Forward"
        }
      },
      {
        "key": "FullScreen",
        "text": "FullScreen",
        "data": {
          "icon": "FullScreen"
        }
      },
      {
        "key": "Game",
        "text": "Game",
        "data": {
          "icon": "Game"
        }
      },
      {
        "key": "GlobalNavButton",
        "text": "GlobalNavButton",
        "data": {
          "icon": "GlobalNavButton"
        }
      },
      {
        "key": "Globe",
        "text": "Globe",
        "data": {
          "icon": "Globe"
        }
      },
      {
        "key": "GripperBarHorizontal",
        "text": "GripperBarHorizontal",
        "data": {
          "icon": "GripperBarHorizontal"
        }
      },
      {
        "key": "GripperBarVertical",
        "text": "GripperBarVertical",
        "data": {
          "icon": "GripperBarVertical"
        }
      },
      {
        "key": "GripperTool",
        "text": "GripperTool",
        "data": {
          "icon": "GripperTool"
        }
      },
      {
        "key": "HardDriveLock",
        "text": "HardDriveLock",
        "data": {
          "icon": "HardDriveLock"
        }
      },
    {
        "key": "Highlight",
        "text": "Highlight",
        "data": {
          "icon": "Highlight"
        }
      },
      {
        "key": "Home",
        "text": "Home",
        "data": {
          "icon": "Home"
        }
      },
      {
        "key": "HorizontalTabKey",
        "text": "HorizontalTabKey",
        "data": {
          "icon": "HorizontalTabKey"
        }
      },
      {
        "key": "IncomingCall",
        "text": "IncomingCall",
        "data": {
          "icon": "IncomingCall"
        }
      },
      {
        "key": "InkingTool",
        "text": "InkingTool",
        "data": {
          "icon": "InkingTool"
        }
      },
      {
        "key": "Installation",
        "text": "Installation",
        "data": {
          "icon": "Installation"
        }
      },
      {
        "key": "InternetSharing",
        "text": "InternetSharing",
        "data": {
          "icon": "InternetSharing"
        }
      },
      {
        "key": "KeyboardClassic",
        "text": "KeyboardClassic",
        "data": {
          "icon": "KeyboardClassic"
        }
      },
      {
        "key": "LaptopSecure",
        "text": "LaptopSecure",
        "data": {
          "icon": "LaptopSecure"
        }
      },
    {
        "key": "Light",
        "text": "Light",
        "data": {
          "icon": "Light"
        }
      },
      {
        "key": "Link",
        "text": "Link",
        "data": {
          "icon": "Link"
        }
      },
      {
        "key": "LocationCircle",
        "text": "LocationCircle",
        "data": {
          "icon": "LocationCircle"
        }
      },
      {
        "key": "Lock",
        "text": "Lock",
        "data": {
          "icon": "Lock"
        }
      },
      {
        "key": "Mail",
        "text": "Mail",
        "data": {
          "icon": "Mail"
        }
      },
      {
        "key": "MapPin",
        "text": "MapPin",
        "data": {
          "icon": "MapPin"
        }
      },
      {
        "key": "Megaphone",
        "text": "Megaphone",
        "data": {
          "icon": "Megaphone"
        }
      },
      {
        "key": "Memo",
        "text": "Memo",
        "data": {
          "icon": "Memo"
        }
      },
      {
        "key": "Merge",
        "text": "Merge",
        "data": {
          "icon": "Merge"
        }
      },
      {
        "key": "Microphone",
        "text": "Microphone",
        "data": {
          "icon": "Microphone"
        }
      },
      {
        "key": "MiniLink",
        "text": "MiniLink",
        "data": {
          "icon": "MiniLink"
        }
      },
      {
        "key": "More",
        "text": "More",
        "data": {
          "icon": "More"
        }
      },
      {
        "key": "Move",
        "text": "Move",
        "data": {
          "icon": "Move"
        }
      },
      {
        "key": "MultiSelect",
        "text": "MultiSelect",
        "data": {
          "icon": "MultiSelect"
        }
      },
      {
        "key": "Nav2DMapView",
        "text": "Nav2DMapView",
        "data": {
          "icon": "Nav2DMapView"
        }
      },
      {
        "key": "OEM",
        "text": "OEM",
        "data": {
          "icon": "OEM"
        }
      },
    {
        "key": "OneDriveFolder16",
        "text": "OneDriveFolder16",
        "data": {
          "icon": "OneDriveFolder16"
        }
      },
    {
        "key": "Org",
        "text": "Org",
        "data": {
          "icon": "Org"
        }
      },
    {
        "key": "Package",
        "text": "Package",
        "data": {
          "icon": "Package"
        }
      },
      {
        "key": "Page",
        "text": "Page",
        "data": {
          "icon": "Page"
        }
      },
      {
        "key": "PageData",
        "text": "PageData",
        "data": {
          "icon": "PageData"
        }
      },
      {
        "key": "PageHeaderEdit",
        "text": "PageHeaderEdit",
        "data": {
          "icon": "PageHeaderEdit"
        }
      },
      {
        "key": "PageLeft",
        "text": "PageLeft",
        "data": {
          "icon": "PageLeft"
        }
      },
      {
        "key": "PageLink",
        "text": "PageLink",
        "data": {
          "icon": "PageLink"
        }
      },
      {
        "key": "PageRight",
        "text": "PageRight",
        "data": {
          "icon": "PageRight"
        }
      },
      {
        "key": "PageSolid",
        "text": "PageSolid",
        "data": {
          "icon": "PageSolid"
        }
      },
      {
        "key": "PanoIndicator",
        "text": "PanoIndicator",
        "data": {
          "icon": "PanoIndicator"
        }
      },
      {
        "key": "Paste",
        "text": "Paste",
        "data": {
          "icon": "Paste"
        }
      },
      {
        "key": "Pause",
        "text": "Pause",
        "data": {
          "icon": "Pause"
        }
      },
      {
        "key": "People",
        "text": "People",
        "data": {
          "icon": "People"
        }
      },
      {
        "key": "Personalize",
        "text": "Personalize",
        "data": {
          "icon": "Personalize"
        }
      },
      {
        "key": "Phone",
        "text": "Phone",
        "data": {
          "icon": "Phone"
        }
      },
      {
        "key": "PhotoCollection",
        "text": "PhotoCollection",
        "data": {
          "icon": "PhotoCollection"
        }
      },
      {
        "key": "Pin",
        "text": "Pin",
        "data": {
          "icon": "Pin"
        }
      },
      {
        "key": "PinSolidOff12",
        "text": "PinSolidOff12",
        "data": {
          "icon": "PinSolidOff12"
        }
      },
      {
        "key": "Play",
        "text": "Play",
        "data": {
          "icon": "Play"
        }
      },
      {
        "key": "PowerButton",
        "text": "PowerButton",
        "data": {
          "icon": "PowerButton"
        }
      },
      {
        "key": "Print",
        "text": "Print",
        "data": {
          "icon": "Print"
        }
      },
      {
        "key": "ProductList",
        "text": "ProductList",
        "data": {
          "icon": "ProductList"
        }
      },
    {
        "key": "ProtectedDocument",
        "text": "ProtectedDocument",
        "data": {
          "icon": "ProtectedDocument"
        }
      },
      {
        "key": "QuickNote",
        "text": "QuickNote",
        "data": {
          "icon": "QuickNote"
        }
      },
      {
        "key": "ReadingMode",
        "text": "ReadingMode",
        "data": {
          "icon": "ReadingMode"
        }
      },
      {
        "key": "RealEstate",
        "text": "RealEstate",
        "data": {
          "icon": "RealEstate"
        }
      },
      {
        "key": "RedEye",
        "text": "RedEye",
        "data": {
          "icon": "RedEye"
        }
      },
      {
        "key": "Redo",
        "text": "Redo",
        "data": {
          "icon": "Redo"
        }
      },
      {
        "key": "Remove",
        "text": "Remove",
        "data": {
          "icon": "Remove"
        }
      },
      {
        "key": "RepeatAll",
        "text": "RepeatAll",
        "data": {
          "icon": "RepeatAll"
        }
      },
    {
        "key": "ReportHacked",
        "text": "ReportHacked",
        "data": {
          "icon": "ReportHacked"
        }
      },
      {
        "key": "ReturnKey",
        "text": "ReturnKey",
        "data": {
          "icon": "ReturnKey"
        }
      },
      {
        "key": "Sad",
        "text": "Sad",
        "data": {
          "icon": "Sad"
        }
      },
      {
        "key": "Save",
        "text": "Save",
        "data": {
          "icon": "Save"
        }
      },
      {
        "key": "SaveAs",
        "text": "SaveAs",
        "data": {
          "icon": "SaveAs"
        }
      },
      {
        "key": "SchoolDataSyncLogo",
        "text": "SchoolDataSyncLogo",
        "data": {
          "icon": "SchoolDataSyncLogo"
        }
      },
      {
        "key": "Search",
        "text": "Search",
        "data": {
          "icon": "Search"
        }
      },
      {
        "key": "SearchAndApps",
        "text": "SearchAndApps",
        "data": {
          "icon": "SearchAndApps"
        }
      },
      {
        "key": "SeeDo",
        "text": "SeeDo",
        "data": {
          "icon": "SeeDo"
        }
      },
      {
        "key": "Send",
        "text": "Send",
        "data": {
          "icon": "Send"
        }
      },
      {
        "key": "Settings",
        "text": "Settings",
        "data": {
          "icon": "Settings"
        }
      },
      {
        "key": "Share",
        "text": "Share",
        "data": {
          "icon": "Share"
        }
      },
      {
        "key": "SharepointAppIcon16",
        "text": "SharepointAppIcon16",
        "data": {
          "icon": "SharepointAppIcon16"
        }
      },
      {
        "key": "Shop",
        "text": "Shop",
        "data": {
          "icon": "Shop"
        }
      },
      {
        "key": "ShoppingCart",
        "text": "ShoppingCart",
        "data": {
          "icon": "ShoppingCart"
        }
      },
      {
        "key": "SIPMove",
        "text": "SIPMove",
        "data": {
          "icon": "SIPMove"
        }
      },
      {
        "key": "Slideshow",
        "text": "Slideshow",
        "data": {
          "icon": "Slideshow"
        }
      },
      {
        "key": "Speakers",
        "text": "Speakers",
        "data": {
          "icon": "Speakers"
        }
      },
      {
        "key": "StackIndicator",
        "text": "StackIndicator",
        "data": {
          "icon": "StackIndicator"
        }
      },
      {
        "key": "Stop",
        "text": "Stop",
        "data": {
          "icon": "Stop"
        }
      },
      {
        "key": "StreetsideSplitMinimize",
        "text": "StreetsideSplitMinimize",
        "data": {
          "icon": "StreetsideSplitMinimize"
        }
      },
      {
        "key": "System",
        "text": "System",
        "data": {
          "icon": "System"
        }
      },
      {
        "key": "Tab",
        "text": "Tab",
        "data": {
          "icon": "Tab"
        }
      },
      {
        "key": "Tablet",
        "text": "Tablet",
        "data": {
          "icon": "Tablet"
        }
      },
    {
        "key": "Tag",
        "text": "Tag",
        "data": {
          "icon": "Tag"
        }
      },
      {
        "key": "Telemarketer",
        "text": "Telemarketer",
        "data": {
          "icon": "Telemarketer"
        }
      },
      {
        "key": "ThumbnailView",
        "text": "ThumbnailView",
        "data": {
          "icon": "ThumbnailView"
        }
      },
    {
        "key": "Tiles",
        "text": "Tiles",
        "data": {
          "icon": "Tiles"
        }
      },
      {
        "key": "TimePicker",
        "text": "TimePicker",
        "data": {
          "icon": "TimePicker"
        }
      },
      {
        "key": "TouchPointer",
        "text": "TouchPointer",
        "data": {
          "icon": "TouchPointer"
        }
      },
      {
        "key": "Train",
        "text": "Train",
        "data": {
          "icon": "Train"
        }
      },
      {
        "key": "Translate",
        "text": "Translate",
        "data": {
          "icon": "Translate"
        }
      },
      {
        "key": "Trim",
        "text": "Trim",
        "data": {
          "icon": "Trim"
        }
      },
      {
        "key": "TurnRight",
        "text": "TurnRight",
        "data": {
          "icon": "TurnRight"
        }
      },
      {
        "key": "TVMonitor",
        "text": "TVMonitor",
        "data": {
          "icon": "TVMonitor"
        }
      },
      {
        "key": "Undo",
        "text": "Undo",
        "data": {
          "icon": "Undo"
        }
      },
      {
        "key": "Unlock",
        "text": "Unlock",
        "data": {
          "icon": "Unlock"
        }
      },
      {
        "key": "Unpin",
        "text": "Unpin",
        "data": {
          "icon": "Unpin"
        }
      },
      {
        "key": "UnpublishContent",
        "text": "UnpublishContent",
        "data": {
          "icon": "UnpublishContent"
        }
      },
      {
        "key": "UnstackSelected",
        "text": "UnstackSelected",
        "data": {
          "icon": "UnstackSelected"
        }
      },
      {
        "key": "Up",
        "text": "Up",
        "data": {
          "icon": "Up"
        }
      },
      {
        "key": "UserWarning",
        "text": "UserWarning",
        "data": {
          "icon": "UserWarning"
        }
      },
      {
        "key": "Video",
        "text": "Video",
        "data": {
          "icon": "Video"
        }
      },
      {
        "key": "ViewOriginal",
        "text": "ViewOriginal",
        "data": {
          "icon": "ViewOriginal"
        }
      },
      {
        "key": "Warning",
        "text": "Warning",
        "data": {
          "icon": "Warning"
        }
      },
      {
        "key": "WebAppBuilderFragmentCreate",
        "text": "WebAppBuilderFragmentCreate",
        "data": {
          "icon": "WebAppBuilderFragmentCreate"
        }
      },
      {
        "key": "WebAppBuilderModule",
        "text": "WebAppBuilderModule",
        "data": {
          "icon": "WebAppBuilderModule"
        }
      },
      {
        "key": "WebAppBuilderSlot",
        "text": "WebAppBuilderSlot",
        "data": {
          "icon": "WebAppBuilderSlot"
        }
      },
      {
        "key": "WindowsLogo",
        "text": "WindowsLogo",
        "data": {
          "icon": "WindowsLogo"
        }
      },
      {
        "key": "Zoom",
        "text": "Zoom",
        "data": {
          "icon": "Zoom"
        }
      },
      {
        "key": "ZoomOut",
        "text": "ZoomOut",
        "data": {
          "icon": "ZoomOut"
        }
      }
    ];
    //const imageSorting = this.iconOptions.sort((a, b) => (a.text > b.text ? 1 : -1))
    //console.log("Sorting",imageSorting)

    return (
      <div className="customPanel">
        <Panel
          isOpen={this.props.panelOpen}
          onDismiss={this.props.onDismiss.bind(this)}
          headerText="Tegel bewerken\aanmaken"
          closeButtonAriaLabel="Close"
          aria-multiselectable={false}
          onRenderFooterContent={this.closePanel}
          isFooterAtBottom={true}
          type={PanelType.medium}
        >
          <Stack tokens={stackTokens}>
            <Dropdown
              onChange={this.onChangeOrganisation}
              options={this.props.selectableOrganisations}
              name="organisatie"
              label="Organisatie"
              placeholder={"Selecteer een organisatie"}
              selectedKey={this.props.selectedOrganisation != null ? this.props.selectedOrganisation : this.state.valueSelectedOrganisation}
              required
              disabled={this.props.selectedOrganisation != null}
            />
            { ((this.props.selectedOrganisation != null ? this.props.selectedOrganisation : this.state.valueSelectedOrganisation) > 0 ) && (
            <React.Fragment>
              <TextField
                label="Titel"
                name="text"
                required
                onChange={this.onChangeTitle}
                value={this.state.valueTitle}
                validateOnFocusOut
                //errorMessage={this.state.errorMessage}
              />
            
              <Dropdown
                onChange={this.onChangeSection}
                options={this.state.valueAllSelectionSection}
                name="Section"
                label="Sectie"
                placeholder={"Selecteer een tegel sectie"}
                selectedKey={this.state.valueSectionID}
                required
              />

              <Dropdown
                onChange={this.onChangePage}
                options={this.state.selectablePages}
                name="pageID"
                label="Pagina"
                placeholder="Selecteer een pagina"
                selectedKey={this.state.valueSelectedPageID}
              />

              <Dropdown
                onChange={this.onChangeIcon}
                options={this.iconOptions}
                label="Icon"
                placeholder={"Selecteer een icon"}
                required
                onRenderTitle={this.onRenderTitle}
                onRenderOption={this.onRenderOption}
                selectedKey={this.state.valueIcon}
              />
              <TextField
                label="Sequence"
                name="sequence"
                required
                onChange={this.onChangeSequence}
                value={this.state.valueSequence}
                validateOnFocusOut
                //errorMessage={this.state.errorMessage}
              />
            </React.Fragment>
            )}
          </Stack>
        </Panel>
      </div>
    );
  };

  onChangeOrganisation = async (event, item) => {
    this.setState({ valueSelectedOrganisation: item.key });
    console.log("Selected organization", item.key);
    //await this.getAvailableSection(item.key);
    this.getAvailableSection(item.key);
    this.getPages(item.key);
  };

  onChangeTitle = (event, value) => {
    this.setState({ valueTitle: value });
  };

  onChangePage = (event, item) =>
  {
      this.setState({
          valueSelectedPageID: item.key,
      })
  }

  onChangeSequence = (event, value) => {
    this.setState({ valueSequence: value });
  };
  /*onChangePosition = (event, item) => {
    this.setState({ valuePosition: item.key });
  }*/
  onChangeSection = (event, item) => {
    console.log("Section", item);
    this.setState({ valueSectionID: item.key });
  };
  onChangeIcon = (event, value) => {
    console.log("Icon selected", value);
    this.setState({ valueIcon: value.text });
  };
  onRenderTitle = (options) => {
    const option = options[0];
    return (
      <div>
        {option.data && option.data.icon && (
          <Icon
            style={{ marginRight: "8px", color: option.data.colorName }}
            iconName={option.data.icon}
            aria-hidden="true"
            title={option.data.icon}
          />
        )}
        <span>{option.text}</span>
      </div>
    );
  };
  onRenderOption(option) {
    return (
      <div>
        {option.data && option.data.icon && (
          <Icon
            style={{ marginRight: "8px", color: option.data.colorName }}
            iconName={option.data.icon}
            aria-hidden="true"
            title={option.data.icon}
          />
        )}
        <span>{option.text}</span>
      </div>
    );
  }
}
