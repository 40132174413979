import React, { Component } from 'react';

import { Button, Checkbox, Dropdown, Form, Header, Label, List, Icon, Modal } from 'semantic-ui-react'

const rawMarkup = (text) => {
  text = text.replace(new RegExp('<p>', 'g'), '')
  text = text.replace(new RegExp('</p>', 'g'), '<br />')
  return { __html: text }
}

const checkType = [
  {key: '1', text: 'Checkbox', 'value': 1, disabled: true},
  {key: '2', text: 'Number', 'value': 2, disabled: true},
  {key: '3', text: 'Textbox', 'value': 3, disabled: true},
  {key: '4', text: 'DateTime', 'value': 4, disabled: true},
  {key: '5', text: 'DropDown', 'value': 5, disabled: true}
]
export default class InfoModal extends Component {
  state = { open: false }

  onOpen = (e) => {
    const { open } = this.state
    e.preventDefault();
    this.setState({ open: !open })
  }

  capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  render() {
    const { node } = this.props
    const keys = Object.keys(node)
    const { open } = this.state
    return(
    <Modal open={open} size='large' onClose={(e) => this.onOpen(e)} closeIcon closeOnDocumentClick={true} trigger={<Button onClick={(e) => this.onOpen(e)} circular icon='info' color='yellow' title='Meer informatie' size='mini'></Button>}>
      <Header>
        <Icon name='info' />
        <Header.Content>Over {node.title}</Header.Content>
      </Header>
      <Modal.Content>
        <Form size='tiny'>
        {
          keys.map((key, index) => {
            if (key === 'id' || key === 'noEdit' || key === 'noChildren' ||  key === 'subtitle' || key === 'noDragging' || key === 'type' || key === 'noAdd' || key === 'expanded' || key === 'children' || key === 'ParentID') {
              return null
            }
            else
            {
              if (typeof node[key] === 'string' || typeof node[key] === 'number') {
                if (key === 'information') {
                  return (<Form.Field key={index}>
                    <label>{this.capitalizeFirstLetter(key)}:</label>
                    <Label size='large'><div dangerouslySetInnerHTML={rawMarkup(node[key])}></div> </Label>
                  </Form.Field>)
                }
                else if (key === 'checktype') {
                  return (<Form.Field width='14' key={index}>
                    <label>{this.capitalizeFirstLetter(key)}:</label>
                    <Dropdown name={key} options={checkType} disabled selection onChange={this.editField} value={Number(node[key])}/>
                    {Number(node[key]) === 5 ? 
                    <>
                      
                      <List>
                        <List.Item>
                          <List.Header>Opties:</List.Header>
                          {node['checkchoices'] ? node['checkchoices'].map((choice, index) => {
                            return(
                              <label key={index}>
                                {choice.value}<br />
                              </label>)
                          }): '[none]'}
                        </List.Item>
                        
                      </List>
                    </>
                     : <div /> }
                  </Form.Field>)
                }
                else {
                  return (<Form.Field key={index}>
                    <label>{this.capitalizeFirstLetter(key)}:</label>
                    <Label size='large'>{node[key]}</Label>
                  </Form.Field>)
                }
              }
              else if (typeof node[key] == 'boolean') {
                return (<Form.Field key={index}>
                  <label>{this.capitalizeFirstLetter(key)}:</label>
                  <Checkbox size='large' disabled checked={node[key]} ></Checkbox>
                </Form.Field>)
              }
            }
          }
        )}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button negative as='a' onClick={(e) => this.onOpen(e)}><Icon name='close' /> Sluiten</Button>
      </Modal.Actions>
    </Modal>
    )
  }
}