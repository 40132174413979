import React, { Component } from "react";
import {
  Checkbox,
  DefaultButton,
  PrimaryButton,
  Stack,
} from "@fluentui/react";
import { Dropdown, TextField } from "@fluentui/react"
import { Panel, PanelType } from "@fluentui/react";
import { ProcessPage } from "../../Services/Entities/ProcessPage";
import { PageService } from "../../Services/PageService";
import { PageTemplate1 } from "./PageTemplate1";
import { PageTemplate3 } from "./PageTemplate3";
import RichTextEditor from "react-rte";

export class EditProcessPagePanel extends Component {


    constructor(props) {
        super(props);

        this.state = this.GetDefaultState();
    };

    GetDefaultState = () => {
        return {
            valueItemId: null,
            valueSelectedOrganisation: null,
            valueSelectedPageTemplate: null,
            valueSelectedPageTemplateText: null,

            valueSelectedPageTitle: null,
            valueSelectedPageTitleText: null,

            valueProcessOwner: null,
            valueProcessResponsible: null,
            selectableProcessTitles: [],

            validationMessageEmail: "",

            valueProcessExplanation: RichTextEditor.createEmptyValue(),
            
            valueProcessPurpose: RichTextEditor.createEmptyValue(),
            valueProcessDescription: RichTextEditor.createEmptyValue(),
            valueProcessMonitoring: RichTextEditor.createEmptyValue(),

            valueVisioFileName: null,
            valueVisioFile: null,

            isUploadSuccess: false,
            uploadSuccessMessage: "",
            hasFileMessage: false,
            fileMessage: ""
        };
    }

    clearItemState = () => {
        this.setState(this.GetDefaultState());
    }

    onSave = () => {
        var returnedItem = new ProcessPage();
        returnedItem.id = this.state.valueItemId;
        var selectedCustomerId = this.props.selectedOrganisation != null ? this.props.selectedOrganisation : this.state.valueSelectedOrganisation;
        var selectedCustomerItem = this.props.selectableOrganisations.find(x => x.key == selectedCustomerId);
        returnedItem.klantid = selectedCustomerId;
        returnedItem.klantnaam = selectedCustomerItem.text;
        returnedItem.paginaurl = selectedCustomerItem.site;

        returnedItem.paginatitle = this.state.valueSelectedPageTitle;
        returnedItem.template = this.state.valueSelectedPageTemplateText;
        returnedItem.procesdoel = this.state.valueProcessPurpose.toString("html");
        returnedItem.procesbescrijving = this.state.valueProcessDescription.toString("html");
        returnedItem.procesbewaking = this.state.valueProcessMonitoring.toString("html");
        returnedItem.processuitleg = this.state.valueProcessExplanation.toString("html");
        returnedItem.eigenaar = this.state.valueProcessOwner;
        returnedItem.verantwoordelijken = this.state.valueProcessResponsible;
        returnedItem.filename = this.state.valueVisioFileName;

        console.log(returnedItem);
        this.props.OnPanelSave(returnedItem);
        this.clearItemState();
    }

    onDismiss = () => {
        this.clearItemState();
        this.props.onDismiss();
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.itemToEdit && prevProps.itemToEdit !== this.props.itemToEdit)
        {
            if (this.props.itemToEdit.id == null)
            {
                this.clearItemState();
                return;
            }
            else
            {
                console.log(this.props.itemToEdit);
                PageService.Instance.GetPageTitlesByCustomer(this.props.itemToEdit.klantid).then((selectablePageTitles) => {
                    var selectedTemplate =  PageService.Instance.GetSupportedPageTemplateOptions().find(x => x.text == this.props.itemToEdit.template);
                    this.setState({
                        selectableProcessTitles: selectablePageTitles,

                        valueItemId: this.props.itemToEdit.id,
                        valueSelectedOrganisation: this.props.itemToEdit.klantid,
                        valueSelectedPageTitle: parseInt(this.props.itemToEdit.procesId),
                        valueSelectedPageTemplateText: this.props.itemToEdit.template,
                        valueSelectedPageTemplate: selectedTemplate.key,
                        
                        valueProcessExplanation: RichTextEditor.createValueFromString(this.props.itemToEdit.procesuitleg, 'html'),
                        valueProcessPurpose: RichTextEditor.createValueFromString(this.props.itemToEdit.procesdoel, 'html'),
                        valueProcessDescription: RichTextEditor.createValueFromString(this.props.itemToEdit.procesbeschrijving, 'html'),
                        valueProcessMonitoring: RichTextEditor.createValueFromString(this.props.itemToEdit.procesbewaking, 'html'),
                        valueProcessOwner: this.props.itemToEdit.eigenaar,
                        valueProcessResponsible: this.props.itemToEdit.verantwoordelijken,
                        valueVisioFileName: this.props.itemToEdit.filename,
                    });
                });
            }
        }
    }

    getProcessen = async (klantId) => {
        PageService.Instance.GetPageTitlesByCustomer(klantId).then((selectablePageTitles) => {
            this.setState({ selectableProcessTitles: selectablePageTitles });
        });
    };

    closePanel = () => {
        return (
        <div>
            <PrimaryButton className="submitButton" onClick={this.onSave.bind(this)}>
                Opslaan
            </PrimaryButton>
            <DefaultButton className="submitButton" onClick={this.onDismiss.bind(this)}>
                Annuleer
            </DefaultButton>
        </div>
        );
    };

    render = () => {
        const stackTokens = { childrenGap: 10 };
        const pageTemplateOptions = PageService.Instance.GetSupportedPageTemplateOptions()

        const useTemplate1 = (this.state.valueSelectedPageTemplateText === "Template 1 - Klantreis en flow"
                                || this.state.valueSelectedPageTemplateText === "Template 2 - Flow"
                                || this.state.valueSelectedPageTemplateText === "Template 5 - Flow en toelichting" 
                                || this.state.valueSelectedPageTemplateText === "Template 6 - Klantries, flow en toelichting");
        const useTemplate3 = (this.state.valueSelectedPageTemplateText === "Template 3 - Klantreis en tekst" 
                                || this.state.valueSelectedPageTemplateText === "Template 4 - Tekst" );

        return (
            <div className="customPanel">
                <Panel
                    isOpen={this.props.panelOpen}
                    onDismiss={this.props.onDismiss.bind(this)}
                    headerText="Procespagina bewerken\aanmaken"
                    closeButtonAriaLabel="Close"
                    aria-multiselectable={false}
                    onRenderFooterContent={this.closePanel}
                    isFooterAtBottom={true}
                    type={PanelType.medium}
                >
                    <Stack tokens={stackTokens} >
                        <Dropdown
                            onChange={this.onChangeOrganisation}
                            options={this.props.selectableOrganisations}
                            name="organisatie"
                            label="Organisatie"
                            placeholder={"Selecteer een organisatie"}
                            selectedKey={this.props.selectedOrganisation != null ? this.props.selectedOrganisation : this.state.valueSelectedOrganisation}
                            required
                            disabled={this.props.selectedOrganisation != null}
                            />
                        <Dropdown
                            onChange={this.onChangePageTemplate}
                            options={pageTemplateOptions}
                            name="template"
                            label="Processen template"
                            placeholder="
                            Selecteer een 
                            template"
                            selectedKey={this.state.valueSelectedPageTemplate}
                            required
                            />
                        { ((this.props.selectedOrganisation != null ? this.props.selectedOrganisation : this.state.valueSelectedOrganisation) > 0 ) && (
                        <React.Fragment>
                            <Dropdown
                                onChange={this.onChangePageTitle}
                                options={this.state.selectableProcessTitles}
                                name="paginatitle"
                                label="Pagina titel"
                                placeholder="Selecteer een pagina title"
                                selectedKey={this.state.valueSelectedPageTitle}
                                required
                            />
                            <TextField
                                name="eigenaar"
                                onChange={this.onChangeProcessOwner}
                                placeholder="Eigenaar email adres"
                                required
                                label="Proces eigenaar"
                                value={this.state.valueProcessOwner}
                                errorMessage={this.state.validationMessageEmail}
                                />
                            <TextField
                                name="verantwoordelijke(n)"
                                onChange={this.onChangeProcessResponsible}
                                placeholder="Verantwoordelijke(n) email adres"
                                required
                                label="Proces Verantwoordelijke(n)"
                                value={this.state.valueProcessResponsible}
                                />
                            {useTemplate1 && (
                                <PageTemplate1 
                                    onChangeFileUpload={this.onChangeFileUpload} 
                                    onSharePointFileUpload={this.onSharePointFileUpload} 
                                    isUploadSuccess={this.state.isUploadSuccess} 
                                    uploadSuccessMessage={this.state.uploadSuccessMessage}
                                    valueVisioFile={this.state.valueVisioFile}
                                    valueVisioFileName={this.state.valueVisioFileName}
                                    valueProcessExplanation={this.state.valueProcessExplanation}
                                    onChangeProcessExplanation={this.onChangeProcessExplanation}
                                />
                            )}
                            {useTemplate3 && (
                                <PageTemplate3 
                                    onChangeFileUpload={this.onChangeFileUpload} 
                                    onSharePointFileUpload={this.onSharePointFileUpload} 
                                    isUploadSuccess={this.state.isUploadSuccess} 
                                    uploadSuccessMessage={this.state.uploadSuccessMessage}
                                    valueVisioFile={this.state.valueVisioFile}
                                    valueVisioFileName={this.state.valueVisioFileName}
                                    valueProcessPurpose={this.state.valueProcessPurpose}
                                    valueProcessDescription={this.state.valueProcessDescription}
                                    valueProcessMonitoring={this.state.valueProcessMonitoring}
                                    onChangeProcessPurpose={this.onChangeProcessPurpose}
                                    onChangeProcessDescription={this.onChangeProcessDescription}
                                    onChangeProcessMonitoring={this.onChangeProcessMonitoring}
                                />
                            )}
                        </React.Fragment>
                        )}
                    </Stack>
                    
                </Panel>
            </div>
        );
    };

    onChangeOrganisation = (event, item) =>
    {
        this.setState({ valueSelectedOrganisation: item.key })
        this.getProcessen(item.key);
    }

    onChangePageTemplate = (event, item) =>
    {
        this.setState({
            valueSelectedPageTemplate: item.key,
            valueSelectedPageTemplateText: item.text
        })
    }

    onChangePageTitle = (event, item) =>
    {
        this.setState({
            valueSelectedPageTitle: item.key,
            valueSelectedPageTitleText: item.text
        })
    }

    onChangeProcessOwner = (event, item) =>
    {
        const emailRegex =
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line
        if (!emailRegex.test(item)) {
          const errMsg = "Het email adres voldoet niet aan de eisen";
          this.setState({ valueProcessOwner: item, validationMessageEmail: errMsg });
        } else {
          this.setState({ valueProcessOwner: item, validationMessageEmail: "" });
        }
    }

    onChangeProcessResponsible = (event, item) =>
    {
        this.setState({ valueProcessResponsible: item});
    }
    

    onChangeProcessExplanation = (value) => {
        this.setState({ valueProcessExplanation: value });
    };

    onChangeProcessPurpose = (value) => {
        this.setState({ valueProcessPurpose: value });
    };
    onChangeProcessDescription = (value) => {
        this.setState({ valueProcessDescription: value });
    };
    onChangeProcessMonitoring = (value) => {
        this.setState({ valueProcessMonitoring: value });
    };

    onChangeFileUpload = (event) => {
        const file = event.target.files;
        const allowedFileTypes = [
          "image/png",
          "image/jpeg",
          "application/vnd.visio",
          "application/vnd.ms-visio.drawing",
          "application/vnd.ms-visio.viewer",
        ];
        const allowedExtensions = [
            "vsd",
            "vsdx",
        ];
        for (var x = 0; x < file.length; x++) {
          var noAllowedFileTypeUsed = allowedFileTypes.every((type) => file[x].type !== type);
          var noAllowedExtensionUsed = allowedExtensions.every((extension) => file[x].name.split('.').pop() !== extension);
          if (noAllowedFileTypeUsed && noAllowedExtensionUsed) {
            // invalid file type, set error message
            this.setState({
              fileMessage: file[x].name + " is not a supported format\n",
              hasFileMessage: true,
              valueVisioFile: null,
              valueVisioFileName: null
            });
          } else {
            this.setState({
                fileMessage: "",
                hasFileMessage: false,
                valueVisioFile: file[0],
                valueVisioFileName: file[0].name,
            });
          }
        }
    };

    onSharePointFileUpload =  () => {
        if (this.state.valueVisioFile) {
            PageService.Instance.SaveFile(this.state.valueVisioFile).then((data) => {

                this.setState({
                    valueVisioFileName: data.filename,
                    isUploadSuccess: true,
                    uploadSuccessMessage: "Het document is succesvol geüpload",
                });
            });
        }
    }; 
}