import React, { Component } from "react";
import {
  DefaultButton,
  PrimaryButton,
} from "office-ui-fabric-react/lib/Button";
import { Panel } from "office-ui-fabric-react/lib/Panel";
import { EditGebruikers } from "./editGebruikers";
import "./style.css"

export class GebruikersPanel extends Component {
  closePanel = () => {
    return (
      <div>
        <PrimaryButton className="submitButton" onClick={this.props.onPanelSave.bind(this)}>
          Opslaan
        </PrimaryButton>
        <DefaultButton className="submitButton" onClick={this.props.onDismiss.bind(this)}>
          Annuleer
        </DefaultButton>
      </div>
    );
  };

  render = () => {
    return (
      <div className="customPanel">
        <Panel
          isOpen={this.props.panelOpen}
          onDismiss={this.props.onDismiss.bind(this)}
          headerText={this.props.panelHeader}
          closeButtonAriaLabel="Close"
          aria-multiselectable={false}
          onRenderFooterContent={this.closePanel}
          isFooterAtBottom={true}
        >
          <EditGebruikers
            Naam={this.props.Naam}
            Email={this.props.Email}
            Organisatie={this.props.Organisatie}
            Rechten={this.props.Rechten}
            RechtenSelectie={this.props.RechtenSelectie}
            onChange={this.props.onChange}
            className="customPanel"
            comboboxChange={this.props.comboboxChange}
            organisatieSelectie={this.props.organisatieSelectie}
            ToggleDropdown={this.props.ToggleDropdown}
            ToggleRechtenDropdown={this.props.ToggleRechtenDropdown}
          ></EditGebruikers>
        </Panel>
      </div>
    );
  };
}
