// export const config = {
//     clientId: '16e444bc-a628-40ac-b46f-aec88829e2ea',
//     redirectUri: 'http://localhost:5001',
//     scopes:[
//         'user.read'
//     ]
// }
module.exports ={
    appId: '16e444bc-a628-40ac-b46f-aec88829e2ea',
    scopes:[
        'user.read'
    ]
}