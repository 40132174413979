export class TilesService {

    static instance;

    static get Instance() {

        if(this.instance == null) {
            this.instance = new TilesService();
        }

        return this.instance;
    }
    
    GetAll() {
        return new Promise((resolve, reject) => {
            fetch("/api/tiles/all")
            .then((response) => response.json())
            .then((data) => {
                console.log("Get All Titles",data)
                resolve(data);
            })
            .catch((error) => {
                console.log("Get All Titles Error",error)
                reject(error);
            });
        });
    }

    Save(tileItem)
    {
        // POST for add, PUT for update
        var restMethod = tileItem.tileID == null ? "POST" : "PUT";
        return new Promise((resolve, reject) => {
            fetch("/api/tiles", {
                method: restMethod,
                headers: {
                "Content-Type": "application/json",
                },
                body: JSON.stringify(tileItem),
            })
            .then((response) => response.json())
            .then((data) => {
                console.log("Success:", data);
                resolve(true);            
            })
            .catch((error) => {
                console.error("Error:", error);
                reject(error);
            });
        });
    }

    GetTilesByCustomer(customerFilter)
    {
        var url = "/api/tiles/tilesByKlant/" + customerFilter;
        return new Promise((resolve, reject) => {
            fetch(url)
            .then((response) => response.json())
            .then((data) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
        });
    }

    GetAllSections() {
        return new Promise((resolve, reject) => {
            fetch("/api/tilesSection/all")
            .then((response) => response.json())
            .then((data) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
        });
    }

    GetSectionsByCustomer(customerFilter)
    {
        var url = "/api/tilesSection/customerSection/" + customerFilter;
        return new Promise((resolve, reject) => {
            fetch(url)
            .then((response) => response.json())
            .then((data) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
        });
    }

    SaveSection(sectionItem)
    {
        // POST for add, PUT for update
        var restMethod = sectionItem.sectionID == null ? "POST" : "PUT";
        return new Promise((resolve, reject) => {
            fetch("/api/tilesSection", {
                method: restMethod,
                headers: {
                "Content-Type": "application/json",
                },
                body: JSON.stringify(sectionItem),
            })
            .then((response) => response.json())
            .then((data) => {
                console.log("Success:", data);
                resolve(true);            
            })
            .catch((error) => {
                console.error("Error:", error);
                reject(error);
            });
        });
    }

    SaveTiles(sectionItem)
    {
        // POST for add, PUT for update
        var restMethod = sectionItem.tileID == null ? "POST" : "PUT";
        console.log("SaveTile API",restMethod)
        // var restMethod = "POST"
        return new Promise((resolve, reject) => {
            fetch("/api/tiles", {
                method: restMethod,
                headers: {
                "Content-Type": "application/json",
                },
                body: JSON.stringify(sectionItem),
            })
            .then((response) => response.json())
            .then((data) => {
                console.log("Success:", data);
                resolve(true);            
            })
            .catch((error) => {
                console.error("Error:", error);
                reject(error);
            });
        });
    }
}