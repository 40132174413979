import * as React from "react";
import {
  CommandBar,
} from "office-ui-fabric-react/lib/CommandBar";
import "./style.css"
export class CommandBarControl extends React.Component {
  render() {
    const _items = [
      {
        key: "Edit",
        text: "Item bewerken",
        iconProps: { iconName: "Edit" },
        onClick: this.props.EditItem
      },
    ];
    return (
      <div>
        <div>
        <CommandBar
          items={_items}
          // onClick={this.props.EditItem}
          className="editButton"
          ariaLabel="Use left and right arrow keys to navigate between commands"
        />
        </div>
      </div>
    );
  }
}
