import React, { Component } from "react";
import "./styles.css";
export class StartPagina extends Component {
  render() {
    return (
      <div className="gridWrapper">
        {this.props.AuthUsers.toLowerCase()===this.props.authEmail.toLowerCase() ? (
          <div>
            <h1>Startpagina</h1>
            <p>Welkom in het RMS365 beheercentrum</p>
            <p>
              RMS365 is ons cloudbased platform voor het managen en beheren van
              certificeringen, richtlijnen en processen. Het combineert
              Kwaliteitsmanagement, Risicomanagement en Informatiemanagement.
              Alles slim geïntegreerd. Ons platform is modulair opgebouwd,
              waardoor je het gemakkelijk kunt inrichten voor elk type
              organisatie. RMS365 is af te stemmen op de processen en behoefte
              van jouw klant. Laat de workflows, de automatische notificaties en
              het overzichtelijke taakbeheer, voor je werken zodat er niets
              blijft liggen en je altijd voorbereid bent op een audit.
            </p>
            <p>
              Het RMS365 beheercentrum kun je gebruiken voor het instellen van
              specifieke instellingen voor een organisatie. In het beheercentrum
              kun je de processen zowel operationele als ondersteunende
              processen toevoegen en aanpassen. Ben je hier voor het eerst?
              Raadpleeg dan de RMS365 startup gids en volg de stappen.
            </p>
          </div>
        ) : (
          <p className="noOpenListButton">
            U heeft geen toegang tot dit scherm
          </p>
        )}
      </div>
    );
  }
}
