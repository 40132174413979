export class UserService {

    static instance;

    static get Instance() {

        if(this.instance == null) {
            this.instance = new UserService();
        }

        return this.instance;
    }

    GetAll(customerFilter) {
        var url = "/api/gebruikers/all";
        if (customerFilter && customerFilter > 0)
        {
            url = "/api/gebruikers/" + customerFilter + "/all"
        }
        return new Promise((resolve, reject) => {
            fetch(url)
            .then((response) => response.json())
            .then((data) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
        });
    }
}