import React, {Component} from 'react'
import {Checkbox} from 'semantic-ui-react'

export default class CheckBoxes extends Component {

    render = () => {
        const { defaultChecked, data, clickEvent } = this.props
        return (
            <div>
            {data.map((norm, index) => (
                <div key={index}>
                    <Checkbox toggle key={norm.value} defaultChecked={defaultChecked.filter(e => e.value === norm.value).length > 0} value={norm.value} label={norm.text} onChange={clickEvent}/>
                </div>
            ))}
            </div>
        )
    }
}