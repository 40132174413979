import React, { Component } from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import "semantic-ui-css/semantic.min.css";
import Leftnavigation from "./Controls/LeftNavigation/leftnavigation";
import { StartPagina } from "./Pages/startPagina";
import { Instelligen } from "./Pages/Instelligen";
import { LicentieInfo } from "./Pages/licentieInfo";
import { AdminRMS } from "./Pages/adminRMS";
import { InstelligenRMS } from "./Pages/instellingenRMS";
import { OpmakenKlantries } from "./Pages/opmakenKlanteis";
import { ProcessInvullen } from "./Pages/processInvullen";
import { News } from "./Pages/news";
import { Branch } from "./Pages/branch";
import { ContextRisico } from "./Pages/contextAnalyse";
import { StakeholderAnalyse } from "./Pages/stakeholderAnalyse";
import { Wetgeving } from "./Pages/wetRegelgeving";
import { OverzichtContinu } from "./Pages/overzichtContinu";
import { RisicoAnalysis } from "./Pages/risicoAnalyse";
import { WelcomePage } from "./Pages/welcomePage";
import { Gebruikers } from "./Pages/gebruikers";
import { SoftwareInfo } from "./Pages/softwareInfo";
import { LicentieUitleg } from "./Pages/licentieUitleg";
import Topbar from "./Controls/TopBar/topbar";
import config from "./Helpers/config";
import { getUserDetails } from "./Helpers/GraphService";
import { UserAgentApplication } from "msal";
import { PersonaPanel } from "./Controls/Panel/personaPanel";
import Cookies from "js-cookie";
import { TooltipHost } from "office-ui-fabric-react";
import { TilesSection } from "./Pages/tilesSection";
import { TilePages } from "./Pages/tilePages";
import { TilesInvullen } from "./Pages/tilesInvullen";
import { Publication } from "./Pages/publication";
import { CustomerService } from "./Services/CustomerService";


export class Index extends Component {
  constructor(props) {
    super(props);

    // this.userAgentApplication = new UserAgentApplication({
    //   auth: {
    //     clientId: config.appId,
    //     redirectUri: "http://localhost:5001/auth/callback",
    //   },
    //   cache: {
    //     cacheLocation: "localStorage",
    //     storeAuthStateInCookie: true,
    //   },
    // });

    // var user = this.userAgentApplication.getAccount();
    this.state = {
      activeItem: "sitemap",
      visible: false,
      loginAccount: "",
      user: {},
      error: null,
      panelOpen: false,
      siteURL: "",
      superAdmin: undefined,
      admin: undefined,
      klantID: null,
      organisatie: null,
      superOrganisatie: null,
      gebruikerOrganisatie: null,
      gebruikerKlantID: null,
      authorizedUser:[],

      globalSelectableCustomers: [],
      selectableCustomers: [],
      selectedCustomer: null
    };
  }

  onChangeOrganisation = (event, item) =>
  {
      console.log(item);
      this.setState({ selectedCustomer: item.key == -1 ? null : item.key
      ,siteURL: item.site });
  }

  // getSiteURL = (id) => {
  //   fetch("/api/klant/organisatie/site/" + id)
  //     .then((response) => response.json())
  //     .then((data) => {
  //     console.log("Site URL" ,data)
  //     this.setState({siteURL:data})
  //      return data;
  //     });
  // };

  devtools() {
    // disable react-dev-tools for this project
    if (typeof window.__REACT_DEVTOOLS_GLOBAL_HOOK__ === "object") {
      for (let [key, value] of Object.entries(
        window.__REACT_DEVTOOLS_GLOBAL_HOOK__
      )) {
        window.__REACT_DEVTOOLS_GLOBAL_HOOK__[key] =
          typeof value == "function" ? () => {} : null;
      }
    }
  }

  getKlientId = (organisatie) => {
    fetch("/api/klant/organisatie/" + organisatie)
      .then((response) => response.json())
      .then((data) => {
        console.log("klantID", data);
        const klantId = data;
        this.setState({ klantID: klantId });
        // console.log("KlantID: ", data);
      })
      .catch((error) => {
        console.log("klantID", error);
      });
  };

  getSiteURL = (email) => {
    fetch("/api/klant/siteUrl")
      .then((response) => response.json())
      .then((data) => {
        console.log("SiteURL", data);
        const result = data;
        this.setState({
          siteURL: result.Site,
          gebruikerOrganisatie: result.Klantnaam,
          gebruikerKlantID: result.KlantID,
        });
        // console.log("KlantID: ", data);
      })
      .catch((error) => {
        console.log("Site URL error", error);
      });
  };

  getSuperAdmin = (email) => {
    fetch("/api/admin/superAdmin")
      .then((response) => response.json())
      .then((data) => {
        console.log("SuperAdmin", data);
        const result = data;
        this.setState({
          superAdmin: result.SuperAdmin,
          superOrganisatie: result.Klantnaam,
        });

        // console.log("KlantID: ", data);
      })
      .catch((error) => {
        console.log("Super Admin error", error);
      });
  };

  getAdmin = (email) => {
    fetch("/api/admin/admin")
      .then((response) => response.json())
      .then((data) => {
        console.log("Admin", data);
        const result = data;
        this.setState({
          admin: result.Admin,
          organisatie: result.Klantnaam,
        });

        // console.log("KlantID: ", data);
      })
      .catch((error) => {
        console.log("Admin error", error);
      });
  };

  handleItemClick = (e) => {
    e.preventDefault();
    const { visible } = this.state;
    this.setState({ visible: !visible });
  };

  getCustomers = () => {
    CustomerService.Instance.GetAll().then((customers) =>
    {
        // deep clone
        var globalSelectable = JSON.parse(JSON.stringify(customers));
        globalSelectable.unshift({key:-1, text:"[Alles]" });

        this.setState({
          selectableCustomers: customers,
          globalSelectableCustomers: globalSelectable
        });
    });
  };
  
  handleSideItemClick = (e, { name }) => {
    e.preventDefault();
    const { visible } = this.state;
    this.setState({ activeItem: name, visible: !visible });
  };

  getAuthUsers = ()=>{
    fetch("/api/gebruikers/AuthorizedUsers")
      .then((response) => response.json())
      .then((data) => {
       this.setState({authorizedUser:data})
       console.log("AUTH user",data)
      })
      .catch((error) => {
        console.error("Error fetching auth users:", error);
      });
  }

  componentDidMount = async () => {
    //Hide dev tools
    // this.devtools();
    //Grab Logged in user
    await this.grabUser();
    this.getAuthUsers();
    this.getCustomers();
    const email = await `${this.state.user.email}`;

    this.getSiteURL();
    this.getSuperAdmin();
    this.getAdmin();

    //this.getKlientId("ShareValue");
  };
  grabUser = () => {
    const user = Cookies.get("RMSUser");
    if (user) {
      user.replace(/\\/g, "");
      const newUSer = JSON.parse(user);
      this.setState({ user: newUSer });
    }
  };

  logout = () => {
    Cookies.remove("RMSUser");
    window.location.href = "/auth/signout";
  };

  login = () => {
    
    window.location.href = "/auth/signin";
  
  };

  onPanelDismiss = () => {
    this.setState({ panelOpen: false });
  };

  onPanelOpen = () => {
    this.setState({ panelOpen: true });
  };

  getUserProfile = async () => {
    try {
      var accessToken = await this.userAgentApplication.acquireTokenSilent({
        scopes: config.scopes,
      });
      if (accessToken) {
        //Get the user profile from Graph
        var user = await getUserDetails(accessToken);
        this.setState({
          user: {
            displayName: user.displayName,
            email: user.mail || user.userPrincipalName,
          },
          error: null,
        });
      }
    } catch (err) {
      console.log("Error", err);
    }
  };

  render() {
    const { visible, activeItem, email } = this.state;

    const routes = [
      {
        path: "/start",
        exact: true,
        main: () => <StartPagina authEmail={this.state.user.email} AuthUsers={this.state.authorizedUser.AdminEmail || this.state.authorizedUser.GebruikersEmail} />,
      },
      {
        path: "/",
        exact: true,
        main: () => <StartPagina authEmail={this.state.user.email} AuthUsers={this.state.authorizedUser.AdminEmail || this.state.authorizedUser.GebruikersEmail} />,
      },
      {
        path: "/instellingen",
        exact: true,
        main: () => <Instelligen />,
      },
      {
        path: "/LI",
        exact: true,
        main: () => <LicentieInfo superAdmin={this.state.superAdmin} />,
      },
      {
        path: "/AR",
        exact: true,
        main: () => <AdminRMS superAdmin={this.state.superAdmin} />,
      },
      {
        path: "/SI",
        exact: true,
        main: () => <SoftwareInfo />,
      },
      {
        path: "/LU",
        exact: true,
        main: () => <LicentieUitleg />,
      },
      {
        path: "/RMSI",
        exact: true,
        main: () => (
          <InstelligenRMS
            klantNaam={this.state.superOrganisatie}
            superAdmin={this.state.superAdmin}
          />
        ),
      },
      {
        path: "/OK",
        exact: true,
        main: () => (
          <OpmakenKlantries
            klantNaam={this.state.superOrganisatie}
            superAdmin={this.state.superAdmin}
          />
        ),
      },
      {
        path: "/PI",
        exact: true,
        main: () => (
          <ProcessInvullen
            siteURL={this.state.siteURL}
            klantId={this.state.gebruikerKlantID}
            organisatie={this.state.gebruikerOrganisatie}
            superAdmin={this.state.superAdmin} 
            klantNaam={this.state.superOrganisatie}

            selectableOrganisations={this.state.selectableCustomers}
            selectedOrganisation={this.state.selectedCustomer}
          />
        ),
      },
      {
        path: "/NEWS",
        exact: true,
        main: () => (
          <News
            siteURL={this.state.siteURL}
            klantId={this.state.gebruikerKlantID}
            organisatie={this.state.gebruikerOrganisatie}
            superAdmin={this.state.superAdmin} 
            klantNaam={this.state.superOrganisatie}

            selectableOrganisations={this.state.selectableCustomers}
            selectedOrganisation={this.state.selectedCustomer}
          />
        ),
      },
      {
        path: "/BRANCH",
        exact: true,
        main: () => (
          <Branch
            siteURL={this.state.siteURL}
            klantId={this.state.gebruikerKlantID}
            organisatie={this.state.gebruikerOrganisatie}
            superAdmin={this.state.superAdmin} 
            klantNaam={this.state.superOrganisatie}
          />
        ),
      },
      {
        path: "/SECTION",
        exact: true,
        main: () => (
          <TilesSection
            siteURL={this.state.siteURL}
            klantId={this.state.gebruikerKlantID}
            organisatie={this.state.gebruikerOrganisatie}
            superAdmin={this.state.superAdmin} 
            klantNaam={this.state.superOrganisatie}

            selectableOrganisations={this.state.selectableCustomers}
            selectedOrganisation={this.state.selectedCustomer}
          />
        ),
      },
      {
        path: "/tegelpagina",
        exact: true,
        main: () => (
          <TilePages
            siteURL={this.state.siteURL}
            klantId={this.state.gebruikerKlantID}
            organisatie={this.state.gebruikerOrganisatie}
            superAdmin={this.state.superAdmin} 
            klantNaam={this.state.superOrganisatie}

            selectableOrganisations={this.state.selectableCustomers}
            selectedOrganisation={this.state.selectedCustomer}
          />
        ),
      },        
      {
        path: "/tegelinvullen",
        exact: true,
        main: () => (
          <TilesInvullen
            siteURL={this.state.siteURL}
            klantId={this.state.gebruikerKlantID}
            organisatie={this.state.gebruikerOrganisatie}
            superAdmin={this.state.superAdmin} 
            klantNaam={this.state.superOrganisatie}

            selectableOrganisations={this.state.selectableCustomers}
            selectedOrganisation={this.state.selectedCustomer}
          />
        ),
      },
      {
        path: "/CA",
        exact: true,
        main: () => <ContextRisico siteURL={this.state.siteURL} />,
      },
      {
        path: "/SA",
        exact: true,
        main: () => <StakeholderAnalyse siteURL={this.state.siteURL} />,
      },
      {
        path: "/RA",
        exact: true,
        main: () => <RisicoAnalysis siteURL={this.state.siteURL} />,
      },
      {
        path: "/WR",
        exact: true,
        main: () => <Wetgeving siteURL={this.state.siteURL} />,
      },
      {
        path: "/OCV",
        exact: true,
        main: () => <OverzichtContinu siteURL={this.state.siteURL} />,
      },
      {
        path: "/GB",
        exact: true,
        main: () => (
          <Gebruikers 
            superAdmin={this.state.superAdmin} 
            admin={this.state.admin}
            klantNaam={this.state.organisatie}

            selectableOrganisations={this.state.selectableCustomers}
            selectedOrganisation={this.state.selectedCustomer}
          />
        ),
      },
      {
        path: "/Publish",
        exact: true,
        main: () => <Publication 
          superAdmin={this.state.superAdmin}
          admin={this.state.admin}
          klantNaam={this.state.organisatie} 
          
          selectableOrganisations={this.state.selectableCustomers}
          selectedOrganisation={this.state.selectedCustomer}
        />,
      },
    ];
    return (
      <>
        <div className="body">
          <PersonaPanel
            userEmail={this.state.user.email}
            signOut={this.logout}
            panelOpen={this.state.panelOpen}
            onDismiss={this.onPanelDismiss}
            panelHeader={this.state.user.displayName}
            versionNumber="1.2.0.0"

            selectableOrganisations={this.state.globalSelectableCustomers}
            selectedOrganisation={this.state.selectedCustomer == null ? -1 : this.state.selectedCustomer}
            onChangeOrganisation={this.onChangeOrganisation}
          />
          <Topbar
            loginButton={this.login}
            loginName={this.state.user.displayName}
            signoutButton={this.onPanelOpen}
          ></Topbar>
          <Router>
            <div>
              {(() => {
                if (!this.state.error && this.state.authorizedUser.AdminEmail || this.state.authorizedUser.GebruikersEmail) {
                  return <Leftnavigation superAdmin={this.state.superAdmin} />;
                } else {
                  return null;
                }
              })()}
              {/* {this.state.isAuthenticated ? (
                <Leftnavigation showitems={this.state.isAuthenticated} />
              ):null} */}

              <Route
                render={({ location }) => (
                  <Router>
                    {!this.state.error && this.state.authorizedUser.AdminEmail || this.state.authorizedUser.GebruikersEmail ? (
                      <Switch location={location}>
                        {routes.map((route, index) => (
                          <Route
                            path={route.path}
                            exact={true}
                            component={route.main}
                          />
                        ))}
                      </Switch>
                    ) : (
                      <WelcomePage authEmail={this.state.user.email} AuthUsers={this.state.authorizedUser.AdminEmail || this.state.authorizedUser.GebruikersEmail} loginButton={this.login}></WelcomePage>
                    )}
                  </Router>
                )}
              />
            </div>
          </Router>
        </div>
      </>
    );
  }
}

ReactDOM.render(<Index />, document.getElementById("root"));
