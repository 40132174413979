import { ThemeGenerator } from "@fluentui/react";
import { loadTheme } from "@uifabric/styling";
import { getColorFromString, themeRulesStandardCreator } from "@fluentui/react";


//Generate JSON report
export const generatePallette = (primaryColor, backgroundColor, textColor) => {

  const standardRules = new themeRulesStandardCreator();
  const primaCol = getColorFromString(primaryColor);

  ThemeGenerator.setSlot(standardRules.primaryColor, primaCol);

  ThemeGenerator.setSlot(
    standardRules.backgroundColor,
    getColorFromString(backgroundColor) || "",
    false,
    true,
    true
  );

  ThemeGenerator.setSlot(
    standardRules.foregroundColor,
    getColorFromString(textColor) || "",
    false,
    true,
    true
  );

  ThemeGenerator.insureSlots(standardRules, false);
  const theme = ThemeGenerator.getThemeAsJson(standardRules);
  const lastTheme = loadTheme({
    ...{ palette: theme },
    isInverted: false,
  });
  console.log("JSON theme: ", lastTheme.palette);
  return lastTheme.palette;
};

//Generate PowerShell report
export const generatePowershellPallette = (primaryColor, backgroundColor, textColor) => {
    
    const standardRules = new themeRulesStandardCreator();
    const primaCol = getColorFromString(primaryColor);
  
    ThemeGenerator.setSlot(standardRules.primaryColor, primaCol);
  
    ThemeGenerator.setSlot(
      standardRules.backgroundColor,
      getColorFromString(backgroundColor) || "",
      false,
      true,
      true
    );
  
    ThemeGenerator.setSlot(
      standardRules.foregroundColor,
      getColorFromString(textColor) || "",
      false,
      true,
      true
    );
  
    ThemeGenerator.insureSlots(standardRules, false);
    const theme = ThemeGenerator.getThemeForPowerShell(standardRules);
    const lastTheme = loadTheme({
      ...{ palette: theme },
      isInverted: false,
    });
    console.log("PowerShell theme: ", lastTheme.palette);
    return lastTheme.palette;
  };