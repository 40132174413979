import React, { Component } from "react";
import {
  DefaultButton,
  PrimaryButton,
  Stack,
} from "@fluentui/react";
import { Dropdown, TextField, Label } from "@fluentui/react"
import { Panel, PanelType } from "@fluentui/react";
import  RichTextEditor  from "react-rte";
import { NewsItem } from "../../Services/Entities/NewsItem";
import { DatePicker, mergeStyleSets, defaultDatePickerStrings } from '@fluentui/react';

export class EditNewsPanel extends Component {

    constructor(props) {
        super(props);

        this.state = {
            valueItemId: null,
            valueSelectedOrganisation: null,
            valuePublicationDate: null,
            valueTitle: "",
            valueArticleBody: RichTextEditor.createEmptyValue(),
        }
    };

    componentDidUpdate = (prevProps) => {
        if (this.props.itemToEdit && prevProps.itemToEdit !== this.props.itemToEdit)
        {
            console.log(this.props.itemToEdit);
            console.log(new Date(this.props.itemToEdit.publicationDate))

            this.setState({
                valueItemId: this.props.itemToEdit.newsID,
                valueSelectedOrganisation: this.props.itemToEdit.klantId,
                valuePublicationDate:this.props.itemToEdit.publicationDate != null ?  new Date(this.props.itemToEdit.publicationDate) : null,
                valueTitle: this.props.itemToEdit.title,
                valueArticleBody: RichTextEditor.createValueFromString(this.props.itemToEdit.articleBody, 'html')
            });
        }
    }

    onSave = () => {
        var returnedItem = new NewsItem();
        returnedItem.newsID = this.state.valueItemId;
        returnedItem.title = this.state.valueTitle;
        returnedItem.publicationDate = this.state.valuePublicationDate;
        returnedItem.klantId = this.props.selectedOrganisation != null ? this.props.selectedOrganisation : this.state.valueSelectedOrganisation;
        returnedItem.articleBody = this.state.valueArticleBody.toString("html");
        console.log(returnedItem);
        this.props.OnPanelSave(returnedItem);
        
        this.clearItemState();
    }

    onDismiss = () => {
        this.clearItemState();
        this.props.onDismiss();
    }

    clearItemState = () => {
        this.setState({
            valueItemId: null,
            valueSelectedOrganisation: null,
            valuePublicationDate: null,
            valueTitle: "",
            valueArticleBody: RichTextEditor.createEmptyValue()
        });
    }

    closePanel = () => {
        return (
        <div>
            <PrimaryButton className="submitButton" onClick={this.onSave.bind(this)}>
                Opslaan
            </PrimaryButton>
            <DefaultButton className="submitButton" onClick={this.onDismiss.bind(this)}>
                Annuleer
            </DefaultButton>
        </div>
        );
    };

    render = () => {
        const toolbarConfig = {
            // Optionally specify the groups to display (displayed in the order listed).
            display: [
              "INLINE_STYLE_BUTTONS",
              "BLOCK_TYPE_BUTTONS",
              "LINK_BUTTONS",
              "BLOCK_TYPE_DROPDOWN",
              "HISTORY_BUTTONS",
            ],
            INLINE_STYLE_BUTTONS: [
              { label: "Bold", style: "BOLD", className: "custom-css-class" },
              { label: "Italic", style: "ITALIC" },
              { label: "Underline", style: "UNDERLINE" },
            ],
            BLOCK_TYPE_DROPDOWN: [
              { label: "Normal", style: "unstyled" },
              { label: "Heading Large", style: "header-one" },
              { label: "Heading Medium", style: "header-two" },
              { label: "Heading Small", style: "header-three" },
            ],
            BLOCK_TYPE_BUTTONS: [
              { label: "UL", style: "unordered-list-item" },
              { label: "OL", style: "ordered-list-item" },
            ],
          };

        return (
            <div className="customPanel">
                <Panel
                    isOpen={this.props.panelOpen}
                    onDismiss={this.props.onDismiss.bind(this)}
                    headerText="Nieuwsitem bewerken\aanmaken"
                    closeButtonAriaLabel="Close"
                    aria-multiselectable={false}
                    onRenderFooterContent={this.closePanel}
                    isFooterAtBottom={true}
                    type={PanelType.medium}
                >
                    <Stack>
                        <Dropdown
                            onChange={this.onChangeOrganisation}
                            options={this.props.selectableOrganisations}
                            name="organisatie"
                            label="Organisatie"
                            placeholder={"Selecteer een organisatie"}
                            selectedKey={this.props.selectedOrganisation != null ? this.props.selectedOrganisation : this.state.valueSelectedOrganisation}
                            required
                            disabled={this.props.selectedOrganisation != null}
                            />
                         <TextField
                            label="Titel"
                            name="text"
                            required
                            onChange={this.onChangeTitle}
                            value={this.state.valueTitle}
                            validateOnFocusOut
                            //errorMessage={this.state.errorMessage}
                        />
                        <DatePicker
                            isRequired
                            label="Publicatiedatum"
                            placeholder="Selecteer een datum..."
                            ariaLabel="Selecteer een datum"
                            // DatePicker uses English strings by default. For localized apps, you must override this prop.
                            strings={defaultDatePickerStrings}
                            value={this.state.valuePublicationDate}
                            onSelectDate={this.onChangePublicationDate}
                        />
                        <Label>Inhoud</Label>
                        <RichTextEditor
                            toolbarConfig={toolbarConfig}
                            name="articleContent"
                            label="Inhoud"
                            value={this.state.valueArticleBody}
                            onChange={this.onChangeArticleBody}
                        />
                    </Stack>
                    
                </Panel>
            </div>
        );
    };

    onChangeOrganisation = (event, item) =>
    {
        this.setState({ valueSelectedOrganisation: item.key })
    }
    onChangeTitle = (event, value) => {
        this.setState({ valueTitle: value })
    }

    onChangeArticleBody = (value) => {
        this.setState({ valueArticleBody: value });
    };

    onChangePublicationDate = (value) => {
        this.setState({ valuePublicationDate: value });
    };
}