import React, { Component } from "react";
import "./styles.css";
import "office-ui-fabric-react/dist/css/fabric.css";
import { TextField, Toggle, Dropdown } from "office-ui-fabric-react/lib/";
import "regenerator-runtime/runtime";
import {
  Dialog,
  DialogFooter,
  DialogType,
} from "office-ui-fabric-react/lib/Dialog";
import { PrimaryButton, DefaultButton } from "office-ui-fabric-react";
import {
  DetailsList,
  DetailsListLayoutMode,
  Selection,
} from "office-ui-fabric-react/lib/DetailsList";
import { Icon } from "office-ui-fabric-react/lib";
import { CustomPanel } from "../Controls/Panel/panel";
import { CommandBarEditDeleteControl } from "../Controls/CommandBar/commandBarEditDelete";
// import { Panel } from 'office-ui-fabric-react/lib/Panel';

export class AdminRMS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newKlantCheck: false,
      editButton: false,
      openDialog: false,
      openErrorDialog: false,
      openSiteDialog: false,
      validationMessage: "",
      validationMessageEmail:"",
      openPanel: false,
      Naam: "",
      Email: "",
      Organisatie: "",
      SuperAdmin: "",
      organisatieSelectie: [],
      selectedAdmin: [],
      newOrg: "",
      operation: "",
      administrators: [],
      newAdmin: {
        klantId: null,
        naam: "",
        email: "",
        superAdmin: false,
        organisatie: "",
      },
    };

    this.toggleOnchange = (e, checked) => {
      let { newAdmin } = this.state;
      console.log("Toggle is : " + checked);
      newAdmin[e.target.name] = checked;
      this.setState({ newAdmin: newAdmin });
    };

    this.getKlientId = (organisatie) => {
      fetch("/api/klant/organisatie/" + organisatie)
        .then((response) => response.json())
        .then((data) => {
          this.setState((prevState) => ({
            newAdmin: {
              ...prevState.newAdmin,
              klantId: data,
            },
          }));
          console.log("KlantID: ", data);
          return data;
        });
    };

    this.getKlientIdEdit = (organisatie) => {
      fetch("/api/klant/organisatie/" + organisatie)
        .then((response) => response.json())
        .then((data) => {
          this.setState((prevState) => ({
            selectedAdmin: {
              ...prevState.selectedAdmin,
              klantId: data,
            },
          }));
          console.log("KlantID: ", data);
          return data;
        });
    };

    this.toggleDropdown = (e, selectedOption) => {
      console.log("Dropdown is : ", selectedOption.text);
      const org = selectedOption.text;
      const klantId = this.getKlientId(org);

      this.setState((prevState) => ({
        newAdmin: {
          ...prevState.newAdmin,
          klantId: klantId,
          organisatie: org,
        },
      }));
    };

    this.toggleDropdownEdit = async (e, selectedOption) => {
      console.log("Dropdown is : ", selectedOption.text);
      const org = selectedOption.text;
      const klantId = await this.getKlientIdEdit(org);

      this.setState((prevState) => ({
        selectedAdmin: {
          ...prevState.selectedAdmin,
          klantId: klantId,
          organisatie: org,
        },
      }));
    };

    this.editField = (e,input) => {
      let { newAdmin } = this.state;
      if (e.target.value !== undefined) {
        newAdmin[e.target.name] = e.target.value;
      }
      this.setState({ newAdmin: newAdmin });
    };

    this.handleEmail = (e) => {
      let { newAdmin } = this.state;
  
      this.setState({ validationMessageEmail: "" });
      const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line
      if (!emailRegex.test(e.target.value)) {
        const errMsg = "Het email adres voldoet niet aan de eisen";
        this.setState({ validationMessageEmail: errMsg});
      } else {
        newAdmin[e.target.name] = e.target.value;
        this.setState({ newAdmin: newAdmin, validationMessageEmail: "" });
      }
    };

    this.editFieldEdit = (e) => {
      let { selectedAdmin } = this.state;

      if (e.target.value !== undefined) {
        selectedAdmin[e.target.name] = e.target.value;
      }
      this.setState({ selectedAdmin: selectedAdmin });
    };
    this._selection = new Selection({
      onSelectionChanged: () =>
        //   setSelectionCount(_selection.count),
        //     setSelectionDetails(`${_selection.count} items selected`)
        this.setState({
          editButton: true,
        }),
    });

    this.changeSomething = () => {
      var klant = this._getSelectionDetailsName();
      // this.updateJSON();
      console.log("Admin", klant);

      this.setState({ selectedAdmin: klant, newAdmin: klant });
    };

    this.getAdmins = () => {
      fetch("/api/admin/all")
        .then((response) => response.json())
        .then((data) => {
          this.setState({ administrators: data });
        });
    };

    this.componentDidMount = () => {
      this.getAdmins();
      this.getKlanten();
    };

    this._changeNewKlant = () => {
      this.setState({ newKlantCheck: true, editButton: false });
    };
    this._changeNewKlantClose = () => {
      this.setState({ newKlantCheck: false });
    };

    this._deleteAdministrator = () => {
      
    };

    this.handleCheckboxChange = (e) => {
      // let { newKlant } = this.state;
      const { checked } = e.target;
      console.log("Edit toggle", e.target);

      this.setState((prevState) => ({
        selectedAdmin: {
          ...prevState.selectedAdmin,
          superAdmin: checked,
        },
      }));
    };

    this.getKlanten = () => {
      fetch("/api/klant/all")
        .then((response) => response.json())
        .then((data) => {
          this.setState({ organisatieSelectie: data });
        });
    };

    this._getSelectionDetailsName = () => {
      const selectionCount = this._selection.getSelectedCount();

      switch (selectionCount) {
        case 0:
          return "No items selected";
        case 1:
          return this._selection.getSelection()[0];
        default:
          return `${selectionCount} items selected`;
      }
    };

    this._showPanel = () => {
      this.setState({ openPanel: true });
      this.changeSomething();
    };
    this._closePanel = () => {
      this.setState({ openPanel: false });
    };

    ///update Admin

    this.updateAdmin = () => {
      const { selectedAdmin } = this.state;

      if (
        selectedAdmin.naam !== "" &&
        selectedAdmin.email !== "" &&
        selectedAdmin.organisatie !== ""
      ) {
        fetch("/api/admin", {
          method: "PUT", // or 'PUT'
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(selectedAdmin),
        })
          .then((response) => response.json())
          .then((data) => {
            console.log("Success:", data);

            this.setState(
              {
                selectedAdmin: {
                  klantId: null,
                  naam: "",
                  email: "",
                  superAdmin: false,
                },
                newAdmin: {
                  naam: "",
                  email: "",
                  superAdmin: "",
                  klantId: "",
                },
                openPanel: false,
                openDialog: true,
                validationMessage: "",
              },
              () => {
                this.getAdmins();
              }
            );
          })
          .catch((error) => {
            console.error("Error:", error);
            // alert("Error:", error);
          });
      } else {
        this.setState({
          openErrorDialog: true,
          validationMessage: "Vereiste veld",
        });
      }
    };

    this.onDeleteSelectedItem = () => {
      var selectedAdmin = this._getSelectionDetailsName();
      selectedAdmin.isDeleted = true;

      console.log(selectedAdmin);

      if (
        selectedAdmin.naam !== "" &&
        selectedAdmin.email !== "" &&
        selectedAdmin.organisatie !== ""
      ) {
        fetch("/api/admin", {
          method: "PUT", // or 'PUT'
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(selectedAdmin),
        })
          .then((response) => response.json())
          .then((data) => {
            console.log("Success:", data);

            this.setState(
              {
                selectedAdmin: {
                  klantId: null,
                  naam: "",
                  email: "",
                  superAdmin: false,
                },
                newAdmin: {
                  naam: "",
                  email: "",
                  superAdmin: "",
                  klantId: "",
                },
                openPanel: false,
                openDialog: true,
                validationMessage: "",
              },
              () => {
                this.getAdmins();
              }
            );
          })
          .catch((error) => {
            console.error("Error:", error);
            // alert("Error:", error);
          });
      } else {
        this.setState({
          openErrorDialog: true,
          validationMessage: "Vereiste veld",
        });
      }
    } 

    this._hideDialog = () => {
      this.setState({ openDialog: false });
    };

    this._hideErrorDialog = () => {
      this.setState({ openErrorDialog: false, validationMessage: "" });
    };

    //Add Admin
    this.addAdmin = async () => {
      const { newAdmin } = this.state;
      // let emailCheck = this.checkEmail(newAdmin.email)
      console.log(JSON.stringify(newAdmin));
     
      if (
        newAdmin.naam !== "" &&
        newAdmin.email !== "" &&
        newAdmin.organisatie !== ""
      ) {
        try {
          await fetch("/api/admin", {
            method: "POST", // or 'PUT'
            headers: {
              "Content-Type": "application/json",
            },

            body: JSON.stringify(newAdmin),
          })
            .then((response) => response.json())
            .then((data) => {
              console.log("Success:", data);

              this.setState(
                {
                  newAdmin: {
                    naam: "",
                    email: "",
                    superAdmin: "",
                    klantId: "",
                  },
                  newKlantCheck: false,
                  openDialog: true,
                  validationMessage: "",
                },
                () => {
                  this.getAdmins();
                }
              );
            })
            .catch((error) => {
              console.error("Error:", error.message);
              // alert("Error:", error);
            });
        } catch (err) {
          console.log("API Admin push:", err.message);
        }
      } else {
        this.setState({
          openErrorDialog: true,
          validationMessage: "Vereiste veld",
          newAdmin: {
            naam: "",
            email: "",
            superAdmin: "",
            klantId: "",
          },
        });
      }
    };
  }

  render = () => {
    this._columns = [
      {
        key: "naam",
        name: "Naam",
        fieldName: "naam",
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
      },
      {
        key: "email",
        name: "Email",
        fieldName: "email",
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
      },
      {
        key: "organisatie",
        name: "Organisatie",
        fieldName: "organisatie",
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
      },
      {
        key: "superAdmin",
        name: "Super Admin",
        fieldName: "superAdmin",
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
      },
    ];
    const norms = [
      {
        Name: "Gehard van der Laarse",
        Email: "g.vanderlaarse@sharevalue.nl",
        Organisatie: "ShareValue",
      },
      {
        Name: "Bert Clevering",
        Email: "b.clevering@sharvalue.nl",
        Organisatie: "ShareValue",
      },
    ];

    const {
      newKlantCheck,
      editButton,
      openDialog,
      openErrorDialog,
      selectedKlant,
      openSiteDialog,
    } = this.state;
    const dialogContentProps = {
      type: DialogType.normal,
      title: "Administrator is succesvol opgeslagen",
    };
    const dialogContentErrorProps = {
      type: DialogType.normal,
      title:
        "De vereiste velden (*) zijn benodigd voordat u deze gegevens kunt versturen",
    };
    return (
      <div>
        {this.props.superAdmin ? (
          <div className="gridWrapper">
            <h1>Administrator RMS</h1>
            <div className="ms-Grid-col ms-sm12 ms-md12">
              <PrimaryButton
                iconProps={{ iconName: "Add", className: "ms-AddIcon" }}
                onClick={this._changeNewKlant}
                className="submitButton"
                text="Nieuwe administrator"
              />
            </div>
            <h4 class="section-divider sectionHeadings">
              <Icon style={{ verticalAlign: "bottom" }} iconName="Info"></Icon>{" "}
              Administrator gegevens
            </h4>
            <div className="ms-Grid-col ms-col12 ms-md12">
              {editButton && selectedKlant !== null && (
                <CommandBarEditDeleteControl EditItem={this._showPanel} DeleteItem={this.onDeleteSelectedItem} />
              )}
              {!newKlantCheck && (
                <div className="detailList">
                  <DetailsList
                    items={this.state.administrators}
                    selectionMode={"single"}
                    enterModalSelectionOnTouch={true}
                    onColumnResize={false}
                    textAlign="left"
                    verticalAlign="left"
                    columns={this._columns}
                    setKey="set"
                    layoutMode={DetailsListLayoutMode.justified}
                    ariaLabelForSelectionColumn="Toggle selection"
                    ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                    checkButtonAriaLabel="Row checkbox"
                    selection={this._selection}
                    compact={false}
                    selectionPreservedOnEmptyClick={true}
                  />
                </div>
              )}
            </div>
            {newKlantCheck ? (
              <div className="detailList">
                <div className="ms-Grid" dir="ltr">
                  <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md6">
                      <TextField
                        onChange={this.editField}
                        required
                        label="Naam"
                        name="naam"
                        errorMessage={this.state.validationMessage}
                      />
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md6">
                      <TextField
                        name="email"
                        onChange={this.handleEmail}
                        type="email"
                        required
                        label="Email"
                        errorMessage={this.state.validationMessageEmail}
                      />
                    </div>
                    <div className="ms-Grid-col ms-col12 ms-md6">
                      <Dropdown
                        onChange={this.toggleDropdown}
                        required
                        options={this.state.organisatieSelectie}
                        label="Organisatie"
                        name="organisatie"
                        errorMessage={this.state.validationMessage}
                      />
                    </div>
                    <div className="ms-Grid-col ms-col12 ms-md6">
                      <Toggle
                        onChange={this.toggleOnchange}
                        required
                        label="Super Admin?"
                        name="superAdmin"
                        onText="Ja"
                        offText="Nee"
                        errorMessage={this.state.validationMessage}
                      />
                    </div>
                    <br></br>
                    <div
                      style={{ "padding-top": 10 }}
                      className="ms-Grid-col ms-sm12 ms-md12"
                    ></div>
                    <div className="ms-Grid-row">
                      <div className="ms-Grid-col">
                        <PrimaryButton
                          className="submitButton"
                          onClick={this.addAdmin}
                          text="Opslaan"
                        />
                      </div>
                      <div className="ms-Grid-col">
                        <DefaultButton
                          className="submitButton"
                          onClick={this._changeNewKlantClose}
                          text="Annuleer"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            <CustomPanel
              onDismiss={this._closePanel}
              onPanelSave={this.updateAdmin}
              panelOpen={this.state.openPanel}
              panelHeader={"Administrator"}
              Naam={this.state.selectedAdmin.naam}
              Email={this.state.selectedAdmin.email}
              Organisatie={this.state.selectedAdmin.organisatie}
              OrganisatieSelectie={this.state.organisatieSelectie}
              EditItems={this.editFieldEdit}
              ToggleDropdown={this.toggleDropdownEdit}
              ToggleOnchange={this.handleCheckboxChange}
              SuperAdmin={this.state.selectedAdmin.superAdmin}
            />
            <Dialog
              hidden={!openDialog}
              onDismiss={this._hideDialog}
              dialogContentProps={dialogContentProps}
            >
              <DialogFooter>
                <PrimaryButton onClick={this._hideDialog} text="OK" />
              </DialogFooter>
            </Dialog>
            <Dialog
              hidden={!openErrorDialog}
              onDismiss={this._hideErrorDialog}
              dialogContentProps={dialogContentErrorProps}
            >
              <DialogFooter>
                <PrimaryButton onClick={this._hideErrorDialog} text="OK" />
              </DialogFooter>
            </Dialog>
          </div>
        ) : (
          <div className="gridWrapper">
            {" "}
            <h1>Administrator RMS</h1>
            <p className="noOpenListButton">
              U heeft geen toegang tot dit scherm
            </p>
          </div>
        )}
      </div>
    );
  };
}
