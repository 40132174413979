export class BranchService {

    static instance;

    static get Instance() {

        if(this.instance == null) {
            this.instance = new BranchService();
        }

        return this.instance;
    }

    GetAll() {
        return new Promise((resolve, reject) => {
            fetch("/api/branch/all")
            .then((response) => response.json())
            .then((data) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
        });
    }

    Save(branchItem)
    {
        // POST for add, PUT for update
        var restMethod = branchItem.branchID == null ? "POST" : "PUT";
        return new Promise((resolve, reject) => {
            fetch("/api/branch", {
                method: restMethod,
                headers: {
                "Content-Type": "application/json",
                },
                body: JSON.stringify(branchItem),
            })
            .then((response) => response.json())
            .then((data) => {
                console.log("Success:", data);
                resolve(true);            
            })
            .catch((error) => {
                console.error("Error:", error);
                reject(error);
            });
        });
    }
}