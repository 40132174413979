import React, { Component } from "react";
import { OpenListButton } from "../Controls/OpenListButton/openListButton";

export class RisicoAnalysis extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listURL: "",
    };
  }

  openListURL = () => {
    window.open(this.props.siteURL +"/SitePages/Risicoanalyse.aspx");
  };

  render() {
    return (
      <div className="gridWrapper">
        <h1>Risicoanalyse</h1>

        <p>
          In dit onderdeel worden risico’s gewogen en beheersmaatregelen
          vastgesteld. Er wordt gewerkt met een Risicoprofiel en een Risico
          actieplan, zodat de informatie goed toegankelijk blijft. Hier wordt de
          methode gehanteerd van Fin/Kinney & Wiruth, op basis van de criteria
          blootstelling, waarschijnlijkheid en effect, wordt een significantie
          bepaald en kunnen beheersmaatregelen worden benoemd. Ook wordt een
          relatie gelegd tussen de risico’s en de desbetreffende normeisen.
        </p>
        {this.props.siteURL ? (
          <OpenListButton openList={this.openListURL} />
        ) : (
          <p className="noOpenListButton">
            U heeft geen toevang tot dit scherm
          </p>
        )}
      </div>
    );
  }
}
