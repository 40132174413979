export class CustomerService {

    static instance;

    static get Instance() {

        if(this.instance == null) {
            this.instance = new CustomerService();
        }

        return this.instance;
    }

    GetAll() {
        return new Promise((resolve, reject) => {
            fetch("/api/klant/all")
            .then((response) => response.json())
            .then((data) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
        });
    }
}