import React, { Component } from "react";
import "./styles.css";
import "office-ui-fabric-react/dist/css/fabric.css";
import { TextField } from "office-ui-fabric-react/lib/TextField";
import "regenerator-runtime/runtime";
import {
  Dialog,
  DialogFooter,
  DialogType,
} from "office-ui-fabric-react/lib/Dialog";
import { PrimaryButton, DefaultButton, Dropdown } from "office-ui-fabric-react";
import { Icon } from "office-ui-fabric-react/lib";
import {
  DetailsList,
  DetailsListLayoutMode,
  Selection,
} from "office-ui-fabric-react/lib/DetailsList";
import { CommandBarControl } from "../Controls/CommandBar/commandBar";
import { GebruikersPanel } from "../Controls/Panel/gebruikersPanel";
import { UserService } from "../Services/UserService";
import { CommandBarEditDeleteControl } from "../Controls/CommandBar/commandBarEditDelete";

export class Gebruikers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newKlantCheck: false,
      editButton: false,
      openPanel: false,
      openDialog: false,
      openErrorDialog: false,
      openSiteDialog: false,
      validationMessage: "",
      validationMessageEmail: "",
      organisatie: "",
      gebruikers: [],
      newGebruiker: {
        naam: "",
        klantId: "",
        email: "",
        organisatie: "",
        rolid: null,
        rolkey:null
      },
      loading: false,
      selectedGebruiker: [],
      addKlant: false,
      rechtenSelectie: [],
    };
    this.changeSomething = () => {
      var gebruiker = this._getSelectionDetailsNameItem();
      // this.updateJSON();
      console.log("Gebruikers", gebruiker);

      this.setState({ selectedGebruiker: gebruiker, newGebruiker: gebruiker });
    };

    this.getRechten = () => {
      fetch("/api/gebruikers/rechten")
        .then((response) => response.json())
        .then((data) => {
          this.setState({ rechtenSelectie: data });
        });
    };

    this.componentDidMount = () => {
      this.getGebruikers();
      this.getRechten();
    };

    this.copyArray = (array) => {
      const newArray = [];
      for (let i = 0; i < array.length; i++) {
        newArray[i] = array[i];
      }
      return newArray;
    };

    this.hideSiteDialog = () => {
      this.setState({ openSiteDialog: false });
    };

    this.getKlantID = (organisatie) => {};

    this.handleEmail = (e) => {
      let { newGebruiker } = this.state;

      this.setState({ validationMessageEmail: "" });
      const emailRegex =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line
      if (!emailRegex.test(e.target.value)) {
        const errMsg = "Het email adres voldoet niet aan de eisen";
        this.setState({ validationMessageEmail: errMsg });
      } else {
        newGebruiker[e.target.name] = e.target.value;
        this.setState({
          newGebruiker: newGebruiker,
          validationMessageEmail: "",
        });
      }
    };

    this.AddGebruiker = () => {
      const { newGebruiker } = this.state;

      if (
        newGebruiker.naam !== "" &&
        newGebruiker.email !== "" &&
        newGebruiker.organisatie !== ""
      ) {
        fetch("/api/gebruikers", {
          method: "POST", // or 'PUT'
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newGebruiker),
        })
          .then((response) => response.json())
          .then((data) => {
            console.log("Success:", data);
            this._showDialog();
            this.setState(
              {
                loading: true,
                newGebruiker: {
                  naam: "",
                  email: "",
                  klantId: "",
                  organisatie: "",
                },
              },
              () => {
                this.getGebruikers();
              }
            );
          })
          .catch((error) => {
            console.error("Error:", error);
            alert("Error:", error);
          });
      } else {
        this.setState({
          openErrorDialog: true,
          validationMessage: "Vereiste veld",
        });
      }
    };

    this.getGebruikers = () => {
      UserService.Instance.GetAll(this.props.selectedOrganisation).then((data) => {
        this.setState({ gebruikers: data });
      });
    };

    this.getKlientId = (organisatie) => {
      fetch("/api/klant/organisatie/" + organisatie)
        .then((response) => response.json())
        .then((data) => {
          this.setState((prevState) => ({
            newGebruiker: {
              ...prevState.newGebruiker,
              klantId: data,
            },
          }));
          console.log("KlantID: ", data);
        });
    };

    this.getKlientIdEdit = (organisatie) => {
      fetch("/api/klant/organisatie/" + organisatie)
        .then((response) => response.json())
        .then((data) => {
          this.setState((prevState) => ({
            selectedGebruiker: {
              ...prevState.selectedGebruiker,
              klantId: data,
            },
          }));
          console.log("KlantID: ", data);
        });
    };
    this.toggleDropdown = (e, selectedOption) => {
      console.log("Dropdown is : ", selectedOption.text);
      const org = selectedOption.text;
      const klantId = this.getKlientId(org);

      this.setState((prevState) => ({
        newGebruiker: {
          ...prevState.newGebruiker,
          klantId: klantId,
          organisatie: org,
        },
      }));
    };

    this.toggleRechtenDropdown = (e, selectedOption) => {
      console.log("Dropdown is : ", selectedOption.text);
      this.setState((prevState) => ({
        newGebruiker: {
          ...prevState.newGebruiker,
          rolid: selectedOption.key,
        },
      }));
    };

    this.ChangeNewFormValue = (e) => {
      this.setState({ [e.target.name]: e.target.value });
    };
    this._changeNewKlant = () => {
      this.setState({ newKlantCheck: true, editButton: false });
    };
    this._changeNewKlantClose = () => {
      this.setState({ newKlantCheck: false });
    };
    this._changeRightNavOpen = () => {
      this.setState({ openRightNav: false });
    };
    this._showPanel = () => {
      this.setState({ openPanel: true });
      this.changeSomething();
    };
    this._closePanel = () => {
      this.setState({
        openPanel: false,
        Email: "",
        Naam: "",
        Organisatie: "",
      });
    };
    this._showDialog = () => {
      this.setState({ openDialog: true });
    };
    this._hideDialog = () => {
      this.setState({ openDialog: false, newKlantCheck: false });
    };
    this._hideErrorDialog = () => {
      this.setState({ openErrorDialog: false, validationMessage: "" });
    };
    this._getSelectionDetailsName = (name) => {
      const selectionCount = this._selection.getSelectedCount();

      switch (selectionCount) {
        case 0:
          return "No items selected";
        case 1:
          return this._selection.getSelection()[0][name];
        default:
          return `${selectionCount} items selected`;
      }
    };
    this._getSelectionDetailsNameItem = () => {
      const selectionCount = this._selection.getSelectedCount();

      switch (selectionCount) {
        case 0:
          return "No items selected";
        case 1:
          return this._selection.getSelection()[0];
        default:
          return `${selectionCount} items selected`;
      }
    };
    this._selection = new Selection({
      onSelectionChanged: () =>
        this.setState({
          editButton: true,
        }),
    });
    this.editField = (e) => {
      let { newGebruiker } = this.state;

      if (e.target.value !== undefined) {
        newGebruiker[e.target.name] = e.target.value;
      }
      this.setState({ newGebruiker: newGebruiker });
    };

    this.editFieldEditPane = (e) => {
      let { selectedGebruiker } = this.state;

      if (e.target.value !== undefined) {
        selectedGebruiker[e.target.name] = e.target.value;
      }
      this.setState({ selectedGebruiker: selectedGebruiker });
    };

    this.toggleDropdownEdit = (e, selectedOption) => {
      console.log("Dropdown is : ", selectedOption.text);
      const org = selectedOption.text;
      const klantId = this.getKlientIdEdit(org);

      this.setState((prevState) => ({
        selectedGebruiker: {
          ...prevState.selectedGebruiker,
          klantId: klantId,
          organisatie: org,
        },
      }));
    };


    this.ToggleRechtenEdit = (e, selectedOption) => {
      console.log("Rechten Dropdown is : ", selectedOption.text);
      
      this.setState((prevState) => ({
        selectedGebruiker: {
          ...prevState.selectedGebruiker,
          rolid:selectedOption.key
        },
      }));
    };

    this.updateGebruiker = () => {
      const { selectedGebruiker } = this.state;
      selectedGebruiker.isDeleted = false;
      console.log("Update selected gebruiker", selectedGebruiker);
      fetch("/api/gebruikers", {
        method: "PUT", // or 'PUT'
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(selectedGebruiker),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("Success:", data);
          this._showDialog();

          this.setState(
            {
              selectedGebruiker: [],
            },
            () => {
              this.getGebruikers();
            }
          );
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    };
    
    this.onDeleteSelectedItem = () => {
      var selectedGebruiker = this._getSelectionDetailsNameItem();
      selectedGebruiker.isDeleted = true;
  
        console.log("Delete selected gebruiker", selectedGebruiker);
        fetch("/api/gebruikers", {
          method: "PUT", // or 'PUT'
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(selectedGebruiker),
        })
          .then((response) => response.json())
          .then((data) => {
            console.log("Success:", data);
            this._showDialog();
  
            this.setState(
              {
                selectedGebruiker: [],
              },
              () => {
                this.getGebruikers();
              }
            );
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      };
    }

  
  render() {
    this._columns = [
      {
        key: "naam",
        name: "Naam",
        fieldName: "naam",
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
      },
      {
        key: "email",
        name: "Email",
        fieldName: "email",
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
      },
      {
        key: "organisatie",
        name: "Organisatie",
        fieldName: "organisatie",
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
      },
      {
        key: "rolid",
        name: "Rechten",
        fieldName: "rolname",
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
      },
    ];

    const {
      newKlantCheck,
      editButton,
      openDialog,
      openErrorDialog,

      selectedKlant,
      openSiteDialog,
    } = this.state;
    const dialogContentProps = {
      type: DialogType.normal,
      title: "Gebruiker is succesvol opgeslagen",
    };
    const dialogSiteContentProps = {
      type: DialogType.normal,
      title: "Site URL is leeg",
    };
    const dialogContentErrorProps = {
      type: DialogType.normal,
      title:
        "De vereiste velden (*) zijn benodigd voordat u deze gegevens kunt versturen",
    };
    const {
      gebruikers,
      selectedGebruiker,
      rechtenSelectie,
    } = this.state;

    return (
      <div>
        {this.props.klantNaam ? (
          <div className="gridWrapper">
            <h1>Gebruikers</h1>
            <div className="ms-Grid-col ms-sm12 ms-md12">
              <PrimaryButton
                iconProps={{ iconName: "Add", className: "ms-AddIcon" }}
                onClick={this._changeNewKlant}
                className="submitButton"
                text="Nieuwe gebruiker"
              />
            </div>
            <h4 class="section-divider sectionHeadings">
              <Icon style={{ verticalAlign: "bottom" }} iconName="Info"></Icon>{" "}
              Gebruikers gegevens
            </h4>
            <div className="ms-Grid-col ms-col12 ms-md12">
              {editButton && selectedKlant !== null && (
                <CommandBarEditDeleteControl EditItem={this._showPanel} DeleteItem={this.onDeleteSelectedItem} />
              )}
              {!newKlantCheck && (
                <div className="detailList">
                  <DetailsList
                    items={gebruikers}
                    selectionMode={"single"}
                    enterModalSelectionOnTouch={true}
                    onColumnResize={false}
                    textAlign="left"
                    verticalAlign="left"
                    columns={this._columns}
                    setKey="set"
                    layoutMode={DetailsListLayoutMode.justified}
                    ariaLabelForSelectionColumn="Toggle selection"
                    ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                    checkButtonAriaLabel="Row checkbox"
                    compact={false}
                    selection={this._selection}
                    selectionPreservedOnEmptyClick={true}
                  />
                </div>
              )}
            </div>
            {newKlantCheck ? (
              <div className="detailList">
                <div className="ms-Grid" dir="ltr">
                  <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md6">
                      <TextField
                        onChange={this.editField}
                        required
                        label="Naam"
                        name="naam"
                        errorMessage={this.state.validationMessage}
                      />
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md6">
                      <TextField
                        name="email"
                        onChange={this.handleEmail}
                        required
                        label="Email"
                        errorMessage={this.state.validationMessageEmail}
                      />
                    </div>
                    <div className="ms-Grid-col ms-col12 ms-md6">
                      <Dropdown
                        name="Organisatie"
                        label="Organisatie"
                        onChange={this.toggleDropdown}
                        options={this.props.selectableOrganisations}
                        required
                        errorMessage={this.state.validationMessage}
                      />
                    </div>
                    <div className="ms-Grid-col ms-col12 ms-md6">
                      <Dropdown
                        name="Rechten"
                        label="Rechten"
                        onClick={this.getRechten}
                        onChange={this.toggleRechtenDropdown}
                        options={rechtenSelectie}
                        errorMessage={this.state.validationMessage}
                        required
                      />
                    </div>
                    <br></br>
                    <div
                      style={{ "padding-top": 10 }}
                      className="ms-Grid-col ms-sm12 ms-md12"
                    ></div>
                    <div className="ms-Grid-row">
                      <div className="ms-Grid-col">
                        <PrimaryButton
                          className="submitButton"
                          onClick={this.AddGebruiker}
                          text="Opslaan"
                        />
                      </div>
                      <div className="ms-Grid-col">
                        <DefaultButton
                          className="submitButton"
                          onClick={this._changeNewKlantClose}
                          text="Annuleer"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            <GebruikersPanel
              onDismiss={this._closePanel}
              onPanelSave={this.updateGebruiker}
              panelOpen={this.state.openPanel}
              panelHeader={"Gebruikers"}
              Naam={selectedGebruiker.naam}
              Email={selectedGebruiker.email}
              Organisatie={selectedGebruiker.organisatie}
              Rechten={selectedGebruiker.rolname}
              RechtenSelectie={rechtenSelectie}
              organisatieSelectie={this.props.selectableOrganisations}
              onChange={this.editFieldEditPane}
              ToggleDropdown={this.toggleDropdownEdit}
              ToggleRechtenDropdown={this.ToggleRechtenEdit}
              // comboboxChange={this.handleNormsChange}

            />
            <Dialog
              hidden={!openDialog}
              onDismiss={this._hideDialog}
              dialogContentProps={dialogContentProps}
            >
              <DialogFooter>
                <PrimaryButton onClick={this._hideDialog} text="OK" />
              </DialogFooter>
            </Dialog>
            <Dialog
              hidden={!openSiteDialog}
              onDismiss={this.hideSiteDialog}
              dialogContentProps={dialogSiteContentProps}
            >
              <DialogFooter>
                <PrimaryButton onClick={this.hideSiteDialog} text="OK" />
              </DialogFooter>
            </Dialog>
            <Dialog
              hidden={!openErrorDialog}
              onDismiss={this._hideErrorDialog}
              dialogContentProps={dialogContentErrorProps}
            >
              <DialogFooter>
                <PrimaryButton onClick={this._hideErrorDialog} text="OK" />
              </DialogFooter>
            </Dialog>
          </div>
        ) : (
          <div className="gridWrapper">
            {" "}
            <h1>Gebruikers</h1>{" "}
            <p className="noOpenListButton">
              U heeft geen toegang tot dit scherm 
            </p>
          </div>
        )}
      </div>
    );
  }
}
