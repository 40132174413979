import React, { Component } from "react";
import "./styles.css";
import KlantSelector from "../Controls/KlantSelector/klantSelector";
export class InstelligenRMS extends Component {
  render() {
    return (
      <div className="gridWrapper">
        <h1>RMS instellingen</h1>
        <p>
          RMS365 is ons cloudbased platform voor het managen en beheren van
          certificeringen, richtlijnen en processen. Het combineert
          Kwaliteitsmanagement, Risicomanagement en Informatiemanagement. Alles
          slim geïntegreerd. Ons platform is modulair opgebouwd, waardoor je het
          gemakkelijk kunt inrichten voor elk type organisatie. RMS365 is af te
          stemmen op de processen en behoefte van jouw klant. Laat de workflows,
          de automatische notificaties en het overzichtelijke taakbeheer, voor
          je werken zodat er niets blijft liggen en je altijd voorbereid bent op
          een audit. Het RMS365 beheercentrum kun je gebruiken voor het
          instellen van specifieke instellingen voor een organisatie. Op de
          pagina met licentie informatie wordt de basisinformatie vermeld
        </p>
        <KlantSelector klantNaam={this.props.klantNaam} superAdmin={this.props.superAdmin}></KlantSelector>
      </div>
    );
  }
}
