import React, { Component } from "react";
import { MessageBar, MessageBarType } from "@fluentui/react";
import { PrimaryButton, Label } from "@fluentui/react";
import RichTextEditor from "react-rte";
import { RichTextEditorToolbarConfig } from "../../Helpers/RichTextEditorToolbarConfig";

export class PageTemplate3 extends Component {

    render = () => {
        const toolbarConfig = RichTextEditorToolbarConfig.Instance.GetDefault();

        return (
            <React.Fragment>
            <div>
              <Label required>Doel van het proces</Label>
              <RichTextEditor
                value={this.props.valueProcessPurpose}
                name="procesdoel"
                toolbarConfig={toolbarConfig}
                onChange={this.props.onChangeProcessPurpose}
              />
            </div>
            <div>
              <Label required>Proces beschrijving</Label>
              <RichTextEditor
                value={this.props.valueProcessDescription}
                name="procesbeschrijving"
                toolbarConfig={toolbarConfig}
                onChange={this.props.onChangeProcessDescription}
              />
            </div>
            <div>
              <Label required>Proces bewaking</Label>
              <RichTextEditor
                value={this.props.valueProcessMonitoring}
                name="procesbewaking"
                toolbarConfig={toolbarConfig}
                onChange={this.props.onChangeProcessMonitoring}
              />
            </div>

            <div>
              <Label required>Visio diagram</Label>
              <input
                name="visiodiagram"
                onChange={this.props.onChangeFileUpload}
                type="file"
              />
              {this.props.valueVisioFile && (
                <PrimaryButton
                  className="submitButton"
                  onClick={this.props.onSharePointFileUpload}
                  text="Upload"
                />
              )}
              {this.props.isUploadSuccess && (
                <MessageBar
                  messageBarType={MessageBarType.success}
                  isMultiline={false}
                >
                  {this.props.uploadSuccessMessage}
                </MessageBar>
              )}
              {this.props.hasFileMessage && (
                <MessageBar
                  messageBarType={MessageBarType.error}
                  isMultiline={false}
                >
                  {this.props.fileMessage}
                </MessageBar>
              )}
              <Label>
                Huidig bestand:<br/>
                {this.props.valueVisioFileName}
              </Label>
            </div>
          </React.Fragment>
        )
    }
}