import React, { Component } from "react";
import { OpenListButton } from "../Controls/OpenListButton/openListButton";

export class ContextRisico extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listURL: "",
    };
  }

  openListURL = () => {
    window.open(this.props.siteURL +"/Lists/Contextanalyse/AllItems1.aspx");
  };

  render() {
    return (
      <div className="gridWrapper">
        <h1>Contextanalyse</h1>

        <p>
          Veel internationale standaarden vragen om een contextanalyse. Daarin
          wordt de rol van de organisatie in de maatschappij uiteengezet. Er
          zijn vijf groepen belanghebbenden te benoemen en van al deze partijen
          is het belangrijk om vast te stellen, wie welke belangen heeft bij de
          organisatie of welke invloed zij hebben op de organisatie. Het gaat
          daarbij om Intern Betrokkenen, Leveranciersgroepen, Klantengroepen,
          Business Stakeholders en Maatschappelijke Stakeholders. Op deze pagina
          kun je belangen invoeren of wijzigen.
        </p>
        {this.props.siteURL ? (
          <OpenListButton openList={this.openListURL} />
        ) : (
          <p className="noOpenListButton">
          U heeft geen toegang tot dit scherm
          </p>
        )}
      </div>
    );
  }
}
