export class NewsService {

    static instance;

    static get Instance() {

        if(this.instance == null) {
            this.instance = new NewsService();
        }

        return this.instance;
    }

    GetAll(customerFilter) {
        var url = "/api/news/all";
        if (customerFilter && customerFilter > 0)
        {
            url = "/api/news/" + customerFilter + "/all"
        }
        return new Promise((resolve, reject) => {
            fetch(url)
            .then((response) => response.json())
            .then((data) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
        });
    }

    Save(newsItem)
    {
        // POST for add, PUT for update
        var restMethod = newsItem.newsID == null ? "POST" : "PUT";
        return new Promise((resolve, reject) => {
            fetch("/api/news", {
                method: restMethod,
                headers: {
                "Content-Type": "application/json",
                },
                body: JSON.stringify(newsItem),
            })
            .then((response) => response.json())
            .then((data) => {
                console.log("Success:", data);
                resolve(true);            
            })
            .catch((error) => {
                console.error("Error:", error);
                reject(error);
            });
        });
    }
}