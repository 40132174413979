import React, { Component } from "react";
import "./styles.css";
import { OpenListButton } from "../Controls/OpenListButton/openListButton";
export class OverzichtContinu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listURL: "",
    };
  }
  openListURL = () => {
    window.open(this.props.siteURL +"/Lists/OCV/AllItems1.aspx");
  };
  render() {
    return (
      <div className="gridWrapper">
        <h1>Overzicht continu verbeteren</h1>
        <p>
          Het OCV geeft inzicht in alle bevindingen en tekortkomingen, die
          voortvloeien uit o.a. de interne audits, de externe audits en het
          management review. Het overzicht geeft de status weer van de
          bevindingen, de relatie naar de norm en benoemt of een maatregel
          effectief is gebleken. Vanuit dit overzicht kan er een signalering
          plaatsvinden naar de eigenaar van de bevinding en het is mogelijk om
          te filteren op datum, norm of eigenaar.
        </p>
        {this.props.siteURL ? (
          <OpenListButton openList={this.openListURL} />
        ) : (
          <p className="noOpenListButton">
          U heeft geen toegang tot dit scherm
          </p>
        )}
      </div>
    );
  }
}
